import React, { useEffect,useState} from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './login.css';


const Login = () => {
    const navigate = useNavigate();
    
  const [isAdmin, setisAdmin] = useState(false);
    useEffect(() => {
        if(localStorage.getItem('isAdminLogin')){
           
            const adminTypeValue = localStorage.getItem('isAdminLogin');
            if(adminTypeValue === 'true'){
                setisAdmin(true)
            }
        }
        document.body.classList.remove('other-bg');
        document.body.classList.add('login-bg');
        return () => {
          document.body.classList.remove('login-bg');
          document.body.classList.add('other-bg');
        };
        
      }, []);
      const callLogout =()=>{
        localStorage.removeItem('isAdminLogin');
        localStorage.removeItem('userInfo');
        localStorage.clear();
            navigate('/');
            window.location.reload();

      }
    return (
        <div>
            <div className="upwelltitle"><img src="./upwelllogo.png" alt="upwell" className="logoStyling" /> </div>
            <div className="maincontainer">
                
                <div className="container">
                    <div className="boxShadow">
                       
                        {isAdmin ? (
                            <div>
                                 <Link to={'/add'}>
                            <button className="loginbuttonStyle">Add Batch</button>
                        </Link><br />
                            <Link to={'/update'}>
                            <button className="loginbuttonStyle">Update Batch</button>
                        </Link><br />
                        <Link to={'/viewBatch'}>
                             <button className="loginbuttonStyle">View Batch</button>
                            </Link><br />
                        <Link to={'/report'}>
                            <button className="loginbuttonStyle">Create Report</button>
                        </Link>
                        </div>
                        ):(
                            <div>
                            <Link to={'/viewBatch'}>
                             <button className="loginbuttonStyle">View Batch</button>
                            </Link><br />
                            <Link to={'/report'}>
                            <button className="loginbuttonStyle">View Report</button>
                        </Link>
                            </div>

                        )}
                    
                        <button type="submit" onClick={callLogout} className="loginbuttonStyle">LOGOUT</button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Login