import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { getData, getIdRefreshToken } from '../ApiServices/ApiService';
import { Link } from 'react-router-dom';
import './AddNewBatch.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

function Report() {
  const [supplierList, setSupplierList] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      if (!dataFetched) {
        if (!localStorage.getItem('isAdminLogin')) {
          setError('You are not currently logged in');
          console.log('data not in local'); return
        }
        getBatchDetailsSearch();
      }
    } else {
      callLogout();
    }
    document.body.classList.remove('other-bg');
    document.body.classList.add('newbatch-bg');
    return () => {
      document.body.classList.remove('newbatch-bg');
      document.body.classList.add('other-bg');
    };
  }, [dataFetched]);

  const getBatchDetailsSearch = () => {
    const getPayload = {
      "op": "GET_BATCH_DETAILS_SEARCH"
    }

    setError('');
    getData('test1', JSON.stringify(getPayload), userData?.id_token).then(resp => {
      setDataFetched(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === "success") {
              if (respData.dataJ.length > 0) {
                setError('');
                setSupplierList(respData.dataJ);
              } else {
                setSupplierList([]);
                setError(respData.info);
              }
            } else {
              setSupplierList([]);
              setError(respData.info);
            }
          } else {
            setError('Error While Getting Supplier Batch List');
            setSupplierList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi();
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Loading Supplier Batch List');
          setSupplierList([]);
        }
      })
    }).catch((error) => {
      console.log('network error:', error);
      setError('Network Error While Loading');
      setSupplierList([]);
    });
  }

  const callRefreshTokenApi = () => {
    const payload = {
      "email": userData.email,
      "oldRefreshToken": userData.refresh_token
    }
    console.log("payload", payload);
    getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
      console.log('responced', resp);
      if (resp.statusCode === 200) {
        if (resp.message.toLowerCase() === 'success') {
          userData.id_token = resp.dataJ.id_token;
          localStorage.setItem('userInfo', JSON.stringify(userData));
          getBatchDetailsSearch();
        } else {
          callLogout();
        }
      } else {
        callLogout();
      }
    }).catch((error) => {
      console.log('refresh error', error);
      callLogout();
    });
  }

  const callLogout = () => {
    localStorage.removeItem('isAdminLogin');
    localStorage.removeItem('userInfo');
    localStorage.clear();
    navigate('/');
    window.location.reload();
  }

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const handleRedirect = (path) => {
    if (!batchNo) {
      setError('Batch Number is required');
      return
    }
    navigate(path, { state: { name: batchNo, update: true, view: true } });
  }

  const [batchNo, setBatchNo] = useState('');

  const handleUpdatedvalue = async (event) => {
    setBatchNo(event);
    setError('');
  }
  return (
    <div>
      <div className="upwelltitle" style={{ display: 'flex' }}>
        <img src="./upwelllogo.png" alt="upwell" className="logoStyling" /> <span className="addTitle-batch">Batch Details</span>
        <span className="home-btn-span"><Link to={'/dashboard'}>
          <button className="home-btn btn-width"> <FontAwesomeIcon icon={faHome} className="homeButton" /></button> </Link>
        </span>
      </div>
      <div className="maincontainernew">

        <div className="containerStyle">
          <div>
            <form>
              <div className="boxStyling">
                <div className="displayFlex marginStyle">
                  <div className="flexAuto">
                    {supplierList?.length > 0 ? (
                      <fieldset>
                        <legend className="scheduler-border">Batch Number:</legend>
                        <select onChange={(e) => handleUpdatedvalue(e.target.value)} style={{ border: 'none', outline: 'none', width: '150px' }}>
                          <option value="">Select Supplier</option>
                          {supplierList?.map((item, index) => (
                            <option key={index}>
                              {item.batchNo}
                            </option>
                          ))}
                        </select>
                      </fieldset>
                    ) : (
                      <div>Loading....</div>
                    )

                    }

                  </div>
                </div>

              </div>
              {error && <p style={{ color: 'red' }}>{error}</p>}

            </form>

            <div className="paddingTop">

              <button type="submit" onClick={() => handleRedirect('/biomassReport')} className="buttonStyle"> RAW BIOMASS </button>

              <button type="submit" onClick={() => handleRedirect('/extractionReport')} className="buttonStyle1"> EXTRACTION </button>

              <button type="submit" onClick={() => handleRedirect('/logisticsReport')} className="buttonStyle2" > LOGISTICS </button>

              <button type="submit" onClick={() => handleRedirect('/productsReports')} className="buttonStyle3">PRODUCTS</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Report;