import React, {useEffect,useState} from 'react';
import './App.css';
import profile from "./../images/avatar.svg";
import { postDataLogin } from '../ApiServices/ApiService';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
function LoginPage() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showForgotPassword,setShowForgotPassword] = useState(false);
    useEffect(() => {
        document.body.classList.remove('other-bg');
        document.body.classList.add('loginPage-bg');
        return () => {
          document.body.classList.remove('loginPage-bg');
          document.body.classList.add('other-bg');
        };
      }, []);

      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [message, setMessage] = useState('');
      const [changeEmail, setChangeEmail] = useState('');
      const [resetPassword, setResetPassword] = useState('');
      const [newPassword, setNewPassword] = useState('');
      const [confirmPassword, setConfirmPassword] = useState('');
      const [showPassword, setShowPassword] = useState(false);
      const [showNewPassword, setShowNewPassword] = useState(false);
      const [showConfirmPassword, setShowConfirmPassword] = useState(false);      
      const [showResetPassword, setShowResetPassword] = useState(false);

      const handleEmail = (event) => {
        setEmail(event.target.value)
      }

      const handlePassword = (event) => {
        setPassword(event.target.value)
      }

   const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // const response = await fetch('YOUR_API_ENDPOINT', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ email, password }),
      // });
      if(selectedUserType.toLowerCase() === 'user'){
        const payload = {
          op: "normal_user",
          username: email,
          password: password
        }
        setLoading(true);
        setMessage('');   
    await postDataLogin('login_page_new',payload).then(resp => {
      if(resp.statusCode === 200){
        if(resp.message.toLowerCase() === 'success_normal_user'){
          setTimeout(() => {
            setLoading(false);
            setMessage(resp.info);
            // Store data in localStorage
            localStorage.setItem('isAdminLogin', false);
            // localStorage.setItem('headerTokens', JSON.stringify(resp.dataJ));
            const userInfo = {
              email: email,
              id_token: resp.dataJ.id_token,
              access_token: resp.dataJ.access_token,
              refresh_token:resp.dataJ.refresh_token
            }
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            navigate('/dashboard',{ state: { type: false } });
            
          }, 1000);
  
        }else if(resp.message.toLowerCase() === 'change_password'){
          setMessage(resp.info);
          setTimeout(() => {
            setLoading(false);
            setShowForgotPassword(true);   
            setMessage('');         
          }, 1000);
          
        }else{
          setLoading(false);
          setMessage(resp.info);
        }
      }else if(resp.statusCode === 500){
          setLoading(false);
          setMessage(resp.info);        
      }else{
        setLoading(false);
        setMessage('Login failed. Please check your credentials.');
      }
    }).catch((error) => {
      setLoading(false);
      setMessage('Login failed. Please check your credentials or Network Error');
      console.log('network error:', error);
    });
      }else{
        const payload = {
          op: "admin_user",
          username: email,
          password: password
        }
        setLoading(true); 
        setMessage('');  
    await postDataLogin('login_page_new',payload).then(resp => {
      if(resp.statusCode === 200){
        if(resp.message.toLowerCase() === 'success_admin'){
          setTimeout(() => {
            setLoading(false);
            setMessage(resp.info);
            localStorage.setItem('isAdminLogin', true);
            const userInfo = {
              email: email,
              id_token: resp.dataJ.id_token,
              access_token: resp.dataJ.access_token,
              refresh_token:resp.dataJ.refresh_token
            }
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            navigate('/dashboard',{ state: { type: true } });          
          }, 1000);
  
        }else if(resp.message.toLowerCase() === 'change_password'){
          setMessage(resp.info);
          setTimeout(() => {
            setLoading(false);
            setShowForgotPassword(true);   
            setMessage('');         
          }, 1000);
          
        }else{
          setLoading(false);
          setMessage(resp.info);
        }
      }else if(resp.statusCode === 500){
        setLoading(false);
        setMessage(resp.info);        
    }else{
      setLoading(false);
      setMessage('Login failed. Please check your credentials.');
    }
  
    }).catch((error) => {
      setLoading(false);
      setMessage('Login failed. Please check your credentials or Network Error');
      console.log('network error:', error);
    });
      }  
 

      // if (response.ok) {
      //   setMessage('Login successful');
      // } else {
      //   setMessage('Login failed. Please check your credentials.');
      // }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [selectedUserType, setSelectedUserType] = useState('user'); // Default to 'user'
  const handleUserTypeChange = (e) => {
    setSelectedUserType(e.target.value);
  };

  // const handleForgotPasswordClick = () => {
  //   setShowForgotPassword(true);
    
  // };

  // const handleBackToLoginClick = () => {
  //   setShowForgotPassword(false);
  // };

  const handleChangeEmail = (event) => {
    setChangeEmail(event.target.value)
  }

  const handleResetPassword = (event) => {
    setResetPassword(event.target.value)
  }

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value)
  }

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value)
  }

  const handleChangePasswordSubmit = async (e) =>{
    e.preventDefault();
    if (!isStrongPassword(newPassword)) {      
      setMessage("Password must contain at least one uppercase letter, lowercase letter, number, special character and 8 characters long");return false
    }
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match!");return false
    }
    const payload = {
      "op": "CHANGE_PASSWORD",
      "username": changeEmail,
      "old_password": resetPassword,
      "new_password": newPassword
    }
    setLoading(true);      
    await postDataLogin('change_password',payload).then(resp => {
      if(resp.statusCode === 200){
        if(resp.message.toLowerCase() === 'success'){
          setMessage(resp.info);   
          setTimeout(() => {
            setLoading(false); 
            setShowForgotPassword(false);  
          }, 1000);
        }else{
          setLoading(false);
          setMessage(resp.info);
        }
      }else if(resp.statusCode === 500){
          setLoading(false);
          setMessage(resp.info);        
      }else{
        setLoading(false);
        setMessage('Change Password failed. Please check your credentials.');
      }
    }).catch((error) => {
      setLoading(false);
      setMessage('Change Password failed. Please check your credentials or Network Error');
      console.log('network error:', error);
    });

  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleResetPasswordVisibility = () => {
    setShowResetPassword(!showResetPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  function isStrongPassword(password) {
    // At least 8 characters long
    if (password.length < 8) {
        return false;
    }
    // Contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
        return false;
    }
    // Contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
        return false;
    }
    // Contains at least one digit
    if (!/\d/.test(password)) {
        return false;
    }
    // Contains at least one special character
    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
        return false;
    }
    return true;
}



  return (
    <div >
        <div className="upwelltitle"  style={{ display: 'flex' }}> <span><img src="./upwelllogo.png" alt="upwell" className="logoStyling" /> </span> 
         <span className="addTitle"> Login Page</span> </div>
        {/* <div className="main">
        <div className="sub-main">
          
       <div style={{width:'90%'}}>
       <div style={{padding:"20px 10px"}}>
          <label style={{padding: '0px 40px'}}>
        <input
          type="radio"
          value="user"
          checked={selectedUserType === 'user'}
          onChange={handleUserTypeChange}
        />
        <span className='log-type-title'> User </span> 
      </label>
      <label style={{padding: '0px 40px'}}>
        <input
          type="radio"
          value="admin"
          checked={selectedUserType === 'admin'}
          onChange={handleUserTypeChange}
        />
       <span className='log-type-title'> Admin </span> 
      </label>
          </div>
         <div className="imgs">
           <div className="container-image">
             <img src={profile} alt="profile" className="profile"/>
           </div>
         </div>
         <div>
       
           <form onSubmit={handleLogin}>
           <div>
           <input
        type="email"
        placeholder="Enter Email"
        value={email}
        onChange={(e) => handleEmail(e)}
        className="name input-box"
        required
      />
           </div>
           <div className="second-input">
           <input
        type="password"
        placeholder="Enter Password"
        value={password}
        onChange={(e) => handlePassword(e)}
        className="name input-box"
        required
      />
           </div> 
          <div>
          <p style={{color:'red'}}>{message}</p>
          </div>
          <div className="login-button">
          <button type='submit' className='log-button' disabled={loading}> {loading ? 'Loading...' : 'Login'}</button>
          </div>
          </form>          
          <div>
            <button className='forgot-btn' onClick={handleForgotPasswordClick}> Forgot Password? </button>
          </div>
         </div>
       </div>
     </div>
        </div> */}

        {!showForgotPassword ? ( <div className="main">
        <div className="sub-main">
          
       <div style={{width:'90%'}}>
       <div style={{padding:"20px 10px"}}>
          <label style={{padding: '0px 40px'}}>
        <input
          type="radio"
          value="user"
          checked={selectedUserType === 'user'}
          onChange={handleUserTypeChange}
        />
        <span className='log-type-title'> User </span> 
      </label>
      <label style={{padding: '0px 40px'}}>
        <input
          type="radio"
          value="admin"
          checked={selectedUserType === 'admin'}
          onChange={handleUserTypeChange}
        />
       <span className='log-type-title'> Admin </span> 
      </label>
          </div>
         <div className="imgs">
           <div className="container-image">
             <img src={profile} alt="profile" className="profile"/>
           </div>
         </div>
         <div style={{paddingBottom:'20px'}}>       
           <form onSubmit={handleLogin}>
           <div>
           <input
        type="email"
        placeholder="Enter Email"
        value={email}
        onChange={(e) => handleEmail(e)}
        className="name input-box"
        required
      />
             {/* <input type="text" placeholder="user name" className="name input-box"/> */}
           </div>
           <div className="second-input">
           <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter Password"
              value={password}
              onChange={(e) => handlePassword(e)}
              className="name input-box password-input"
              style={{border:'none'}}
              required
            />
            <span onClick={togglePasswordVisibility}>
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </span>
             {/* <input type="password" placeholder="user name" className="name input-box"/> */}
           </div> 
          <div>
          <p style={{color:'red'}}>{message}</p>
          </div>
          <div className="login-button">
          <button type='submit' className='log-button' disabled={loading}> {loading ? 'Loading...' : 'Login'}</button>
          </div>
          </form>        
          {/* <div>
            <button className='forgot-btn' onClick={handleForgotPasswordClick}> Forgot Password? </button>
          </div> */}
         </div>
       </div>
     </div>
        </div>):(
           <div className="main">
           <div className="sub-main">
             
          <div style={{width:'90%'}}>
            <div style={{paddingBottom:'20px'}}>
              <h2>Change Password</h2>
              </div>
            <div style={{paddingBottom:'20px'}}>
          
              <form onSubmit={handleChangePasswordSubmit}>
              <div>
              <input
                type="email"
                placeholder="Enter Email"
                value={changeEmail}
                onChange={(e) => handleChangeEmail(e)}
                className="name input-box"
                required
              />
              </div>
              <div className="second-input">
           <input
              type={showResetPassword ? 'text' : 'password'}
              placeholder="Enter Old Password"
              value={resetPassword}
              onChange={(e) => handleResetPassword(e)}
              className="name input-box password-input"
              style={{border:'none'}}
              required
            />
            <span onClick={toggleResetPasswordVisibility}>
              <FontAwesomeIcon icon={showResetPassword ? faEye : faEyeSlash} />
            </span>
             {/* <input type="password" placeholder="user name" className="name input-box"/> */}
           </div> 
           <div className="second-input">
           <input
              type={showNewPassword ? 'text' : 'password'}
              placeholder="Enter New Password"
              value={newPassword}
              onChange={(e) => handleNewPassword(e)}
              className="name input-box password-input"
              style={{border:'none'}}
              required
            />
            <span onClick={toggleNewPasswordVisibility}>
              <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} />
            </span>
             {/* <input type="password" placeholder="user name" className="name input-box"/> */}
           </div> 
           <div className="second-input">
           <input
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Enter Confirm Password"
              value={confirmPassword}
              onChange={(e) => handleConfirmPassword(e)}
              className="name input-box password-input"
              style={{border:'none'}}
              required
            />
            <span onClick={toggleConfirmPasswordVisibility}>
              <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
            </span>
             {/* <input type="password" placeholder="user name" className="name input-box"/> */}
           </div>
           <div>            
             <span> <span style={{color:'red'}}>Note:</span> <span style={{color:'#333'}}>Password should be something like Password@123 and 8 characters long</span> </span>
            </div> 
             <div>
             <p style={{color:'red'}}>{message}</p>
             </div>
             <div className="login-button">
             <button type='submit' className='log-button' disabled={loading}> {loading ? 'Loading...' : 'Reset Password'}</button>
             </div>
             </form>
             {/* <div>
            <button className='forgot-btn' onClick={handleBackToLoginClick}> Login </button>
          </div> */}
            </div>
          </div>
        </div>
           </div>
        )}
  
    </div>
  );
}

export default LoginPage;