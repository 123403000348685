import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../AddNewBatch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { getData, getIdRefreshToken } from '../../ApiServices/ApiService';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import '../login';

function LogisticsReport() {
    const location = useLocation();
    const batchNo = location.state?.name;
    const update = location.state?.update;
    const viewData = location.state?.view;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const [error, setError] = useState('');
    const [sfeError, setSfeError] = useState('');
    const [extractorError, setExtractorError] = useState('');
    const [upwellClientError, setUpwellClientError] = useState('');
    const [newSupplier, setNewSupplier] = useState('');
    const [isDataLoad, setIsDataLoad] = useState(false);

    const [supplierList, setSupplierList] = useState([]);
    const [sfeSupplierList, setSfeSupplierList] = useState([]);
    const [extractorSupplierList, setExtractorSupplierList] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [sfeTollingSupplierFetched, setSfeTollingSupplierFetched] = useState(false);
    const [extractorSupplierFetched, setExtractorSupplierFetched] = useState(false);
    const [upwellClientsFetched, setUpwellClientsFetched] = useState(false);
    const [isSFETollingDataLoaded, setIsSFETollingDataLoaded] = useState(false);
    const [isExtractorDataLoaded, setIsExtractorDataLoaded] = useState(false);

    const [sfeTollingQldbDataFetched, setSfeTollingQldbDataFetched] = useState(false);
    const [sfeTollingQldbError, setSfeTollingQldbError] = useState('');
    const [extractorQldbDataFetched, setExtractorQldbDataFetched] = useState(false);
    const [extractorQldbError, setExtractorQldbError] = useState('');
    const [sfeTollingQldbList, setSfeTollingQldbList] = useState([]);
    const [extractorQldbList, setExtractorQldbList] = useState([]);
    const [upwellClientsQldbList, setUpwellClientsQldbList] = useState([]);

    const contentRef = useRef(null);
    const [pdf, setPdf] = useState(null);
    const qldbContentRef = useRef(null);
    const [presignedUrlError, setPresignedUrlError] = useState('');
    const [singlePdf, setSinglePdf] = useState(false);
    const [mergePdf, setMergePdf] = useState(false);
    const [qldbPdf, setQldbPdf] = useState(false);
    const [sfeTollingSupplier, setSfeTollingSupplier] = useState('');
    const [extractorSupplier, setExtractorSupplier] = useState('');

    const isdownloadAll = sfeTollingSupplier !== '' || extractorSupplier !== '';
    const userData = JSON.parse(localStorage.getItem('userInfo'));
    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            if (!sfeTollingSupplierFetched) {
                getSfeTollingSupplierBatchList();
            }

            if (!extractorSupplierFetched) {
                getExtractorSupplierBatchList();
            }

            if (!upwellClientsFetched) {
                getUpwellClientData();
            }
        } else {
            callLogout();
        }
    }, [sfeTollingSupplierFetched, batchNo, extractorSupplierFetched, upwellClientsFetched, viewData])

    const getSfeTollingSupplierBatchList = () => {
        const getPayload = {
            "op": "GET_SUPPLIER_BATCH_SUPPLIER_TO_SFE_TOLLING",
            "batchno": batchNo
        }
        setSfeError('');
        getData('logi', JSON.stringify(getPayload), userData.id_token).then(resp => {
            setSfeTollingSupplierFetched(true);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ.length > 0) {
                                setSfeError('');
                                setSfeSupplierList(respData.dataJ);
                            } else {
                                setSfeError(respData.info);
                                setSfeSupplierList([]);
                            }
                        } else {
                            setSfeError(respData.info);
                            setSfeSupplierList([]);
                        }
                    } else {
                        setSfeError('Error While Getting SFE Tolling Supplier List');
                        setSfeSupplierList([]);
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getSfeTollingBatch', '');
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setSfeError('Error Occurred While Loading SFE Tolling Supplier List');
                    setSfeSupplierList([]);
                }
            }).catch((error) => {
                console.log('network error:', error);
                setSfeError('Network Error While Getting SFE Tolling Supplier List');
                setSfeSupplierList([]);
            });
        }).catch((error) => {
            console.log('network error:', error);
            setSfeError('Network Error While Getting SFE Tolling Supplier List!');
            setSfeSupplierList([]);
        });
    }

    const getExtractorSupplierBatchList = () => {
        const getPayload = {
            op: "GET_SUPPLIER_BATCH_SUPPLIER_SFE_TO_EXTRACTOR",
            batchno: batchNo
        }
        setExtractorError('');
        getData('logi', JSON.stringify(getPayload), userData.id_token).then(resp => {
            setExtractorSupplierFetched(true);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ.length > 0) {
                                setExtractorError('');
                                setExtractorSupplierList(respData.dataJ);
                            } else {
                                setExtractorError(respData.info);
                                setExtractorSupplierList([]);
                            }
                        } else {
                            setExtractorError(respData.info);
                            setExtractorSupplierList([]);
                        }
                    } else {
                        setExtractorError('Error While Getting Extractor Supplier List');
                        setExtractorSupplierList([]);
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getExtractorBatch', '');
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setExtractorError('Error Occurred While Loading Extractor Supplier List');
                    setExtractorSupplierList([]);
                }
            }).catch((error) => {
                console.log('network error:', error);
                setExtractorError('Network Error While Getting Extractor Supplier List');
                setExtractorSupplierList([]);
            });
        }).catch((error) => {
            console.log('network error:', error);
            setExtractorError('Network Error While Getting Extractor Supplier List!');
            setExtractorSupplierList([]);
        });
    }

    const getUpwellClientData = () => {
        const getPayload = {
            op: "GET_UPWELL_TO_CLIENT",
            batchno: batchNo
        }
        setUpwellClientError('');
        getData('logi', JSON.stringify(getPayload), userData.id_token).then(resp => {
            setUpwellClientsFetched(true);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ !== undefined) {
                                setUpwellClientError('');
                                setUpwellClientInfo((prevUpwellClientInfo) => ({
                                    ...prevUpwellClientInfo,
                                    ...respData.dataJ,
                                }));
                                setMaterialInfo((prevMaterialInfo) => ({
                                    ...prevMaterialInfo,
                                    ...respData.dataJ,
                                }));
                            } else {
                                setUpwellClientError(respData.info);
                                upwellClientDataEmpty();
                            }
                        } else {
                            setUpwellClientError(respData.info);
                            upwellClientDataEmpty();
                        }
                    } else {
                        setUpwellClientError('Error While Getting Logistics Clients Data');
                        upwellClientDataEmpty();
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getUpwellClient', '');
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setUpwellClientError('Error Occurred While Loading Logistics Clients Data');
                    upwellClientDataEmpty();
                }
            }).catch((error) => {
                console.log('network error:', error);
                setUpwellClientError('Network Error While Getting Logistics Clients Data');
                upwellClientDataEmpty();
            });
        }).catch((error) => {
            console.log('network error:', error);
            setUpwellClientError('Network Error While Getting Logistics Clients Data!');
            upwellClientDataEmpty();
        });
    }

    const callRefreshTokenApi = (type, event) => {
        const payload = {
            "email": userData.email,
            "oldRefreshToken": userData.refresh_token
        }
        getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
            if (resp.statusCode === 200) {
                if (resp.message.toLowerCase() === 'success') {
                    userData.id_token = resp.dataJ.id_token;
                    localStorage.setItem('userInfo', JSON.stringify(userData));
                    if (type === 'getSfeTollingBatch') {
                        getSfeTollingSupplierBatchList();
                    } else if (type === 'getExtractorBatch') {
                        getExtractorSupplierBatchList();
                    } else if (type === 'getUpwellClient') {
                        getUpwellClientData();
                    } else if (type === 'getSfeTollingAll') {
                        getSfeTollingData(event);
                    } else if (type === 'getSfeTollingQldb') {
                        getSfeTollingQldbData(event);
                    } else if (type === 'getExtractorAll') {
                        getExtractorData(event);
                    } else if (type === 'getExtractorQldb') {
                        getExtractorQldbData(event);
                    } else if (type === 'getReportAll') {
                        getPresignedUrl();
                    } else {
                        console.log('type', type)
                    }
                } else {
                    callLogout();
                }
            } else {
                callLogout();
            }
        }).catch((error) => {
            console.log('refresh error', error);
            callLogout();
        });
    }

    const callLogout = () => {
        localStorage.removeItem('isAdminLogin');
        localStorage.removeItem('userInfo');
        localStorage.clear();
        navigate('/');
        window.location.reload();
    }

    const upwellClientDataEmpty = () => {
        setUpwellClientInfo({
            shipped_to: '',
            shipper: '',
            receivedDate: '',
            shippedDate: '',
            air_sea: '',
            shipping_manifest_file_logi: '',
            proof_of_delivery_file_logi: '',
            Client_po_file_logi: '',
            shipping_manifest_file_logi_display_name: '',
            proof_of_delivery_file_logi_display_name: '',
            Client_po_file_logi_display_name: ''
        })

        setMaterialInfo({
            upwell_butter: '',
            upwell_wax: '',
            upwell_oleo: '',
            upwell_hydro: '',
            spent_biomass: ''
        });
    }

    const [sfeResponseInfo, setSfeResponseInfo] = useState({
        Supplier: '',
        associatedPo: '',
        air_sea: '',
        receivedDate: '',
        shippedDate: '',
        shipped_to: '',
        shipper: '',
        supplierBatch: '',
        shipping_manifest_file_logi_1: '',
        proof_of_delivery_file_logi_1: '',
        shipping_manifest_file_display_name: '',
        proof_of_delivery_file_display_name: ''
    })

    const [extractorInfo, setExtractorInfo] = useState({
        Supplier: '',
        associatedPo: '',
        air_sea: '',
        receivedDate: '',
        shippedDate: '',
        shipped_to: '',
        shipper: '',
        supplierBatch: '',
        shipping_manifest_file_logi_2: '',
        proof_of_delivery_file_logi_2: '',
        shipping_manifest_file_display_name: '',
        proof_of_delivery_display_name: ''
    })

    const [upwellClientInfo, setUpwellClientInfo] = useState({
        shipped_to: '',
        shipper: '',
        receivedDate: '',
        shippedDate: '',
        air_sea: '',
        shipping_manifest_file_logi: '',
        proof_of_delivery_file_logi: '',
        Client_po_file_logi: '',
        shipping_manifest_file_logi_display_name: '',
        proof_of_delivery_file_logi_display_name: '',
        Client_po_file_logi_display_name: ''
    })

    const [materialInfo, setMaterialInfo] = useState({
        upwell_butter: '',
        upwell_wax: '',
        upwell_oleo: '',
        upwell_hydro: '',
        spent_biomass: ''
    });

    const handleSuplierChange = (event, supplierValue) => {
        console.log('update', event);
        if (supplierValue === 'sfeTolling') {
            if (!event) {
                setSfeTollingSupplier('');
                setSfeTollingQldbList([]);
                sfeTollingDataEmpty(); return;
            }
            setSfeTollingSupplier(event);
            getSfeTollingData(event);
            getSfeTollingQldbData(event);
        } else {
            if (!event) {
                setExtractorSupplier('');
                setExtractorQldbList([]);
                setUpwellClientsQldbList([]);
                extractorDataEmpty(); return;
            }
            setExtractorSupplier(event);
            getExtractorData(event);
            getExtractorQldbData(event);
        }
    }

    const getSfeTollingData = async (event) => {
        const getPayload = {
            "op": "GET_LOGI_SUPPLIER_TO_SFE_TOLLING",
            "batchno": batchNo,
            "supplierBatch": event
        }
        setSfeError('');
        setIsDataLoad(true);
        setIsSFETollingDataLoaded(false);
        await getData('logi', JSON.stringify(getPayload), userData.id_token).then(resp => {
            // setIsDataLoad(false);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ !== undefined) {
                                setIsSFETollingDataLoaded(true);
                                setSfeError('');
                                setSfeResponseInfo((prevSfeResponseInfo) => ({
                                    ...prevSfeResponseInfo,
                                    ...respData.dataJ,
                                }));
                            } else {
                                setSfeError(respData.info);
                                sfeTollingDataEmpty();
                            }
                        } else {
                            setSfeError(respData.info);
                            sfeTollingDataEmpty();
                        }
                    } else {
                        setSfeError('Error While Getting Logistics SFE Tolling Data');
                        sfeTollingDataEmpty();
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getSfeTollingAll', event);
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setSfeError('Error Occurred While Getting Logistics SFE Tolling Data');
                    sfeTollingDataEmpty();
                }
            }).catch((error) => {
                setIsDataLoad(false);
                console.log('network error:', error);
                setSfeError('Network Error While Getting Logistics SFE Tolling Data');
                sfeTollingDataEmpty();
            });
        }).catch((error) => {
            setIsDataLoad(false);
            console.log('network error:', error);
            setSfeError('Network Error While Getting Logistics SFE Tolling Data!');
            sfeTollingDataEmpty();
        });
    }

    const getExtractorData = async (event) => {
        const getPayload = {
            "op": "GET_LOGI_SFE_TOLLING_TO_EXTRACTOR",
            "batchno": batchNo,
            "supplierBatch": event
        }
        setExtractorError('');
        setIsDataLoad(true);
        setIsExtractorDataLoaded(false);
        await getData('logi', JSON.stringify(getPayload), userData.id_token).then(resp => {
            // setIsDataLoad(false);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ !== undefined) {
                                setExtractorError('');
                                setIsExtractorDataLoaded(true);
                                setIsSFETollingDataLoaded(true);
                                setExtractorInfo((prevExtractorInfo) => ({
                                    ...prevExtractorInfo,
                                    ...respData.dataJ,
                                }));
                            } else {
                                setExtractorError(respData.info);
                                extractorDataEmpty();
                            }
                        } else {
                            setExtractorError(respData.info);
                            extractorDataEmpty();
                        }
                    } else {
                        setExtractorError('Error While Getting Logistics Extractor Data');
                        extractorDataEmpty();
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getExtractorAll', event);
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setExtractorError('Error Occurred While Getting Logistics Extractor Data');
                    extractorDataEmpty();
                }
            }).catch((error) => {
                setIsDataLoad(false);
                console.log('network error:', error);
                setExtractorError('Network Error While Getting Logistics Extractor Data');
                extractorDataEmpty();
            });
        }).catch((error) => {
            setIsDataLoad(false);
            console.log('network error:', error);
            setExtractorError('Network Error While Getting Logistics Extractor Data!');
            extractorDataEmpty();
        });
    }

    const sfeTollingDataEmpty = () => {
        setSfeResponseInfo({
            shippedDate: '',
            receivedDate: '',
            supplierBatch: '',
            associatedPo: '',
            Supplier: '',
            shipped_to: '',
            shipper: '',
            air_sea: '',
            shipping_manifest_file_logi_1: '',
            proof_of_delivery_file_logi_1: '',
            shipping_manifest_file_display_name: '',
            proof_of_delivery_file_display_name: ''
        });
    }

    const extractorDataEmpty = () => {
        setExtractorInfo({
            Supplier: '',
            associatedPo: '',
            air_sea: '',
            receivedDate: '',
            shippedDate: '',
            shipped_to: '',
            shipper: '',
            supplierBatch: '',
            shipping_manifest_file_logi_2: '',
            proof_of_delivery_file_logi_2: '',
            shipping_manifest_file_display_name: '',
            proof_of_delivery_display_name: ''
        })
    }

    const getSfeTollingQldbData = async (event) => {
        const getPayload = {
            "op": "GET_ALL_LOGISTICS_DETAILS_QLDB_PG1",
            "batchNo": batchNo,
            "supplierBatch": event
        }
        setSfeTollingQldbError('');
        setIsDataLoad(true);
        setSfeTollingQldbDataFetched(false);
        await getData('logi', JSON.stringify(getPayload), userData.id_token).then(resp => {
            // setIsDataLoad(false);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ !== undefined) {
                                if (respData.dataJ.supplier_to_sfe.length > 0) {
                                    setSfeTollingQldbDataFetched(true);
                                    setSfeTollingQldbError('');
                                    setSfeTollingQldbList(respData.dataJ.supplier_to_sfe);
                                } else {
                                    setSfeTollingQldbError(respData.info);
                                    setSfeTollingQldbList([]);
                                }
                            } else {
                                setSfeTollingQldbError(respData.info);
                                setSfeTollingQldbList([]);
                            }
                        } else {
                            setSfeTollingQldbError(respData.info);
                            setSfeTollingQldbList([]);
                        }
                    } else {
                        setSfeTollingQldbError('Error While Getting Logistics SFE Tolling QLDB Report');
                        setSfeTollingQldbList([]);
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getSfeTollingQldb', event);
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setSfeTollingQldbError('Error Occurred While Getting Logistics SFE Tolling QLDB Report');
                    setSfeTollingQldbList([]);
                }
            }).catch(error => {
                setIsDataLoad(false);
                setSfeTollingQldbError('Network Error While Getting Logistics SFE Tolling QLDB Report');
                setSfeTollingQldbList([]);
            })
        }).catch(error => {
            setIsDataLoad(false);
            setSfeTollingQldbError('Network Error While Getting Logistics SFE Tolling QLDB Report!');
            setSfeTollingQldbList([]);
        })
    }

    const getExtractorQldbData = async (event) => {
        const getPayload = {
            "op": "GET_ALL_LOGISTICS_DETAILS_QLDB_PG2_3",
            "batchNo": batchNo,
            "supplierBatch": event
        }
        setExtractorQldbError('');
        setIsDataLoad(true);
        setExtractorQldbDataFetched(false);
        await getData('logi', JSON.stringify(getPayload), userData.id_token).then(resp => {
            // setIsDataLoad(false);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ !== undefined) {
                                let extractorQldb = respData.dataJ.sfe_to_extractor;
                                let upwellClientsQldb = respData.dataJ.upwell_to_clients;
                                if (extractorQldb.length > 0 || upwellClientsQldb.length > 0) {
                                    setExtractorQldbDataFetched(true);
                                    setSfeTollingQldbDataFetched(true);
                                    setExtractorQldbError('');
                                    setExtractorQldbList(extractorQldb);
                                    setUpwellClientsQldbList(upwellClientsQldb);
                                } else {
                                    setExtractorQldbError(respData.info);
                                    setExtractorQldbList([]);
                                    setUpwellClientsQldbList([]);
                                }
                            } else {
                                setExtractorQldbError(respData.info);
                                setExtractorQldbList([]);
                                setUpwellClientsQldbList([]);
                            }
                        } else {
                            setExtractorQldbError(respData.info);
                            setExtractorQldbList([]);
                            setUpwellClientsQldbList([]);
                        }
                    } else {
                        setExtractorQldbError('Error While Getting Logistics Extractor QLDB Report');
                        setExtractorQldbList([]);
                        setUpwellClientsQldbList([]);
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getExtractorQldb', event);
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setExtractorQldbError('Error Occurred While Getting Logistics Extractor QLDB Report');
                    setExtractorQldbList([]);
                    setUpwellClientsQldbList([]);
                }
            }).catch(error => {
                setIsDataLoad(false);
                setExtractorQldbError('Network Error While Getting Logistics Extractor QLDB Report');
                setExtractorQldbList([]);
                setUpwellClientsQldbList([]);
            })
        }).catch(error => {
            setIsDataLoad(false);
            setExtractorQldbError('Network Error While Getting Logistics Extractor QLDB Report!');
            setExtractorQldbList([]);
            setUpwellClientsQldbList([]);
        })
    }

    const downloadPdf = () => {
        setSinglePdf(true);
        const content = contentRef.current;
        if (!content) {
            return;
        }

        // Use html2canvas to capture the content as an image
        html2canvas(content).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 210;
            const pageHeight = 295;

            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const pdf = new jsPDF('p', 'mm');
            let position = 0;

            // Add the captured image to the PDF
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            // Download the PDF
            pdf.save('Logistics Report.pdf');
            setSinglePdf(false);
        });
    };

    const downloadUploadedPdf = (fileName, fileBase64) => {
        console.log('downloadUploadedPdf fileName , ', fileName);
        const byteArray = new Uint8Array(window.atob(fileBase64).split('').map((char) => char.charCodeAt(0)));

        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    }

    const handleRedirect = (path) => {
        navigate(path, { state: { name: batchNo, update: update, view: viewData } });
    }

    const downloadQldbPdf = () => {
        setQldbPdf(true);
        const content = qldbContentRef.current;

        if (!content) {
            return;
        }

        // Use html2canvas to capture the content as an image
        html2canvas(content).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 210;
            const pageHeight = 295;

            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const pdf = new jsPDF('p', 'mm');
            let position = 0;

            // Add the captured image to the PDF
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            // Download the PDF
            pdf.save('Logistics QLDB Reports.pdf');
            setQldbPdf(false);
        });
    }

    const isChanged = (current, previous, key) => {
        if (current[key] !== previous[key]) {
            return true;
        }
        return false;
    }

    const gmtToLocalTime = (gmtTime) => {
        const gmtDate = new Date(gmtTime);
        const localTime = gmtDate.toLocaleString();
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        const localTimeString = localTime.toLocaleString(undefined, options);
        return localTimeString.replace(/\//g, '-').replace(/\,/g, '');
    }

    const getPresignedUrl = () => {
        console.log('getPresignedUrl : ');
        if (sfeTollingSupplier !== '' && extractorSupplier === '') {
            console.log(' if sfeTollingSupplier : ', sfeTollingSupplier, 'extractorSupplier : ', extractorSupplier);
            getAllReportPresignedUrl('type1', batchNo, sfeTollingSupplier, '', '');
        } else if (sfeTollingSupplier === '' && extractorSupplier !== '') {
            console.log(' else if 1 sfeTollingSupplier : ', sfeTollingSupplier, 'extractorSupplier : ', extractorSupplier);
            getAllReportPresignedUrl('type2', '', '', batchNo, extractorSupplier);
        } else if (sfeTollingSupplier !== '' && extractorSupplier !== '') {
            console.log(' else if 2 sfeTollingSupplier : ', sfeTollingSupplier, 'extractorSupplier : ', extractorSupplier);
            getAllReportPresignedUrl('type3', batchNo, sfeTollingSupplier, batchNo, extractorSupplier);
        } else {
            setPresignedUrlError('Select Supplier Batch')
            console.log('else sfeTollingSupplier : ', sfeTollingSupplier, 'extractorSupplier : ', extractorSupplier);
        }
    }

    const getAllReportPresignedUrl = (type, batchNo_1, supplierBatch_1, batchNo_2, supplierBatch_2) => {
        setMergePdf(true);
        const getPayload = {
            op: "GET_ALL_LOGISTICS_DETAILS_BACKED_REPORT",
            type: type,
            logi_1_batchno: batchNo_1,
            logi_1_supplierBatch: supplierBatch_1,
            logi_2_batchno: batchNo_2,
            logi_2_supplierBatch: supplierBatch_2
        }
        setPresignedUrlError('');
        setIsDataLoad(true);
        getData('reports', JSON.stringify(getPayload), userData.id_token).then(resp => {
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            setPresignedUrlError('');
                            downloadAllReport(respData.dataJ.presignedurl);
                        } else {
                            setMergePdf(false);
                            setPresignedUrlError(respData.info);
                        }
                    } else {
                        setMergePdf(false);
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getReportAll', '');
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setPresignedUrlError('Error Occurred While Getting Logistics All Report Data');
                    setMergePdf(false);
                }
            }).catch(error => {
                setIsDataLoad(false);
                setPresignedUrlError('Network Error While Getting Logistics All Report Data');
                setMergePdf(false);
            })
        }).catch(error => {
            setIsDataLoad(false);
            setPresignedUrlError('Network Error While Getting Logistics All Report Data!');
            setMergePdf(false);
        })
    }

    const downloadAllReport = (presignedUrl) => {
        const link = document.createElement('a');
        link.href = presignedUrl;
        link.download = 'Logistics Report.pdf';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        setMergePdf(false);
    }

    return (
        <div>
            <div className="reporttitle" style={{ display: 'flex' }}><img src="./upwelllogo.png" alt="upwell" className="logoStyling" />
                <span className="addTitle">Logistics Report</span>
                <button onClick={() => handleRedirect('/dashboard')} className="home-btn" style={{ paddingRight: '50px' }}> <FontAwesomeIcon icon={faHome} className="homeButton" /></button>
            </div>
            <div className="reportContainer">
                {isDataLoad && (
                    <div className="dot-loader">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                )}
                <div className="batchNoStyling">Batch Number: {batchNo} </div>
                <div className="displayFlex">
                    <div className="flexAuto">
                        <div className="subtitle">Logistics Supplier To SFE Tolling</div>
                        {/* <div className="select-batch-style"> Select the Supplier Batch To Get the Data </div> */}
                        <div className="flexAuto" style={{ paddingTop: '6px' }}>
                            <fieldset  >
                                <legend className="scheduler-border">Select Supplier batch:</legend>
                                {sfeSupplierList?.length >= 0 ? (
                                    <select onChange={(e) => handleSuplierChange(e.target.value, 'sfeTolling')} style={{ border: 'none', outline: 'none', width: '150px' }}>
                                        <option value="">Select Supplier</option>
                                        {sfeSupplierList?.map((item, index) => (
                                            <option key={index}>
                                                {item.supplierBatch}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <select onChange={(e) => handleSuplierChange(e.target.value, 'sfeTolling')} style={{ border: 'none', outline: 'none', width: '150px' }}>
                                        {sfeError === '' && sfeSupplierList?.length <= 0 ? (
                                            <option value="" selected disabled>Loading...</option>) : (
                                            <option value="" selected disabled>No Suppliers</option>)}
                                    </select>
                                )}
                            </fieldset>
                        </div>
                        {sfeError && <p style={{ color: 'red' }}>{sfeError}</p>}
                    </div>

                    <div className="flexAuto">
                        <div className="subtitle">Logistics SFE Tolling To Extractor</div>
                        {/* <div className="select-batch-style"> Select the Supplier Batch To Get the Data </div> */}
                        <div className="flexAuto" style={{ paddingTop: '6px' }}>
                            <fieldset  >
                                <legend className="scheduler-border">Select Supplier batch:</legend>
                                {extractorSupplierList?.length >= 0 ? (
                                    <select onChange={(e) => handleSuplierChange(e.target.value, 'extractor')} style={{ border: 'none', outline: 'none', width: '150px' }}>
                                        <option value="">Select Supplier</option>
                                        {extractorSupplierList?.map((item, index) => (
                                            <option key={index}>
                                                {item.supplierBatch}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <select onChange={(e) => handleSuplierChange(e.target.value, 'extractor')} style={{ border: 'none', outline: 'none', width: '150px' }}>
                                        {extractorError === '' && extractorSupplierList?.length <= 0 ? (
                                            <option value="" selected disabled>Loading...</option>) : (
                                            <option value="" selected disabled>No Suppliers</option>)}
                                    </select>
                                )}
                            </fieldset>
                        </div>
                        {extractorError && <p style={{ color: 'red' }}>{extractorError}</p>}
                    </div>
                </div>

                {/* <div className="subtitle" style={{ paddingTop: '15px' }}>Logistics Upwell to Clients</div> */}

                <div className="download-div">
                    {isSFETollingDataLoaded && <span><button onClick={downloadPdf} className="dwnld-btn">{singlePdf ? 'Generating...' : 'Download PDF'}</button></span>}
                    {sfeTollingQldbDataFetched && <span><button onClick={downloadQldbPdf} className="dwnld-btn" style={{ maxWidth: '180px' }}>{qldbPdf ? 'Generating...' : 'Download QLDB PDF'}</button></span>}
                    {isdownloadAll && (<span><button onClick={getPresignedUrl} className="dwnld-btn">{mergePdf ? 'Generating...' : 'Download All'}</button></span>)}
                    <div>{presignedUrlError && <p className="error">{presignedUrlError}</p>}</div>
                </div>

                {isSFETollingDataLoaded && (<div>
                    <div ref={contentRef} className="pdfPage-styling">
                        <div >
                            <img src="./upwelllogo.png" alt="upwell" className="logoStyling" style={{ padding: '0px' }} />
                        </div>
                        <div className="reportheading" >Logistics</div>
                        <div className="displayFlex">
                            <div className="batchNoStyling  display-batch">Batch Number: {batchNo} </div>
                        </div>
                        <div className="tabletitle" style={{ marginRight: '7px' }}>Logistics Supplier To SFE Tolling</div>
                        <div style={{ paddingBottom: '20px' }}>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Associated PO : {sfeResponseInfo.associatedPo}</div>
                                <div className="batchinfo flexAuto">Area/Sea : {sfeResponseInfo.air_sea}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Supplier Batch : {sfeResponseInfo.supplierBatch}</div>
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Supplier : {sfeResponseInfo.Supplier}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Shipper : {sfeResponseInfo.shipper}</div>
                                <div className="batchinfo flexAuto">Shipped To : {sfeResponseInfo.shipped_to}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto">Shipped Date : {sfeResponseInfo.shippedDate}</div>
                                <div className="batchinfo flexAuto">Received Date : {sfeResponseInfo.receivedDate}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo">Shipping Manifest File : {sfeResponseInfo.shipping_manifest_file_display_name}</div>
                                {sfeResponseInfo.shipping_manifest_file_logi_1 && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(sfeResponseInfo.shipping_manifest_file_display_name, sfeResponseInfo.shipping_manifest_file_logi_1)} />}
                            </div>

                            <div className="displayFlex">
                                <div className="batchinfo">Proof Of Delivery File : {sfeResponseInfo.proof_of_delivery_file_display_name}</div>
                                {sfeResponseInfo.proof_of_delivery_file_logi_1 && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(sfeResponseInfo.proof_of_delivery_file_display_name, sfeResponseInfo.proof_of_delivery_file_logi_1)} />}
                            </div>
                        </div>

                        <div className="tabletitle" style={{ marginRight: '7px' }}>Logistics SFE Tolling To Extractor</div>
                        <div style={{ paddingBottom: '20px' }}>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Associated PO : {extractorInfo.associatedPo}</div>
                                <div className="batchinfo flexAuto">Area/Sea : {extractorInfo.air_sea}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Supplier Batch : {extractorInfo.supplierBatch}</div>
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Supplier : {extractorInfo.Supplier}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Shipper : {extractorInfo.shipper}</div>
                                <div className="batchinfo flexAuto">Shipped To : {extractorInfo.shipped_to}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto">Shipped Date : {extractorInfo.shippedDate}</div>
                                <div className="batchinfo flexAuto">Received Date : {extractorInfo.receivedDate}</div>
                            </div>

                            <div className="displayFlex">
                                <div className="batchinfo">Shipping Manifest File : {extractorInfo.shipping_manifest_file_display_name}</div>
                                {extractorInfo.shipping_manifest_file_logi_2 && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(extractorInfo.shipping_manifest_file_display_name, extractorInfo.shipping_manifest_file_logi_2)} />}
                            </div>

                            <div className="displayFlex">
                                <div className="batchinfo">Proof Of Delivery File : {extractorInfo.proof_of_delivery_display_name}</div>
                                {extractorInfo.proof_of_delivery_file_logi_2 && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(extractorInfo.proof_of_delivery_display_name, extractorInfo.proof_of_delivery_file_logi_2)} />}
                            </div>
                        </div>

                        <div className="tabletitle" style={{ marginBottom: '10px', marginRight: '7px' }}>Logistics Upwell To Clients</div>
                        <div style={{ paddingBottom: '20px' }}>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Shipped To : {upwellClientInfo.shipped_to}</div>
                                <div className="batchinfo flexAuto">Area/Sea : {upwellClientInfo.air_sea}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto">Shipped Date : {upwellClientInfo.shippedDate}</div>
                                <div className="batchinfo flexAuto">Received Date : {upwellClientInfo.receivedDate}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Shipper : {upwellClientInfo.shipper}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo">Client PO File : {upwellClientInfo.Client_po_file_logi_display_name}</div>
                                {upwellClientInfo.Client_po_file_logi && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(upwellClientInfo.Client_po_file_logi_display_name, upwellClientInfo.Client_po_file_logi)} />}
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo">Shipping Manifest File : {upwellClientInfo.shipping_manifest_file_logi_display_name}</div>
                                {upwellClientInfo.shipping_manifest_file_logi && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(upwellClientInfo.shipping_manifest_file_logi_display_name, upwellClientInfo.shipping_manifest_file_logi)} />}
                            </div>

                            <div className="displayFlex">
                                <div className="batchinfo">Proof Of Delivery File : {upwellClientInfo.proof_of_delivery_file_logi_display_name}</div>
                                {upwellClientInfo.proof_of_delivery_file_logi && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(upwellClientInfo.proof_of_delivery_file_logi_display_name, upwellClientInfo.proof_of_delivery_file_logi)} />}
                            </div>
                        </div>
                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Material</div>
                        <table className="table">
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(kg)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Upwell Butter</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{materialInfo.upwell_butter}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Upwell Wax</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{materialInfo.upwell_wax}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Upwell Oleo</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{materialInfo.upwell_oleo}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Upwell Hydro</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{materialInfo.upwell_hydro}</td></tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Spent Biomass</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{materialInfo.spent_biomass}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                )}

                {sfeTollingQldbDataFetched && (<div>
                    <div ref={qldbContentRef} className="pdfPage-styling">
                        <div >
                            <img src="./upwelllogo.png" alt="upwell" className="logoStyling" style={{ padding: '0px' }} />
                        </div>
                        <div className="reportheading" >Logistics QLDB Reports</div>
                        <div className="batchNoStyling  display-batch displayFlex">Batch Number: {batchNo} </div>

                        {sfeTollingQldbList?.length > 0 && (<div>
                            <div className="tabletitle" style={{ marginRight: '7px' }}>Logistics Supplier To SFE Tolling</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th>Hash</th>
                                        <th >Created Time</th>
                                        <th >Associated PO</th>
                                        <th >Supplier Batch</th>
                                        <th >Supplier</th>
                                        <th >Shipper</th>
                                        <th >Shipped To</th>
                                        <th >Shipped Date</th>
                                        <th >Received Date</th>
                                        <th >Air/Sea</th>
                                        <th >Shipping Manifest File</th>
                                        <th >Proof Of Delivery File</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sfeTollingQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.supplier_to_sfeHash}</td>}
                                            {index !== 0 && <td className={isChanged(item, sfeTollingQldbList[index - 1], 'supplier_to_sfeHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.supplier_to_sfeHash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.supplier_to_sfeMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.supplier_to_sfeMetadata, sfeTollingQldbList[index - 1].supplier_to_sfeMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.supplier_to_sfeMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.supplier_to_sfeData.associatedPo}</td>}
                                            {index !== 0 && <td className={isChanged(item.supplier_to_sfeData, sfeTollingQldbList[index - 1].supplier_to_sfeData, 'associatedPo') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplier_to_sfeData.associatedPo}</td>}
                                            {index === 0 && <td className="tdbreak">{item.supplier_to_sfeData.supplierBatch}</td>}
                                            {index !== 0 && <td className={isChanged(item.supplier_to_sfeData, sfeTollingQldbList[index - 1].supplier_to_sfeData, 'supplierBatch') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplier_to_sfeData.supplierBatch}</td>}
                                            {index === 0 && <td className="tdbreak">{item.supplier_to_sfeData.Supplier}</td>}
                                            {index !== 0 && <td className={isChanged(item.supplier_to_sfeData, sfeTollingQldbList[index - 1].supplier_to_sfeData, 'Supplier') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplier_to_sfeData.Supplier}</td>}
                                            {index === 0 && <td className="tdbreak">{item.supplier_to_sfeData.shipper}</td>}
                                            {index !== 0 && <td className={isChanged(item.supplier_to_sfeData, sfeTollingQldbList[index - 1].supplier_to_sfeData, 'shipper') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplier_to_sfeData.shipper}</td>}
                                            {index === 0 && <td className="tdbreak">{item.supplier_to_sfeData.shipped_to}</td>}
                                            {index !== 0 && <td className={isChanged(item.supplier_to_sfeData, sfeTollingQldbList[index - 1].supplier_to_sfeData, 'shipped_to') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplier_to_sfeData.shipped_to}</td>}
                                            {index === 0 && <td className="tdbreak">{item.supplier_to_sfeData.shippedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.supplier_to_sfeData, sfeTollingQldbList[index - 1].supplier_to_sfeData, 'shippedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplier_to_sfeData.shippedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.supplier_to_sfeData.receivedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.supplier_to_sfeData, sfeTollingQldbList[index - 1].supplier_to_sfeData, 'receivedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplier_to_sfeData.receivedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.supplier_to_sfeData.air_sea}</td>}
                                            {index !== 0 && <td className={isChanged(item.supplier_to_sfeData, sfeTollingQldbList[index - 1].supplier_to_sfeData, 'air_sea') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplier_to_sfeData.air_sea}</td>}
                                            {index === 0 && <td className="tdbreak">{item.supplier_to_sfeData.shipping_manifest_file_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.supplier_to_sfeData, sfeTollingQldbList[index - 1].supplier_to_sfeData, 'shipping_manifest_file_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplier_to_sfeData.shipping_manifest_file_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.supplier_to_sfeData.proof_of_delivery_file_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.supplier_to_sfeData, sfeTollingQldbList[index - 1].supplier_to_sfeData, 'proof_of_delivery_file_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplier_to_sfeData.proof_of_delivery_file_display_name}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        )}

                        {extractorQldbList?.length > 0 && (<div>
                            <div className="tabletitle" style={{ marginRight: '7px' }}>Logistics SFE Tolling To Extractor</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th>Hash</th>
                                        <th >Created Time</th>
                                        <th >Associated PO</th>
                                        <th >Supplier Batch</th>
                                        <th >Supplier</th>
                                        <th >Shipper</th>
                                        <th >Shipped To</th>
                                        <th >Shipped Date</th>
                                        <th >Received Date</th>
                                        <th >Air/Sea</th>
                                        <th >Shipping Manifest File</th>
                                        <th >Proof Of Delivery File</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {extractorQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.sfe_to_extractorHash}</td>}
                                            {index !== 0 && <td className={isChanged(item, extractorQldbList[index - 1], 'sfe_to_extractorHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.sfe_to_extractorHash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.sfe_to_extractorMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_to_extractorMetadata, extractorQldbList[index - 1].sfe_to_extractorMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.sfe_to_extractorMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_to_extractorData.associatedPo}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_to_extractorData, extractorQldbList[index - 1].sfe_to_extractorData, 'associatedPo') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_to_extractorData.associatedPo}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_to_extractorData.supplierBatch}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_to_extractorData, extractorQldbList[index - 1].sfe_to_extractorData, 'supplierBatch') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_to_extractorData.supplierBatch}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_to_extractorData.Supplier}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_to_extractorData, extractorQldbList[index - 1].sfe_to_extractorData, 'Supplier') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_to_extractorData.Supplier}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_to_extractorData.shipper}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_to_extractorData, extractorQldbList[index - 1].sfe_to_extractorData, 'shipper') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_to_extractorData.shipper}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_to_extractorData.shipped_to}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_to_extractorData, extractorQldbList[index - 1].sfe_to_extractorData, 'shipped_to') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_to_extractorData.shipped_to}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_to_extractorData.shippedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_to_extractorData, extractorQldbList[index - 1].sfe_to_extractorData, 'shippedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_to_extractorData.shippedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_to_extractorData.receivedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_to_extractorData, extractorQldbList[index - 1].sfe_to_extractorData, 'receivedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_to_extractorData.receivedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_to_extractorData.air_sea}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_to_extractorData, extractorQldbList[index - 1].sfe_to_extractorData, 'air_sea') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_to_extractorData.air_sea}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_to_extractorData.shipping_manifest_file_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_to_extractorData, extractorQldbList[index - 1].sfe_to_extractorData, 'shipping_manifest_file_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_to_extractorData.shipping_manifest_file_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_to_extractorData.proof_of_delivery_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_to_extractorData, extractorQldbList[index - 1].sfe_to_extractorData, 'proof_of_delivery_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_to_extractorData.proof_of_delivery_display_name}</td>}
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                        )}

                        {upwellClientsQldbList?.length > 0 && (<div>
                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Logistics Upwell To Clients</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th>Hash</th>
                                        <th >Created Time</th>
                                        <th >Shipper</th>
                                        <th >Shipped To</th>
                                        <th >Shipped Date</th>
                                        <th >Received Date</th>
                                        <th >Air/Sea</th>
                                        <th >Client PO File</th>
                                        <th >Shipping Manifest File</th>
                                        <th >Proof Of Delivery File</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {upwellClientsQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.upwell_to_clientsHash}</td>}
                                            {index !== 0 && <td className={isChanged(item, upwellClientsQldbList[index - 1], 'upwell_to_clientsHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.upwell_to_clientsHash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwell_to_clientsMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsMetadata, upwellClientsQldbList[index - 1].upwell_to_clientsMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwell_to_clientsMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.shipper}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'shipper') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.shipper}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.shipped_to}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'shipped_to') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.shipped_to}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.shippedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'shippedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.shippedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.receivedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'receivedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.receivedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.air_sea}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'air_sea') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.air_sea}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.Client_po_file_logi_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'Client_po_file_logi_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.Client_po_file_logi_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.shipping_manifest_file_logi_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'shipping_manifest_file_logi_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.shipping_manifest_file_logi_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.proof_of_delivery_file_logi_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'proof_of_delivery_file_logi_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.proof_of_delivery_file_logi_display_name}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Material</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Upwell Butter (kg)</th>
                                        <th >Upwell Wax (kg)</th>
                                        <th >Upwell Oleo (kg)</th>
                                        <th >Upwell Hydro (kg)</th>
                                        <th >Spent Biomass (kg)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {upwellClientsQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwell_to_clientsMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsMetadata, upwellClientsQldbList[index - 1].upwell_to_clientsMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwell_to_clientsMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.upwell_butter}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'upwell_butter') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.upwell_butter}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.upwell_wax}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'upwell_wax') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.upwell_wax}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.upwell_oleo}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'upwell_oleo') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.upwell_oleo}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.upwell_hydro}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'upwell_hydro') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.upwell_hydro}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwell_to_clientsData.spent_biomass}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwell_to_clientsData, upwellClientsQldbList[index - 1].upwell_to_clientsData, 'spent_biomass') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwell_to_clientsData.spent_biomass}</td>}
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                        )}
                    </div>
                </div>
                )}
            </div>
        </div>
    );
}

export default LogisticsReport;