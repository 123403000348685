import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../AddNewBatch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { postData, getData, getIdRefreshToken } from '../../ApiServices/ApiService';

function RawBiomass() {
  const location = useLocation();
  const batchNo = location.state?.name;
  const update = location.state?.update;
  const viewData = location.state?.view;
  const batchInfo = location.state?.batchInfo;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [supplierError, setSupplierError] = useState('');
  const [supplierIdError, setSupplierIdError] = useState('');
  const [newSupplier, setNewSupplier] = useState('');
  const [isDataLoad, setIsDataLoad] = useState(false);

  const [supplierList, setSupplierList] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [issupplier, setIssupplier] = useState(false);
  const [supplierOptionList, setSupplierOptionList] = useState([]);
  const [addDropdownError, setAddDropdownError] = useState('');

  const [isAdmin, setisAdmin] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      if (!dataFetched) {
        if (localStorage.getItem('isAdminLogin')) {
          const adminTypeValue = localStorage.getItem('isAdminLogin');
          if (adminTypeValue === 'false') {
            setisAdmin(true);
          } else {
            if (viewData) {
              setisAdmin(true);
            } else {
              setisAdmin(false);
            }
          }
        }
        if (update) {
          console.log('If update', update);
          getSupplierBatchList();
        } else {
          console.log('els update', update);
        }
      }
      if (!viewData) {
        if (!issupplier) {
          getSupplierList();
        }
      }
    } else {
      callLogout();
    }
  }, [dataFetched, batchNo, update, issupplier, viewData]);

  const getSupplierBatchList = () => {
    const getPayload = {
      "op": "GET_SUPPLIER_DETAILS_RAWBIOMASS_FILES",
      "batchNo": batchNo
    }
    setSupplierIdError('');
    getData('file1', JSON.stringify(getPayload), userData?.id_token).then(resp => {
      setDataFetched(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === "success") {
              if (respData.dataJ.length > 0) {
                setSupplierIdError('');
                setSupplierList(respData.dataJ);
              } else {
                setSupplierIdError(respData.info);
                setSupplierList([]);
              }
            } else {
              setSupplierIdError(respData.info);
              setSupplierList([]);
            }
          } else {
            setSupplierIdError('Error While Getting Supplier Batch List');
            setSupplierList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getBatch', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setSupplierIdError('Error Occurred While Loading Supplier Batch List');
          setSupplierList([]);
        }
      }).catch((error) => {
        console.log('network error:', error);
        setSupplierList([]);
        setSupplierIdError('Network Error While Loading');
      });
    }).catch((error) => {
      console.log('network error:', error);
      setSupplierList([]);
      setSupplierIdError('Network Error While Loading!');
    });
  }

  const getSupplierList = () => {
    const payload = {
      "op": "GET_SUPPLIER_DROPDOWN"
    }
    setSupplierError('');
    getData('file1', JSON.stringify(payload), userData?.id_token).then(resp => {
      setIssupplier(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setSupplierError('');
                setSupplierOptionList(respData.dataJ);
              } else {
                setSupplierError(respData.info);
                setSupplierOptionList([]);
              }
            } else {
              setSupplierError(respData.info);
              setSupplierOptionList([]);
            }
          } else {
            setSupplierError('Error While Getting Supplier List');
            setSupplierOptionList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getSupplier', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setSupplierError('Error Occurred While Loading Supplier List');
          setSupplierOptionList([]);
        }
      }).catch((error) => {
        console.log('network error:', error);
        setSupplierError('Network While Loading');
        setSupplierOptionList([]);
      });
    }).catch((error) => {
      console.log('network error:', error);
      setSupplierError('Network While Loading!');
      setSupplierOptionList([]);
    });
  }

  const callRefreshTokenApi = (type, event) => {
    const payload = {
      "email": userData.email,
      "oldRefreshToken": userData.refresh_token
    }
    getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
      if (resp.statusCode === 200) {
        if (resp.message.toLowerCase() === 'success') {
          userData.id_token = resp.dataJ.id_token;
          localStorage.setItem('userInfo', JSON.stringify(userData));
          if (type === 'getBatch') {
            getSupplierBatchList();
          } else if (type === 'getSupplier') {
            getSupplierList();
          } else if (type === 'getRawBiomassDetails') {
            getRawBiomassDetails(event);
          } else if (type === 'insertRawBiomass') {
            insertRawBiomassData(event);
          } else if (type === 'updateRawBiomass') {
            updateRawBiomassData(event);
          } else if (type === 'updateSupplier') {
            updateSupplier();
          } else {
            console.log('type', type)
          }
        } else {
          callLogout();
        }
      } else {
        callLogout();
      }
    }).catch((error) => {
      console.log('refresh error', error);
      callLogout();
    });
  }

  const callLogout = () => {
    localStorage.removeItem('isAdminLogin');
    localStorage.removeItem('userInfo');
    localStorage.clear();
    navigate('/');
    window.location.reload();
  }

  const [pdfFileJson, setPdfFileJson] = useState({
    supplier_pesticides_files: '',
    upwell_coa_files: '',
    shipping_manifest_files: '',
    proof_of_delivery_files: '',
    supplier_coa_file: '',
  });

  const [fileNames, setFileNames] = useState({
    supplier_pesticides_files_display_name: '',
    upwell_coa_files_display_name: '',
    shipping_manifest_files_display_name: '',
    proof_of_delivery_files_display_name: '',
    supplier_coa_file_display_name: '',
  });

  const [bachPoIds, setBachPoIds] = useState({
    supplierBatch: '',
    associatedPo: '',
  });

  const [shippingDates, setshippingDates] = useState({
    shippedDate: '',
    receivedDate: '',
  });

  const [supplierId, setSupplierId] = useState('');

  const handleRedirect = (path) => {
    console.log('path', path)
    if (path === '/supplier') {
      if (!bachPoIds.supplierBatch) {
        setError('supplier ID is required');
        return
      }
      console.log('path', path, 'batchid', bachPoIds.supplierBatch)
      navigate(path, { state: { name: batchNo, batchId: bachPoIds.supplierBatch, supplierName: fileNames, update: update, view: viewData, batchInfo: batchInfo } });
    } else {
      navigate(path, { state: { name: batchNo, update: update, view: viewData, batchInfo: batchInfo } });
    }
  };


  const handleFileChange = (event, inputFile, inputName) => {
    setError('')
    var validImageFileExtensions = ['.pdf'];

    if (event.target.files[0]) {
      if (!validImageFileExtensions.some(el => event.target.files[0].name.toLowerCase().endsWith(el))) {
        setError("File not supported! only PDF files allowed");
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = () => {
        const base64Pdf = reader.result.split(',')[1]; // Get the base64 data
        console.log('base64Pdf', base64Pdf)
        setPdfFileJson({ ...pdfFileJson, [inputFile]: base64Pdf });
      };
    }
    if (event.target.files[0]) {
      setFileNames({ ...fileNames, [inputName]: event.target.files[0].name });
    }
  };

  const alphanumericRegex = /^[a-zA-Z0-9]*$/;
  const handleTextChange = (event, inputName) => {
    const newValue = event.target.value.replace(/[-.]/g, '');
    // setBachPoIds({ ...bachPoIds, [inputName]: newValue });
    if (alphanumericRegex.test(newValue)) {
      setBachPoIds({ ...bachPoIds, [inputName]: newValue });
    } else {
      // If it doesn't match, don't update the state (ignore special characters)
    }
  };

  const handleKeyPress = (event) => {
    // Prevent "-" and "." keys from being entered
    if (event.key === '-' || event.key === '.' || event.key === '+' || event.key === 'e') {
      event.preventDefault();
    }
  };

  const handleDateChange = (event, inputName) => {
    setshippingDates({ ...shippingDates, [inputName]: event.target.value });
  };

  const handleClosePopup = () => {
    document.getElementById('popupid').style.display = 'none';
  }

  const handleSelectChange = (event) => {
    setAddDropdownError('');
    if (event.target.value === 'add') {
      setNewSupplier('');
      document.getElementById('popupid').style.display = 'flex';
    } else {
      setSupplierId(event.target.value);
    }
  };

  const handleNewSupplier = (event) => {
    setNewSupplier(event.target.value);
  };

  const handleSaveSupplier = () => {
    console.log('id', newSupplier);
    if (newSupplier.trim().length <= 0) {
      setAddDropdownError('Enter Supplier'); return
    }
    updateSupplier();
  };

  const updateSupplier = () => {
    const payload = {
      "op": "UPDATE_SUPPLIER_DROPDOWN",
      "suppliers_list": newSupplier
    }
    setLoading(true);
    setAddDropdownError('');
    postData('file1', payload, userData?.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setLoading(false);
              setAddDropdownError(respData.info);
              setIssupplier(false);
              setTimeout(() => {
                document.getElementById('popupid').style.display = 'none';
              }, 1000);
            } else {
              setLoading(false);
              setAddDropdownError(respData.info);
            }
          } else {
            setLoading(false);
            setAddDropdownError('Error While Adding Supplier');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('updateSupplier', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setAddDropdownError('Error Occurred While Adding Supplier');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setAddDropdownError('Network Error While Adding Supplier');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setAddDropdownError('Network Error While Adding Supplier!');
    });
  }
  // const options = ['option1', 'option2', 'option3'];
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // Handle form submission logic
  // };

  const handleSubmit = (path) => {
    // navigate(path, { state: { name: batchNo,batchId: bachPoIds.supplierBatch,supplierName: fileNames.supplier_coa_file_display_name  ,update: update } });return

    if (!bachPoIds.supplierBatch) {
      setError('Supplier Batch is Required');
      return
    }
    if (!bachPoIds.associatedPo) {
      setError('AssociatedPo is Required');
      return
    }
    // if (!shippingDates.shippedDate) {
    //   setError('Shipped date id required');
    //   return
    // }
    if (shippingDates.receivedDate) {
      if (shippingDates.shippedDate > shippingDates.receivedDate) {
        setError('Received date cannot be greater than Shipped date');
        return
      }
    }


    if (!fileNames.supplier_coa_file_display_name) {
      setError('Add SupplierCoa Fille');
      return
    }
    if (!fileNames.upwell_coa_files_display_name) {
      setError('Add UpwellCoa Fille');
      return
    }


    const formData = new FormData();
    formData.append('supplier_pesticides_files', pdfFileJson.supplier_pesticides_files);
    formData.append('upwell_coa_files', pdfFileJson.upwell_coa_files);
    formData.append('shipping_manifest_files', pdfFileJson.shipping_manifest_files);
    formData.append('proof_of_delivery_files', pdfFileJson.proof_of_delivery_files);
    formData.append('supplier_coa_file', pdfFileJson.supplier_coa_file);

    if (update) {
      // const payload = {
      //   op: 'UPDATE_RAW_BIOMASS_DETAILS',
      //   batchNo: batchNo,
      //   supplier: supplierId,
      //   supplierBatch:bachPoIds.supplierBatch,
      //   shippedDate: shippingDates.shippedDate,
      //   receivedDate: shippingDates.receivedDate,
      //   associatedPo: bachPoIds.associatedPo,
      // };

      // navigate(path, { state: { name: batchNo,batchId: bachPoIds.supplierBatch,supplierName: fileNames.supplier_coa_file_display_name ,update: update } });return
      updateRawBiomassData(path);
    } else {
      insertRawBiomassData(path);
    }

  };

  const insertRawBiomassData = async (path) => {
    const payload = {
      "op": "RAWBIOMASS1",
      "batchNo": batchNo,
      "supplier": supplierId,
      "supplierBatch": bachPoIds.supplierBatch,
      "shippedDate": shippingDates.shippedDate,
      "receivedDate": shippingDates.receivedDate,
      "associatedPo": bachPoIds.associatedPo,
      "supplier_coa_file": pdfFileJson.supplier_coa_file,
      "shipping_manifest_files": pdfFileJson.shipping_manifest_files,
      "proof_of_delivery_files": pdfFileJson.proof_of_delivery_files,
      "supplier_pesticides_files": pdfFileJson.supplier_pesticides_files,
      "upwell_coa_files": pdfFileJson.upwell_coa_files,
      "supplier_coa_file_display_name": fileNames.supplier_coa_file_display_name,
      "shipping_manifest_files_display_name": fileNames.shipping_manifest_files_display_name,
      "proof_of_delivery_files_display_name": fileNames.proof_of_delivery_files_display_name,
      "supplier_pesticides_files_display_name": fileNames.supplier_pesticides_files_display_name,
      "upwell_coa_files_display_name": fileNames.upwell_coa_files_display_name,

    }
    setLoading(true);
    setError('');
    await postData('file1', payload, userData?.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setLoading(false);
              setError(respData.info);
              // setTimeout(() => {
              navigate(path, { state: { name: batchNo, batchId: bachPoIds.supplierBatch, supplierName: fileNames, update: update, view: viewData, batchInfo: batchInfo } });

              // }, 2000);
            } else {
              setLoading(false);
              setError(respData.info);
            }
          } else {
            setLoading(false);
            setError('Error While Adding Raw Biomass Details');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('insertRawBiomass', path);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Adding Raw Biomass Details');
          setLoading(false);
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setError('Network Error While Adding Raw Biomass Details');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setError('Network Error While Adding Raw Biomass Details!');
    });
  }

  const updateRawBiomassData = async (path) => {
    const payload = {
      "op": "UPDATE_RAW_BIOMASS_DETAILS_FILES",
      "batchNo": batchNo,
      "supplier": supplierId,
      "supplierBatch": bachPoIds.supplierBatch,
      "shippedDate": shippingDates.shippedDate,
      "receivedDate": shippingDates.receivedDate,
      "associatedPo": bachPoIds.associatedPo,
      "supplier_coa_file": pdfFileJson.supplier_coa_file,
      "shipping_manifest_files": pdfFileJson.shipping_manifest_files,
      "proof_of_delivery_files": pdfFileJson.proof_of_delivery_files,
      "supplier_pesticides_files": pdfFileJson.supplier_pesticides_files,
      "upwell_coa_files": pdfFileJson.upwell_coa_files,
      "supplier_coa_file_display_name": fileNames.supplier_coa_file_display_name,
      "shipping_manifest_files_display_name": fileNames.shipping_manifest_files_display_name,
      "proof_of_delivery_files_display_name": fileNames.proof_of_delivery_files_display_name,
      "supplier_pesticides_files_display_name": fileNames.supplier_pesticides_files_display_name,
      "upwell_coa_files_display_name": fileNames.upwell_coa_files_display_name,
    }
    setLoading(true);
    setError('');
    await postData('file1', payload, userData?.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setError(respData.info);
              // setTimeout(() => {
              setLoading(false);
              navigate(path, { state: { name: batchNo, batchId: bachPoIds.supplierBatch, supplierName: fileNames, update: update, view: viewData, batchInfo: batchInfo } });

              // }, 2000);
            } else {
              setLoading(false);
              setError(respData.info);
            }
          } else {
            setLoading(false);
            setError('Error While Updating Raw Biomass Details');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('updateRawBiomass', path);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setError('Error Occurred While Updating Raw Biomass Details');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setError('Network Error While Updating Raw Biomass Details');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setError('Network Error While Updating Raw Biomass Details!');
    });
  }


  const handleSuplierChange = (event) => {
    console.log('update', event);
    if (!event) {
      rawBiomassDetailsEmpty(); return;
    }
    getRawBiomassDetails(event);
  }

  const getRawBiomassDetails = async (event) => {
    const getPayload = {
      "op": "GET_SUPPLIER_COA_RAW_BIOMASS_DETAILS_FILES",
      "batchNo": batchNo,
      "supplierBatch": event
    }
    setError('');
    setIsDataLoad(true);
    await getData('file1', JSON.stringify(getPayload), userData?.id_token).then(resp => {
      // setIsDataLoad(false);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        setIsDataLoad(false);
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setError('');
              setSupplierId(respData.dataJ.supplier);
              setshippingDates((prevShippingDates) => ({
                ...prevShippingDates,
                ...respData.dataJ,
              }));

              setBachPoIds((prevBachPoIds) => ({
                ...prevBachPoIds,
                ...respData.dataJ,
              }));

              setFileNames((prevFileNames) => ({
                ...prevFileNames,
                ...respData.dataJ,
              }));

              setPdfFileJson((prevPdfFileJson) => ({
                ...prevPdfFileJson,
                ...respData.dataJ,
              }));
            } else {
              setError(respData.info);
              rawBiomassDetailsEmpty();
            }
          } else {
            setError('Error While Getting Raw Biomass Details');
            rawBiomassDetailsEmpty();
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getRawBiomassDetails', event);
            setIsDataLoad(true);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Getting Raw Biomass Details');
          rawBiomassDetailsEmpty();
        }
      }).catch((error) => {
        setIsDataLoad(false);
        setError('Network Error While Loading');
        console.log('network error:', error);
        rawBiomassDetailsEmpty();
      });
    }).catch((error) => {
      setIsDataLoad(false);
      setError('Network Error While Loading!');
      console.log('network error:', error);
      rawBiomassDetailsEmpty();
    });

  }

  const rawBiomassDetailsEmpty = () => {
    setSupplierId('');
    setshippingDates({
      shippedDate: '',
      receivedDate: '',
    });
    setBachPoIds({
      supplierBatch: '',
      associatedPo: '',
    });
    setPdfFileJson({
      supplier_pesticides_files: '',
      upwell_coa_files: '',
      shipping_manifest_files: '',
      proof_of_delivery_files: '',
      supplier_coa_file: '',
    });
    setFileNames({
      supplier_pesticides_files_display_name: '',
      upwell_coa_files_display_name: '',
      shipping_manifest_files_display_name: '',
      proof_of_delivery_files_display_name: '',
      supplier_coa_file_display_name: '',
    });
  }

  return (
    <div>
      <div className="upwelltitle" style={{ display: 'flex' }}><img src="./upwelllogo.png" alt="upwell" className="logoStyling" /> <span className="addTitle">Batch Details: Raw Biomass</span></div>
      <div className="maincontainerbiomass">

        <div className="biomassContainer">
          <div className="biomassDiv">
            {isDataLoad && (
              <div className="dot-loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            )
            }
            <div className="batchNoStyling">Batch Number: {batchNo} </div>
            {update && (<div className="select-batch-style"> Select the Supplier Batch To Get the Data </div>)}
            <div className="marginStyle">
              {update && (
                <div className="flexAuto">
                  <fieldset >
                    <legend className="scheduler-border">Select Supplier batch:</legend>
                    {supplierList?.length > 0 ? (
                      <select onChange={(e) => handleSuplierChange(e.target.value)} style={{ border: 'none', outline: 'none', width: '150px' }}>
                        <option value="">Select Supplier</option>
                        {supplierList?.map((item, index) => (
                          <option key={index}>
                            {item.supplierBatch}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select onChange={(e) => handleSuplierChange(e.target.value)} style={{ border: 'none', outline: 'none', width: '150px' }}>
                        {supplierIdError === '' && supplierList?.length <= 0 ? (
                          <option value="" selected disabled>Loading...</option>) : (
                          <option value="" selected disabled>No Suppliers</option>)}
                      </select>
                    )}

                  </fieldset>

                  {supplierIdError && <p className="error">{supplierIdError}</p>}
                </div>)
              }
            </div>
            {/* <span style={{color:'red'}}>*</span> */}
            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <div className="inputFlex">
                  <div className="flexAuto">
                    <fieldset  >
                      <legend className="scheduler-border">Supplier batch #:</legend>
                      <input type="text" id="SupplierBatch" value={bachPoIds.supplierBatch}
                        onChange={(e) => handleTextChange(e, 'supplierBatch')}
                        placeholder="" className="inputStyle" style={{ border: 'none' }} disabled={isAdmin} />
                    </fieldset>
                  </div>
                </div>


              </div>
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Supplier CoA #:</legend>
                  <div className="file-input-container">
                    <label htmlFor="file-input" className="custom-file-label">
                      {fileNames.supplier_coa_file_display_name ? fileNames.supplier_coa_file_display_name : 'Choose File'}
                    </label>
                    <input
                      type="file"
                      id="file-input" accept=".pdf"
                      onChange={(e) => handleFileChange(e, 'supplier_coa_file', 'supplier_coa_file_display_name')}
                      style={{ display: 'none' }} disabled={isAdmin}
                    />
                  </div>
                </fieldset>
              </div>
            </div>

            <div className="displayFlex marginStyle">
              {viewData ? (              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Supplier:</legend>
                  <input type="text" value={supplierId}
                    onChange={() =>handleSelectChange}
                    placeholder="" className="inputStyle" style={{ border: 'none' }} disabled={isAdmin} />
                </fieldset>
              </div>):(              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Supplier:</legend>
                  <select value={supplierId} onChange={handleSelectChange} style={{ border: 'none', outline: 'none' }} disabled={isAdmin}>
                    <option>Select Supplier</option>
                    {supplierOptionList?.map((option, index) => (
                      <option key={index} value={option.suppliers_list}>
                        {option.suppliers_list}
                      </option>
                    ))}
                    <option value="add" style={{ color: 'blue' }}>Add New Option</option>
                  </select>
                </fieldset>
                {supplierError && <p className="error">{supplierError}</p>}
              </div>)}

              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Supplier Pesticides:</legend>
                  <div className="file-input-container">
                    <label htmlFor="file-input2" className="custom-file-label">
                      {fileNames.supplier_pesticides_files_display_name ? fileNames.supplier_pesticides_files_display_name : 'Choose File'}
                    </label>
                    <input
                      type="file"
                      id="file-input2" accept=".pdf"
                      onChange={(e) => handleFileChange(e, 'supplier_pesticides_files', 'supplier_pesticides_files_display_name')}
                      style={{ display: 'none' }} disabled={isAdmin}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Associated PO #:</legend>
                  <input type="text" id="associatedPo" value={bachPoIds.associatedPo}
                    onChange={(e) => handleTextChange(e, 'associatedPo')}
                    placeholder="" className="inputStyle" style={{ border: 'none' }} disabled={isAdmin} />
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Upwell CoA #:</legend>
                  <div className="file-input-container">
                    <label htmlFor="file-input3" className="custom-file-label">
                      {fileNames.upwell_coa_files_display_name ? fileNames.upwell_coa_files_display_name : 'Choose File'}
                    </label>
                    <input
                      type="file"
                      id="file-input3" accept=".pdf"
                      onChange={(e) => handleFileChange(e, 'upwell_coa_files', 'upwell_coa_files_display_name')}
                      style={{ display: 'none' }} disabled={isAdmin}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="marginStyle">
              <fieldset  >
                <legend className="scheduler-border">Shipped:</legend>
                <input type="date" id="shipped" max={new Date().toISOString().split('T')[0]} value={shippingDates.shippedDate}
                  onChange={(e) => handleDateChange(e, 'shippedDate')} className="inputStyle" disabled={isAdmin} />
              </fieldset>
            </div>
            <div className="marginStyle">
              <fieldset  >
                <legend className="scheduler-border">Received: </legend>
                <input type="date" id="received" max={new Date().toISOString().split('T')[0]} value={shippingDates.receivedDate}
                  onChange={(e) => handleDateChange(e, 'receivedDate')} className="inputStyle" disabled={isAdmin} />
              </fieldset>
            </div>
            <div className="marginStyle">
              <fieldset  >
                <legend className="scheduler-border">Shipping manifest:</legend>
                <div className="file-input-container">
                  <label htmlFor="file-input4" className="custom-file-label">
                    {fileNames.shipping_manifest_files_display_name ? fileNames.shipping_manifest_files_display_name : 'Choose File'}
                  </label>
                  <input
                    type="file"
                    id="file-input4" accept=".pdf"
                    onChange={(e) => handleFileChange(e, 'shipping_manifest_files', 'shipping_manifest_files_display_name')}
                    style={{ display: 'none' }} disabled={isAdmin}
                  />
                </div>
              </fieldset>
            </div>
            <div className="marginStyle">
              <fieldset  >
                <legend className="scheduler-border">Proof of delivery:</legend>
                <div className="file-input-container">
                  <label htmlFor="file-input5" className="custom-file-label">
                    {fileNames.proof_of_delivery_files_display_name ? fileNames.proof_of_delivery_files_display_name : 'Choose File'}
                  </label>
                  <input
                    type="file"
                    id="file-input5" accept=".pdf"
                    onChange={(e) => handleFileChange(e, 'proof_of_delivery_files', 'proof_of_delivery_files_display_name')}
                    style={{ display: 'none' }} disabled={isAdmin}
                  />
                </div>
              </fieldset>
            </div>
            {error && <p className="error">{error}</p>}


          </div>
          <div className="paddingTop">

            {isAdmin ? (
              <div>
                <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome} className="homeButton" /></button>
                <button type="button" onClick={() => handleRedirect('/viewBatch')} className="loginbuttonStyle" disabled={loading}>BACK</button>
                <button type="button" onClick={() => handleRedirect('/supplier')} className="loginbuttonStyle">NEXT</button>
              </div>
            ) : (
              <div>
                <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome} className="homeButton" /></button>
                {/* <button type="button" onClick={() => handleRedirect('/add')} className="loginbuttonStyle" disabled={loading}>BACK</button> */}
                {update ? (
                  <button type="button" onClick={() => handleRedirect('/update')} className="loginbuttonStyle" disabled={loading}>BACK</button>
                ) : (<button type="button" onClick={() => handleRedirect('/add')} className="loginbuttonStyle" disabled={loading}>BACK</button>)}

                {update && <button type="submit" onClick={() => handleSubmit('/supplier')} className="loginbuttonStyle" disabled={loading}>
                  {loading ? 'Loading...' : 'Update'}
                </button>
                }
                {update && <button type="button" onClick={() => handleRedirect('/supplier')} className="loginbuttonStyle" disabled={loading}>NEXT</button>}

                {!update && <button type="submit" onClick={() => handleSubmit('/supplier')} className="loginbuttonStyle" disabled={loading}>
                  {loading ? 'Loading...' : 'NEXT'}
                </button>
                }
              </div>
            )}

          </div>
        </div>
      </div>
      <div id="popupid" className="popup">
        <div className="popup-content">
          <h2>Add New Supplier</h2>
          <div className="marginStyle">
            <fieldset  >
              <legend className="scheduler-border">New Supplier:</legend>
              <input type="text" id="newSupplier" value={newSupplier}
                onChange={(e) => handleNewSupplier(e, 'newSupplier')}
                placeholder="Enter Supplier" className="inputStyle" style={{ border: 'none' }} />
            </fieldset>
          </div>
          {addDropdownError && <p style={{ color: 'red' }}>{addDropdownError}</p>}
          <div className="popup-btns-flex">
            <button onClick={() => handleClosePopup()} className="popup-btns">Close</button >
            <button onClick={() => handleSaveSupplier()} disabled={loading} className="popup-btns">{loading ? 'Loading...' : 'Save'}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RawBiomass;