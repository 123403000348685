import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './login';
import AddNewBatch from './AddNewBatch';
import UpdateBatch from './UpdateBatch';
import CreateReport from './CreateReport';
import RawBiomass from './RawBiomass/RawBiomass';
import Extraction from './Extraction';
import Logistics from './Logistics';
import Products from './Products';
import SupplierCoa from './RawBiomass/SupplierCoa';
import UpwellButter from './RawBiomass/UpwellButter';
import UpwellHydero from './RawBiomass/UpwellHydro';
import UpwellOleo from './RawBiomass/UpwellOleo';
import UpwellWax from './RawBiomass/UpwellWax';
import PostBiomass from './RawBiomass/PostBiomass';
import Remaining from './RawBiomass/RemainingBiomass';
import LogisticsClient from './LogisticsClients';
import LogisticsExtractor from './LogisticsExtractor';
import UpwellCoa from './RawBiomass/UpwellCoa';
import AqueousExtraction from './AqueousExtraction';
import LoginPage from './LoginPage';
import Protected from './Protected';
import UserViewBatch from './UserViewBatch';
import BiomassReport from './UpwellReports/RawBiomassReport';
import ExtractionReport from './UpwellReports/ExtractionReport';
import LogisticsReport from './UpwellReports/LogisticsReport';
import ProductsReports from './UpwellReports/ProductsReports';
// import useUser from './useUser';
// import { useNavigate } from 'react-router-dom';

// function ProtectedRoute({ children }) {
//   // const { isLoggedIn } = useUser();
//   const userIsAuthenticated = false;
//   const navigate = useNavigate();

//   if (!userIsAuthenticated) {
//     // Redirect to the login page if not logged in
//     navigate('/Login');
//     return null;
//   }else{
//     navigate('/dashboard');
//     return null;
//   }

//   return children;
// }

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/dashboard" element={<Login />} />
      <Route path="/" element={<Protected Component={LoginPage} />} />
      <Route path="/add" element={<AddNewBatch/>} />
      <Route path="/update" element={<UpdateBatch />} />
      <Route path="/report" element={<CreateReport />} />
      <Route path="/rawbiomass" element={<RawBiomass />} />
      <Route path="/extraction" element={<Extraction />} />
      <Route path="/logistics" element={<Logistics />} />
      <Route path="/products" element={<Products />} />
      <Route path="/supplier" element={<SupplierCoa />} />
      <Route path="/butter" element={<UpwellButter />} />
      <Route path="/hydero" element={<UpwellHydero />} />
      <Route path="/oleo" element={<UpwellOleo />} />
      <Route path="/postBiomass" element={<PostBiomass />} />
      <Route path="/wax" element={<UpwellWax />} />
      <Route path="/remaining" element={<Remaining />} />
      <Route path="/client" element={<LogisticsClient />} />
      <Route path="/extractor" element={<LogisticsExtractor />} />
      <Route path="/upwellCoa" element={<UpwellCoa />} />
      <Route path="/aqueous" element={<AqueousExtraction />} />
      <Route path="/viewBatch" element={<UserViewBatch />} />
      <Route path="/biomassReport" element={<BiomassReport />} />
      <Route path="/extractionReport" element={<ExtractionReport />} />
      <Route path="/logisticsReport" element={<LogisticsReport />} />
      <Route path="/productsReports" element={<ProductsReports />} />
    </Routes>
  </BrowserRouter>

  );
}

export default App;
