import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../AddNewBatch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { getData, getIdRefreshToken } from '../../ApiServices/ApiService';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function ExtractionReport() {
    const location = useLocation();
    const batchNo = location.state?.name;
    const update = location.state?.update;
    const viewData = location.state?.view;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [sfeError, setSfeError] = useState('');
    const [aqueousError, setAqueousError] = useState('');
    const [isDataLoad, setIsDataLoad] = useState(false);
    const [isSFEExtractionDataLoaded, setIsSFEExtractionDataLoaded] = useState(false);
    const [isAqueousExtractionDataLoaded, setIsAqueousExtractionDataLoaded] = useState(false);

    const [sfeSupplierList, setSfeSupplierList] = useState([]);
    const [aqueousSupplierList, setAqueousSupplierList] = useState([]);
    const [sfeSupplierDataFetched, setSfeSupplierDataFetched] = useState(false);
    const [aqueousSupplierDataFetched, setAqueousSupplierDataFetched] = useState(false);
    const [sfeExtractionQldbList, setSfeExtractionQldbList] = useState([]);
    const [butterExtractionQldbList, setButterExtractionQldbList] = useState([]);
    const [waxExtractionQldbList, setWaxExtractionQldbList] = useState([]);
    const [oleoExtractionQldbList, setOleoExtractionQldbList] = useState([]);
    const [postSfeBiomassExtractionQldbList, setPostSfeBiomassExtractionQldbList] = useState([]);
    const [aqueousExtractionQldbList, setAqueousExtractionQldbList] = useState([]);
    const [hydroExtractionQldbList, setHydroExtractionQldbList] = useState([]);
    const [remainingBiomassExtractionQldbList, setRemainingBiomassExtractionQldbList] = useState([]);
    const [sfeExtractionQldbDataFetched, setSfeExtractionQldbDataFetched] = useState(false);
    const [sfeExtractionQldbError, setSfeExtractionQldbError] = useState('');
    const [aqueousExtractionQldbDataFetched, setAqueousExtractionQldbDataFetched] = useState(false);
    const [aqueousExtractionQldbError, setAqueousExtractionQldbError] = useState('');

    const contentRef = useRef(null);
    const qldbContentRef = useRef(null);
    const [presignedUrlError, setPresignedUrlError] = useState('');
    const [singlePdf, setSinglePdf] = useState(false);
    const [mergePdf, setMergePdf] = useState(false);
    const [qldbPdf, setQldbPdf] = useState(false);
    const [selectedSfeSupplier, setSelectedSfeSupplier] = useState('');
    const [selectedAqueousSupplier, setSelectedAqueousSupplier] = useState('');
    const isdownloadAll = selectedSfeSupplier !== '' || selectedAqueousSupplier !== '';
    const userData = JSON.parse(localStorage.getItem('userInfo'));
    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            if (!sfeSupplierDataFetched) {
                getSfeExtractionBatchList();
            }

            if (!aqueousSupplierDataFetched) {
                getAqueousExtractionBatchList();
            }
        } else {
            callLogout();
        }
    }, [sfeSupplierDataFetched, aqueousSupplierDataFetched, batchNo, viewData])

    const getSfeExtractionBatchList = () => {
        const getPayload = {
            "op": "GET_SUPPLIER_BATCH_SFE_EXT",
            "batchno": batchNo
        }
        setSfeError('');
        getData('test2', JSON.stringify(getPayload), userData.id_token).then(resp => {
            setSfeSupplierDataFetched(true);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ.length > 0) {
                                setSfeError('');
                                setSfeSupplierList(respData.dataJ);
                            } else {
                                setSfeError(respData.info);
                                setSfeSupplierList([]);
                            }
                        } else {
                            setSfeError(respData.info);
                            setSfeSupplierList([]);
                        }
                    } else {
                        setSfeError('Error While getting SFE Extraction Supplier List');
                        setSfeSupplierList([]);
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getSfeBatch', '');
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setSfeError('Error Occurred While Loading SFE Extraction Supplier List');
                    setSfeSupplierList([]);
                }
            }).catch((error) => {
                console.log('network error:', error);
                setSfeError('Network Error While getting SFE Extraction Supplier List');
                setSfeSupplierList([]);
            });
        }).catch((error) => {
            console.log('network error:', error);
            setSfeError('Network Error While getting SFE Extraction Supplier List!');
            setSfeSupplierList([]);
        });
    }

    const getAqueousExtractionBatchList = () => {
        const getPayload = {
            "op": "GET_SUPPLIER_BATCH_AQA_EXT",
            "batchno": batchNo
        }
        setAqueousError('');
        getData('test2', JSON.stringify(getPayload), userData.id_token).then(resp => {
            setAqueousSupplierDataFetched(true);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ.length > 0) {
                                setAqueousError('');
                                setAqueousSupplierList(respData.dataJ);
                            } else {
                                setAqueousError(respData.info);
                                setAqueousSupplierList([]);
                            }
                        } else {
                            setAqueousError(respData.info);
                            setAqueousSupplierList([]);
                        }
                    } else {
                        setAqueousError('Error While getting Aqueous Extraction Supplier List');
                        setAqueousSupplierList([]);
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getAqueousBatch', '');
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setAqueousError('Error Occurred While Loading Aqueous Extraction Supplier List');
                    setAqueousSupplierList([]);
                }
            }).catch((error) => {
                console.log('network error:', error);
                setAqueousError('Network Error While getting Aqueous Extraction Supplier List');
                setAqueousSupplierList([]);
            });
        }).catch((error) => {
            console.log('network error:', error);
            setAqueousError('Network Error While getting Aqueous Extraction Supplier List!');
            setAqueousSupplierList([]);
        });
    }

    const callRefreshTokenApi = (type, event) => {
        const payload = {
            "email": userData.email,
            "oldRefreshToken": userData.refresh_token
        }
        getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
            if (resp.statusCode === 200) {
                if (resp.message.toLowerCase() === 'success') {
                    userData.id_token = resp.dataJ.id_token;
                    localStorage.setItem('userInfo', JSON.stringify(userData));
                    if (type === 'getSfeBatch') {
                        getSfeExtractionBatchList();
                    } else if (type === 'getAqueousBatch') {
                        getAqueousExtractionBatchList();
                    } else if (type === 'getSfeAll') {
                        getSfeExtractionData(event);
                    } else if (type === 'getSfeQldb') {
                        getSfeExtractionQldbData(event);
                    } else if (type === 'getAqueousAll') {
                        getAqueousExtractionData(event);
                    } else if (type === 'getAqueousQldb') {
                        getAqueousExtractionQldbData(event);
                    } else if (type === 'getReportAll') {
                        getPresignedUrl();
                    } else {
                        console.log('type', type)
                    }
                } else {
                    callLogout();
                }
            } else {
                callLogout();
            }
        }).catch((error) => {
            console.log('refresh error', error);
            callLogout();
        });
    }

    const callLogout = () => {
        localStorage.removeItem('isAdminLogin');
        localStorage.removeItem('userInfo');
        localStorage.clear();
        navigate('/');
        window.location.reload();
    }

    const handleSuplierChange = (event, supplierValue) => {
        console.log('update', event);
        if (supplierValue === 'sfeExtraction') {
            if (!event) {
                setSelectedSfeSupplier('');
                setSfeExtractionQldbList([]);
                sfeExtractionDataEmpty(); return;
            }
            setSelectedSfeSupplier(event);
            getSfeExtractionData(event);
            getSfeExtractionQldbData(event);
        } else {
            if (!event) {
                setSelectedAqueousSupplier('');
                setAqueousExtractionQldbList([]);
                aqueousExtractionDataEmpty(); return;
            }
            setSelectedAqueousSupplier(event);
            getAqueousExtractionData(event);
            getAqueousExtractionQldbData(event);
        }
    }

    const getSfeExtractionData = async (event) => {
        const getPayload = {
            "op": "GET_ALL_EXTRACTION_DETAILS_SFE",
            "batchNo": batchNo,
            "supplierBatch": event
        }
        setSfeError('');
        setIsDataLoad(true);
        setIsSFEExtractionDataLoaded(false);
        await getData('test2', JSON.stringify(getPayload), userData.id_token).then(resp => {
            // setIsDataLoad(false);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ.length > 0) {
                                setIsSFEExtractionDataLoaded(true);
                                setSfeError('');
                                setSfeExtractionResponseInfo((prevSfeExtractionResponseInfo) => ({
                                    ...prevSfeExtractionResponseInfo,
                                    ...respData.dataJ[0].sfe_extraction,
                                }));

                                setButterExtractionResponseInfo((prevButterExtractionResponseInfo) => ({
                                    ...prevButterExtractionResponseInfo,
                                    ...respData.dataJ[0].butter,
                                }));

                                setOleoExtractionResponseInfo((prevOleoExtractionResponseInfo) => ({
                                    ...prevOleoExtractionResponseInfo,
                                    ...respData.dataJ[0].oleo,
                                }));

                                setWaxExtractionResponseInfo((prevWaxExtractionResponseInfo) => ({
                                    ...prevWaxExtractionResponseInfo,
                                    ...respData.dataJ[0].wax,
                                }));

                                setPostSfeBiomassExtractionResponseInfo((prevPostSfeBiomassExtractionResponseInfo) => ({
                                    ...prevPostSfeBiomassExtractionResponseInfo,
                                    ...respData.dataJ[0].post_sfe_biomass,
                                }));
                            } else {
                                setSfeError(respData.info);
                                sfeExtractionDataEmpty();
                            }
                        } else {
                            setSfeError(respData.info);
                            sfeExtractionDataEmpty();
                        }
                    } else {
                        setSfeError('Error While Getting SFE Extraction Data');
                        sfeExtractionDataEmpty();
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getSfeAll', event);
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setSfeError('Error Occurred While Getting SFE Extraction Data');
                    sfeExtractionDataEmpty();
                }
            }).catch((error) => {
                setIsDataLoad(false);
                console.log('network error:', error);
                setSfeError('Network Error While Getting SFE Extraction Data');
                sfeExtractionDataEmpty();
            });
        }).catch((error) => {
            setIsDataLoad(false);
            console.log('network error:', error);
            setSfeError('Network Error While Getting SFE Extraction Data!');
            sfeExtractionDataEmpty();
        });
    }

    const getSfeExtractionQldbData = async (event) => {
        const getPayload = {
            "op": "GET_ALL_EXTRACTION_DETAILS_QLDB_SFE",
            "batchNo": batchNo,
            "supplierBatch": event
        }
        setSfeExtractionQldbError('');
        setIsDataLoad(true);
        setSfeExtractionQldbDataFetched(false);
        await getData('test2', JSON.stringify(getPayload), userData.id_token).then(resp => {
            // setIsDataLoad(false);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ !== undefined) {
                                let sfeExtractionQldb = respData.dataJ.sfe_extraction;
                                let butterExtractionQldb = respData.dataJ.upwellCoa_butter;
                                let waxExtractionQldb = respData.dataJ.upwellCoa_wax;
                                let oleoExtractionQldb = respData.dataJ.upwellCoa_oleo;
                                let postSfeBiomassExtractionQldb = respData.dataJ.upwellCoa_post_sfe_biomass;
                                if (sfeExtractionQldb.length > 0 || butterExtractionQldb.length > 0 || waxExtractionQldb.length > 0
                                    || oleoExtractionQldb.length > 0 || postSfeBiomassExtractionQldb.length > 0) {
                                    setSfeExtractionQldbDataFetched(true);
                                    setSfeExtractionQldbError('');
                                    setSfeExtractionQldbList(sfeExtractionQldb);
                                    setButterExtractionQldbList(butterExtractionQldb);
                                    setWaxExtractionQldbList(waxExtractionQldb);
                                    setOleoExtractionQldbList(oleoExtractionQldb);
                                    setPostSfeBiomassExtractionQldbList(postSfeBiomassExtractionQldb);
                                } else {
                                    setSfeExtractionQldbError(respData.info);
                                    sfeQldbListEmpty();
                                }
                            } else {
                                setSfeExtractionQldbError(respData.info);
                                sfeQldbListEmpty();
                            }
                        } else {
                            setSfeExtractionQldbError(respData.info);
                            sfeQldbListEmpty();
                        }
                    } else {
                        setSfeExtractionQldbError('Error While Getting SFE Extraction QLDB Report');
                        sfeQldbListEmpty();
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getSfeQldb', event);
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setSfeExtractionQldbError('Error Occurred While Getting SFE Extraction QLDB Report');
                    sfeQldbListEmpty();
                }
            }).catch((error) => {
                setIsDataLoad(false);
                console.log('network error:', error);
                setSfeExtractionQldbError('Network Error While Getting SFE Extraction QLDB Report');
                sfeQldbListEmpty();
            });
        }).catch((error) => {
            setIsDataLoad(false);
            console.log('network error:', error);
            setSfeExtractionQldbError('Network Error While Getting SFE Extraction QLDB Report!');
            sfeQldbListEmpty();
        });
    }

    const sfeQldbListEmpty = () => {
        setSfeExtractionQldbList([]);
        setButterExtractionQldbList([]);
        setWaxExtractionQldbList([]);
        setOleoExtractionQldbList([]);
        setPostSfeBiomassExtractionQldbList([]);
    }

    const getAqueousExtractionData = async (event) => {
        const getPayload = {
            "op": "GET_ALL_EXTRACTION_DETAILS_AQE",
            "batchNo": batchNo,
            "supplierBatch": event
        }
        setAqueousError('');
        setIsDataLoad(true);
        setIsAqueousExtractionDataLoaded(false);
        await getData('test2', JSON.stringify(getPayload), userData.id_token).then(resp => {
            // setIsDataLoad(false);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ.length > 0) {
                                setIsSFEExtractionDataLoaded(true);
                                setIsAqueousExtractionDataLoaded(true);
                                setAqueousError('');
                                setAqueousExtractionResponseInfo((prevAqueousExtractionResponseInfo) => ({
                                    ...prevAqueousExtractionResponseInfo,
                                    ...respData.dataJ[0].aqueous_extraction,
                                }));

                                setHydroExtractionResponseInfo((prevHydroExtractionResponseInfo) => ({
                                    ...prevHydroExtractionResponseInfo,
                                    ...respData.dataJ[0].hydro,
                                }));

                                setRemainingBiomassExtractionResponseInfo((prevRemainingBiomassExtractionResponseInfo) => ({
                                    ...prevRemainingBiomassExtractionResponseInfo,
                                    ...respData.dataJ[0].remaining_biomass,
                                }));

                            } else {
                                setAqueousError(respData.info);
                                aqueousExtractionDataEmpty();
                            }
                        } else {
                            setAqueousError(respData.info);
                            aqueousExtractionDataEmpty();
                        }
                    } else {
                        setAqueousError('Error While Getting Aqueous Extraction Data');
                        aqueousExtractionDataEmpty();
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getAqueousAll', event);
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setAqueousError('Error Occurred While Getting Aqueous Extraction Data');
                    aqueousExtractionDataEmpty();
                }
            }).catch((error) => {
                setIsDataLoad(false);
                console.log('network error:', error);
                setAqueousError('Network Error While Getting Aqueous Extraction Data');
                aqueousExtractionDataEmpty();
            });
        }).catch((error) => {
            setIsDataLoad(false);
            console.log('network error:', error);
            setAqueousError('Network Error While Getting Aqueous Extraction Data!');
            aqueousExtractionDataEmpty();
        });
    }

    const getAqueousExtractionQldbData = async (event) => {
        const getPayload = {
            "op": "GET_ALL_EXTRACTION_DETAILS_QLDB_AQE",
            "batchNo": batchNo,
            "supplierBatch": event
        }
        setAqueousExtractionQldbError('');
        setIsDataLoad(true);
        setAqueousExtractionQldbDataFetched(false);
        await getData('test2', JSON.stringify(getPayload), userData.id_token).then(resp => {
            // setIsDataLoad(false);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ !== undefined) {
                                let aqueousExtractionQldb = respData.dataJ.aqueous_extraction;
                                let hydroExtractionQldb = respData.dataJ.upwellCoa_hydro;
                                let remainingBiomassExtractionQldb = respData.dataJ.upwellCoa_remaining_biomass;
                                if (aqueousExtractionQldb.length > 0 || hydroExtractionQldb.length > 0 || remainingBiomassExtractionQldb.length > 0) {
                                    setAqueousExtractionQldbDataFetched(true);
                                    setAqueousExtractionQldbError('');
                                    setAqueousExtractionQldbList(aqueousExtractionQldb);
                                    setHydroExtractionQldbList(hydroExtractionQldb);
                                    setRemainingBiomassExtractionQldbList(remainingBiomassExtractionQldb);
                                } else {
                                    setAqueousExtractionQldbError(respData.info);
                                    aqueousQldbListEmpty();
                                }
                            } else {
                                setAqueousExtractionQldbError(respData.info);
                                aqueousQldbListEmpty();
                            }
                        } else {
                            setAqueousExtractionQldbError(respData.info);
                            aqueousQldbListEmpty();
                        }
                    } else {
                        setAqueousExtractionQldbError('Error While Getting Aqueous Extraction QLDB Report');
                        aqueousQldbListEmpty();
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getAqueousQldb', event);
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setAqueousExtractionQldbError('Error Occurred While Getting Aqueous Extraction QLDB Report');
                    aqueousQldbListEmpty();
                }
            }).catch((error) => {
                setIsDataLoad(false);
                console.log('network error:', error);
                setAqueousExtractionQldbError('Network Error While Getting Aqueous Extraction QLDB Report');
                aqueousQldbListEmpty();
            });
        }).catch((error) => {
            setIsDataLoad(false);
            console.log('network error:', error);
            setAqueousExtractionQldbError('Network Error While Getting Aqueous Extraction QLDB Report!');
            aqueousQldbListEmpty();
        });
    }

    const aqueousQldbListEmpty = () => {
        setAqueousExtractionQldbList([]);
        setHydroExtractionQldbList([]);
        setRemainingBiomassExtractionQldbList([]);
    }

    const sfeExtractionDataEmpty = () => {
        setSfeExtractionResponseInfo({
            tolling_facility: '',
            supplierBatch: '',
            associatedPo: '',
            shippedDate: '',
            receivedDate: '',
            post_sfe_biomass: '',
            upwell_oil: '',
            upwell_wax: '',
            upwell_butter: '',
            material_extracted: '',
            upwell_coa_files_1: '',
            upwell_coa_files_2: '',
            upwell_coa_files_3: '',
            upwell_coa_files_4: '',
            sfe_report_file: '',
            sfe_report_file_display_name: '',
            upwell_coa_files_1_display_name: '',
            upwell_coa_files_2_display_name: '',
            upwell_coa_files_3_display_name: '',
            upwell_coa_files_4_display_name: '',
        })
        setButterExtractionResponseInfo({
            aerobic_plant_count: '',
            e_coil: '',
            total_coliforms: '',
            staphylococcus_aureus: '',
            mold: '',
            yeast: '',
            Salmonella_spp: '',
            Salmonella_spp_key: '',
            moisture: '',
            oil: '',
            alkenone: '',
            fucoxanthin: '',
            arsenic: '',
            cardium: '',
            lead: '',
            mercury: '',
            total_saturated: '',
            total_monousaturated: '',
            total_n_u_3pufa: '',
            total_n_u_6pufa: '',
            total_n_u_9pufa: '',
            total_pufa: '',
            acid_value_mg_KOH_g: '',
            melting_point_c: '',
            peroxide_value_meq_kg: '',
            iodine: '',
            saponification_value_mg_koh_g: '',
        })
        setOleoExtractionResponseInfo({
            aerobic_plant_count: '',
            e_coil: '',
            total_coliforms: '',
            staphylococcus_aureus: '',
            mold: '',
            yeast: '',
            Salmonella_spp: '',
            Salmonella_spp_key: '',
            moisture: '',
            oil: '',
            fucoxanthin: '',
            arsenic: '',
            cardium: '',
            lead: '',
            mercury: '',
            total_saturated: '',
            total_monousaturated: '',
            total_n_u_3pufa: '',
            total_n_u_6pufa: '',
            total_n_u_9pufa: '',
            total_pufa: '',
        })
        setWaxExtractionResponseInfo({
            aerobic_plant_count: '',
            e_coil: '',
            total_coliforms: '',
            staphylococcus_aureus: '',
            mold: '',
            yeast: '',
            Salmonella_spp: '',
            Salmonella_spp_key: '',
            moisture: '',
            alkenone: '',
            acid_value_mg_KOH_g: '',
            melting_point_c: '',
            peroxide_value_meq_kg: '',
            iodine: '',
            saponification_value_mg_koh_g: '',
        })
        setPostSfeBiomassExtractionResponseInfo({
            aerobic_plant_count: '',
            e_coil: '',
            total_coliforms: '',
            staphylococcus_aureus: '',
            mold: '',
            yeast: '',
            Salmonella_spp: '',
            Salmonella_spp_key: '',
            arsenic: '',
            cardium: '',
            lead: '',
            mercury: '',
            moisture: '',
            ash: '',
            protein: '',
            carbohydrate: '',
            oil: '',
        })
    }

    const aqueousExtractionDataEmpty = () => {
        setAqueousExtractionResponseInfo({
            tolling_facility: '',
            supplierBatch: '',
            associatedPo: '',
            shippedDate: '',
            receivedDate: '',
            upwell_hydro: '',
            remaining_biomass: '',
            material_extracted: '',
            upwell_coa_files_1_aq: '',
            upwell_coa_files_2_aq: '',
            extraction_report_file: '',
            extraction_report_file_display_name: '',
            upwell_coa_files_1_aq_display_name: '',
            upwell_coa_files_2_aq_display_name: ''
        })
        setHydroExtractionResponseInfo({
            aerobic_plant_count: '',
            e_coil: '',
            total_coliforms: '',
            staphylococcus_aureus: '',
            mold: '',
            yeast: '',
            Salmonella_spp: '',
            Salmonella_spp_key: '',
            arsenic: '',
            cardium: '',
            lead: '',
            mercury: '',
            moisture: '',
            ash: '',
            protein: '',
            carbohydrate: '',
        })
        setRemainingBiomassExtractionResponseInfo({
            aerobic_plant_count: '',
            e_coil: '',
            total_coliforms: '',
            staphylococcus_aureus: '',
            mold: '',
            yeast: '',
            Salmonella_spp: '',
            Salmonella_spp_key: '',
            arsenic: '',
            cardium: '',
            lead: '',
            mercury: '',
            moisture: '',
            ash: '',
            protein: '',
            carbohydrate: '',
            oil: ''
        })
    }

    const [sfeExtractionResponseInfo, setSfeExtractionResponseInfo] = useState({
        tolling_facility: '',
        supplierBatch: '',
        associatedPo: '',
        shippedDate: '',
        receivedDate: '',
        post_sfe_biomass: '',
        upwell_oil: '',
        upwell_wax: '',
        upwell_butter: '',
        material_extracted: '',
        upwell_coa_files_1: '',
        upwell_coa_files_2: '',
        upwell_coa_files_3: '',
        upwell_coa_files_4: '',
        sfe_report_file: '',
        sfe_report_file_display_name: '',
        upwell_coa_files_1_display_name: '',
        upwell_coa_files_2_display_name: '',
        upwell_coa_files_3_display_name: '',
        upwell_coa_files_4_display_name: '',
    })

    const [butterExtractionResponseInfo, setButterExtractionResponseInfo] = useState({
        aerobic_plant_count: '',
        e_coil: '',
        total_coliforms: '',
        staphylococcus_aureus: '',
        mold: '',
        yeast: '',
        Salmonella_spp: '',
        Salmonella_spp_key: '',
        moisture: '',
        oil: '',
        alkenone: '',
        fucoxanthin: '',
        arsenic: '',
        cardium: '',
        lead: '',
        mercury: '',
        total_saturated: '',
        total_monousaturated: '',
        total_n_u_3pufa: '',
        total_n_u_6pufa: '',
        total_n_u_9pufa: '',
        total_pufa: '',
        acid_value_mg_KOH_g: '',
        melting_point_c: '',
        peroxide_value_meq_kg: '',
        iodine: '',
        saponification_value_mg_koh_g: '',
    })

    const [oleoExtractionResponseInfo, setOleoExtractionResponseInfo] = useState({
        aerobic_plant_count: '',
        e_coil: '',
        total_coliforms: '',
        staphylococcus_aureus: '',
        mold: '',
        yeast: '',
        Salmonella_spp: '',
        Salmonella_spp_key: '',
        moisture: '',
        oil: '',
        fucoxanthin: '',
        arsenic: '',
        cardium: '',
        lead: '',
        mercury: '',
        total_saturated: '',
        total_monousaturated: '',
        total_n_u_3pufa: '',
        total_n_u_6pufa: '',
        total_n_u_9pufa: '',
        total_pufa: '',
    })

    const [waxExtractionResponseInfo, setWaxExtractionResponseInfo] = useState({
        aerobic_plant_count: '',
        e_coil: '',
        total_coliforms: '',
        staphylococcus_aureus: '',
        mold: '',
        yeast: '',
        Salmonella_spp: '',
        Salmonella_spp_key: '',
        moisture: '',
        alkenone: '',
        acid_value_mg_KOH_g: '',
        melting_point_c: '',
        peroxide_value_meq_kg: '',
        iodine: '',
        saponification_value_mg_koh_g: '',
    })

    const [postSfeBiomassExtractionResponseInfo, setPostSfeBiomassExtractionResponseInfo] = useState({
        aerobic_plant_count: '',
        e_coil: '',
        total_coliforms: '',
        staphylococcus_aureus: '',
        mold: '',
        yeast: '',
        Salmonella_spp: '',
        Salmonella_spp_key: '',
        arsenic: '',
        cardium: '',
        lead: '',
        mercury: '',
        moisture: '',
        ash: '',
        protein: '',
        carbohydrate: '',
        oil: '',
    })

    const [aqueousExtractionResponseInfo, setAqueousExtractionResponseInfo] = useState({
        tolling_facility: '',
        supplierBatch: '',
        associatedPo: '',
        shippedDate: '',
        receivedDate: '',
        upwell_hydro: '',
        remaining_biomass: '',
        material_extracted: '',
        upwell_coa_files_1_aq: '',
        upwell_coa_files_2_aq: '',
        extraction_report_file: '',
        extraction_report_file_display_name: '',
        upwell_coa_files_1_aq_display_name: '',
        upwell_coa_files_2_aq_display_name: ''
    })

    const [hydroExtractionResponseInfo, setHydroExtractionResponseInfo] = useState({
        aerobic_plant_count: '',
        e_coil: '',
        total_coliforms: '',
        staphylococcus_aureus: '',
        mold: '',
        yeast: '',
        Salmonella_spp: '',
        Salmonella_spp_key: '',
        arsenic: '',
        cardium: '',
        lead: '',
        mercury: '',
        moisture: '',
        ash: '',
        protein: '',
        carbohydrate: '',
    })

    const [remainingBiomassExtractionResponseInfo, setRemainingBiomassExtractionResponseInfo] = useState({
        aerobic_plant_count: '',
        e_coil: '',
        total_coliforms: '',
        staphylococcus_aureus: '',
        mold: '',
        yeast: '',
        Salmonella_spp: '',
        Salmonella_spp_key: '',
        arsenic: '',
        cardium: '',
        lead: '',
        mercury: '',
        moisture: '',
        ash: '',
        protein: '',
        carbohydrate: '',
        oil: ''
    })

    const handleRedirect = (path) => {
        navigate(path, { state: { name: batchNo, update: update, view: viewData } });
    }

    const downloadPdf = () => {
        setSinglePdf(true);
        const content = contentRef.current;
        if (!content) {
            return;
        }

        // Use html2canvas to capture the content as an image
        html2canvas(content).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 210;
            const pageHeight = 295;

            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const pdf = new jsPDF('p', 'mm');
            let position = 0;

            // Add the captured image to the PDF
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            // Download the PDF
            pdf.save('Extraction Report.pdf');
            setSinglePdf(false);
        });
    };

    const downloadUploadedPdf = (fileName, fileBase64) => {
        console.log('downloadUploadedPdf fileName , ', fileName);
        const byteArray = new Uint8Array(window.atob(fileBase64).split('').map((char) => char.charCodeAt(0)));

        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    }

    const downloadQldbPdf = () => {
        setQldbPdf(true);
        const content = qldbContentRef.current;

        if (!content) {
            return;
        }

        // Use html2canvas to capture the content as an image
        html2canvas(content).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 210;
            const pageHeight = 295;

            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const pdf = new jsPDF('p', 'mm');
            let position = 0;

            // Add the captured image to the PDF
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            // Download the PDF
            pdf.save('Extraction QLDB Reports.pdf');
            setQldbPdf(false);
        });

    }

    const isChanged = (current, previous, key) => {
        if (current[key] !== previous[key]) {
            return true;
        }
        return false;
    }

    const gmtToLocalTime = (gmtTime) => {
        const gmtDate = new Date(gmtTime);
        const localTime = gmtDate.toLocaleString();
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        const localTimeString = localTime.toLocaleString(undefined, options);
        return localTimeString.replace(/\//g, '-').replace(/\,/g, '');
    }

    const getPresignedUrl = () => {
        console.log('getPresignedUrl : ');
        if (selectedSfeSupplier !== '' && selectedAqueousSupplier === '') {
            console.log('if  selectedSfeSupplier : ', selectedSfeSupplier, 'selectedAqueousSupplier: ', selectedAqueousSupplier);
            getAllReportPresignedUrl('type1', batchNo, selectedSfeSupplier, '', '');
        } else if (selectedSfeSupplier === '' && selectedAqueousSupplier !== '') {
            console.log('else if 1 selectedSfeSupplier : ', selectedSfeSupplier, 'selectedAqueousSupplier: ', selectedAqueousSupplier);
            getAllReportPresignedUrl('type2', '', '', batchNo, selectedAqueousSupplier);
        } else if (selectedSfeSupplier !== '' && selectedAqueousSupplier !== '') {
            console.log('else if 2 selectedSfeSupplier : ', selectedSfeSupplier, 'selectedAqueousSupplier: ', selectedAqueousSupplier);
            getAllReportPresignedUrl('type3', batchNo, selectedSfeSupplier, batchNo, selectedAqueousSupplier);
        } else {
            console.log('else : selectedSfeSupplier : ', selectedSfeSupplier, 'selectedAqueousSupplier: ', selectedAqueousSupplier);
        }
    }

    const getAllReportPresignedUrl = (type, batchNo_1, supplierBatch_1, batchNo_2, supplierBatch_2) => {
        setMergePdf(true);
        const getPayload = {
            op: "GET_ALL_EXTRACTION_DETAILS_BACKED_REPORT",
            type: type,
            ext_1_batchno: batchNo_1,
            ext_1_supplierBatch: supplierBatch_1,
            ext_2_batchno: batchNo_2,
            ext_2_supplierBatch: supplierBatch_2
        }
        setPresignedUrlError('');
        setIsDataLoad(true);
        getData('reports', JSON.stringify(getPayload), userData.id_token).then(resp => {
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === "success") {
                            setPresignedUrlError('');
                            downloadAllReport(respData.dataJ.presignedurl);
                        } else {
                            setMergePdf(false);
                            setPresignedUrlError(respData.info);
                        }
                    } else {
                        setMergePdf(false);
                        setPresignedUrlError('Error While Getting Extraction All Report Data');
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getReportAll', '');
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setPresignedUrlError('Error Occurred While Getting Extraction All Report Data');
                    setMergePdf(false);
                }
            })
        }).catch((error) => {
            setIsDataLoad(false);
            setMergePdf(false);
            setPresignedUrlError('Network Error While Getting Extraction All Report Data');
            console.log('network error:', error);
        });
    }

    const downloadAllReport = (presignedUrl) => {
        const link = document.createElement('a');
        link.href = presignedUrl;
        link.download = 'Extraction Report.pdf';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        setMergePdf(false);
    }

    return (
        <div>
            <div className="reporttitle" style={{ display: 'flex' }}><img src="./upwelllogo.png" alt="upwell" className="logoStyling" />
                <span className="addTitle">Extraction Report</span>
                <button onClick={() => handleRedirect('/dashboard')} className="home-btn" style={{ paddingRight: '50px' }}> <FontAwesomeIcon icon={faHome} className="homeButton" /></button>
            </div>
            <div className="reportContainer">
                {isDataLoad && (
                    <div className="dot-loader">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                )}
                <div className="batchNoStyling">Batch Number: {batchNo} </div>
                <div className="displayFlex">
                    <div className="flexAuto">
                        <div className="subtitle">SFE Extraction</div>
                        {/* <div className="select-batch-style"> Select the Supplier Batch To Get the Data </div> */}
                        <div className="flexAuto" style={{ paddingTop: '6px' }}>
                            <fieldset  >
                                <legend className="scheduler-border">Select Supplier batch:</legend>
                                {sfeSupplierList?.length >= 0 ? (
                                    <select onChange={(e) => handleSuplierChange(e.target.value, 'sfeExtraction')} style={{ border: 'none', outline: 'none', width: '150px' }}>
                                        <option value="">Select Supplier</option>
                                        {sfeSupplierList?.map((item, index) => (
                                            <option key={index}>
                                                {item.supplierBatch}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <select onChange={(e) => handleSuplierChange(e.target.value, 'sfeExtraction')} style={{ border: 'none', outline: 'none', width: '150px' }}>
                                        {sfeError === '' && sfeSupplierList?.length <= 0 ? (
                                            <option value="" selected disabled>Loading...</option>) : (
                                            <option value="" selected disabled>No Suppliers</option>)}
                                    </select>
                                )}
                            </fieldset>
                        </div>
                        {sfeError && <p style={{ color: 'red' }}>{sfeError}</p>}
                    </div>

                    <div className="flexAuto">
                        <div className="subtitle">Aqueous Extraction</div>
                        {/* <div className="select-batch-style"> Select the Supplier Batch To Get the Data </div> */}
                        <div className="flexAuto" style={{ paddingTop: '6px' }}>
                            <fieldset  >
                                <legend className="scheduler-border">Select Supplier batch:</legend>
                                {aqueousSupplierList?.length >= 0 ? (
                                    <select onChange={(e) => handleSuplierChange(e.target.value, 'aqueousExtraction')} style={{ border: 'none', outline: 'none', width: '150px' }}>
                                        <option value="">Select Supplier</option>
                                        {aqueousSupplierList?.map((item, index) => (
                                            <option key={index}>
                                                {item.supplierBatch}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <select onChange={(e) => handleSuplierChange(e.target.value, 'aqueousExtraction')} style={{ border: 'none', outline: 'none', width: '150px' }}>
                                        {aqueousError === '' && aqueousSupplierList?.length <= 0 ? (
                                            <option value="" selected disabled>Loading...</option>) : (
                                            <option value="" selected disabled>No Suppliers</option>)}
                                    </select>
                                )}
                            </fieldset>
                        </div>
                        {aqueousError && <p style={{ color: 'red' }}>{aqueousError}</p>}
                    </div>

                </div>

                <div className="download-div">
                    {isSFEExtractionDataLoaded && <span><button onClick={downloadPdf} className="dwnld-btn">{singlePdf ? 'Generating...' : 'Download PDF'}</button></span>}
                    {sfeExtractionQldbDataFetched && <span><button onClick={downloadQldbPdf} className="dwnld-btn" style={{ maxWidth: '180px' }}>{qldbPdf ? 'Generating...' : 'Download QLDB PDF'}</button></span>}
                    {isdownloadAll && (<span><button onClick={getPresignedUrl} className="dwnld-btn">{mergePdf ? 'Generating...' : 'Download All'}</button></span>)}
                    <div>{presignedUrlError && <p className="error">{presignedUrlError}</p>}</div>
                </div>

                {isSFEExtractionDataLoaded && (<div>
                    <div ref={contentRef} className="pdfPage-styling">
                        <div >
                            <img src="./upwelllogo.png" alt="upwell" className="logoStyling" style={{ padding: '0px' }} />
                        </div>
                        <div className="reportheading" >Extraction</div>
                        <div className="displayFlex">
                            <div className="batchNoStyling  display-batch">Batch Number: {batchNo} </div>
                        </div>
                        <div className="tabletitle" style={{ marginRight: '7px' }}>SFE Extraction</div>
                        <div style={{ paddingBottom: '20px' }}>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Associated PO : {sfeExtractionResponseInfo.associatedPo}</div>
                                <div className="batchinfo flexAuto">Tolling Facility : {sfeExtractionResponseInfo.tolling_facility}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Supplier Batch : {sfeExtractionResponseInfo.supplierBatch}</div>
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Material Extracted : {sfeExtractionResponseInfo.material_extracted} kg</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto">Start Date : {sfeExtractionResponseInfo.shippedDate}</div>
                                <div className="batchinfo flexAuto">End Date : {sfeExtractionResponseInfo.receivedDate}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto">Upwell Butter : {sfeExtractionResponseInfo.upwell_butter} kg</div>
                                <div className="batchinfo flexAuto">Upwell Wax : {sfeExtractionResponseInfo.upwell_wax} kg</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto">Upwell Oil : {sfeExtractionResponseInfo.upwell_oil} kg</div>
                                <div className="batchinfo flexAuto">Post SFE Biomass : {sfeExtractionResponseInfo.post_sfe_biomass} kg</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo">Upwell CoA Butter File : {sfeExtractionResponseInfo.upwell_coa_files_1_display_name}</div>
                                {sfeExtractionResponseInfo.upwell_coa_files_1 && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(sfeExtractionResponseInfo.upwell_coa_files_1_display_name, sfeExtractionResponseInfo.upwell_coa_files_1)} />}
                            </div>

                            <div className="displayFlex">
                                <div className="batchinfo">Upwell CoA Wax File : {sfeExtractionResponseInfo.upwell_coa_files_2_display_name}</div>
                                {sfeExtractionResponseInfo.upwell_coa_files_2 && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(sfeExtractionResponseInfo.upwell_coa_files_2_display_name, sfeExtractionResponseInfo.upwell_coa_files_2)} />}
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo">Upwell CoA Oil File : {sfeExtractionResponseInfo.upwell_coa_files_3_display_name}</div>
                                {sfeExtractionResponseInfo.upwell_coa_files_3 && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(sfeExtractionResponseInfo.upwell_coa_files_3_display_name, sfeExtractionResponseInfo.upwell_coa_files_3)} />}
                            </div>

                            <div className="displayFlex">
                                <div className="batchinfo">Upwell CoA Post SFE Biomass File : {sfeExtractionResponseInfo.upwell_coa_files_4_display_name}</div>
                                {sfeExtractionResponseInfo.upwell_coa_files_4 && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(sfeExtractionResponseInfo.upwell_coa_files_4_display_name, sfeExtractionResponseInfo.upwell_coa_files_4)} />}
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo">SFE Report File : {sfeExtractionResponseInfo.sfe_report_file_display_name}</div>
                                {sfeExtractionResponseInfo.sfe_report_file && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(sfeExtractionResponseInfo.sfe_report_file_display_name, sfeExtractionResponseInfo.sfe_report_file)} />}
                            </div>
                        </div>
                        <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Upwell Butter</div>
                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(cfu/ml)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Aerobic Plate Count</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{butterExtractionResponseInfo.aerobic_plant_count}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>E. Coli</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.e_coil}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total coliforms</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.total_coliforms}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Staphylococcus aureus</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.staphylococcus_aureus}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Mold</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.mold}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Yeast</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.yeast}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Salmonella spp</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.Salmonella_spp_key}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Moisture</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{butterExtractionResponseInfo.moisture}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Oil</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.oil}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Alkenone</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.alkenone}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Fucoxanthin</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.fucoxanthin}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(mg/kg)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Arsenic</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{butterExtractionResponseInfo.arsenic}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Cadmium</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.cardium}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Lead</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.lead}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Mercury</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.mercury}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Fatty Acids Analysis</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total saturated</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{butterExtractionResponseInfo.total_saturated}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total monounsaturated</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.total_monousaturated}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total n-3 PUFA</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.total_n_u_3pufa}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total n-6 PUFA</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.total_n_u_6pufa}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total n-9 PUFA</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.total_n_u_9pufa}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total PUFA</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.total_pufa}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Specific Analysis</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Acid Value, mg KOH/g</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{butterExtractionResponseInfo.acid_value_mg_KOH_g}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Melting Point, ºC</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.melting_point_c}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Peroxide Value, meq/kg</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.peroxide_value_meq_kg}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Iodine</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.iodine}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Saponification Value, mg KOH/g</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{butterExtractionResponseInfo.saponification_value_mg_koh_g}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Upwell Oleo</div>
                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(cfu/ml)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Aerobic Plate Count</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{oleoExtractionResponseInfo.aerobic_plant_count}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>E. Coli</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.e_coil}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total coliforms</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.total_coliforms}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Staphylococcus aureus</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.staphylococcus_aureus}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Mold</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.mold}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Yeast</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.yeast}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Salmonella spp</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.Salmonella_spp_key}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Moisture</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{oleoExtractionResponseInfo.moisture}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Oil</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.oil}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Fucoxanthin</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.fucoxanthin}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(mg/kg)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Arsenic</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{oleoExtractionResponseInfo.arsenic}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Cadmium</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.cardium}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Lead</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.lead}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Mercury</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.mercury}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Fatty Acids Analysis</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total saturated</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{oleoExtractionResponseInfo.total_saturated}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total monounsaturated</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.total_monousaturated}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total n-3 PUFA</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.total_n_u_3pufa}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total n-6 PUFA</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.total_n_u_6pufa}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total n-9 PUFA</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.total_n_u_9pufa}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total PUFA</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{oleoExtractionResponseInfo.total_pufa}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Upwell Wax</div>
                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(cfu/ml)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Aerobic Plate Count</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{waxExtractionResponseInfo.aerobic_plant_count}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>E. Coli</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{waxExtractionResponseInfo.e_coil}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total coliforms</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{waxExtractionResponseInfo.total_coliforms}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Staphylococcus aureus</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{waxExtractionResponseInfo.staphylococcus_aureus}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Mold</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{waxExtractionResponseInfo.mold}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Yeast</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{waxExtractionResponseInfo.yeast}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Salmonella spp</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{waxExtractionResponseInfo.Salmonella_spp_key}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Moisture</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{waxExtractionResponseInfo.moisture}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Alkenone</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{waxExtractionResponseInfo.alkenone}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Specific Analysis</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Acid Value, mg KOH/g</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{waxExtractionResponseInfo.acid_value_mg_KOH_g}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Melting Point, ºC</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{waxExtractionResponseInfo.melting_point_c}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Peroxide Value, meq/kg</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{waxExtractionResponseInfo.peroxide_value_meq_kg}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Iodine</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{waxExtractionResponseInfo.iodine}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Saponification Value, mg KOH/g</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{waxExtractionResponseInfo.saponification_value_mg_koh_g}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Post SFE Biomass</div>
                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(cfu/ml)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Aerobic Plate Count</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{postSfeBiomassExtractionResponseInfo.aerobic_plant_count}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>E. Coli</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.e_coil}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total coliforms</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.total_coliforms}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Staphylococcus aureus</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.staphylococcus_aureus}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Mold</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.mold}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Yeast</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.yeast}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Salmonella spp</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.Salmonella_spp_key}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(mg/kg)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Arsenic</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{postSfeBiomassExtractionResponseInfo.arsenic}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Cadmium</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.cardium}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Lead</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.lead}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Mercury</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.mercury}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Moisture</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{postSfeBiomassExtractionResponseInfo.moisture}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Ash</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.ash}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Protein</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.protein}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Carbohydrate</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.carbohydrate}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Oil</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{postSfeBiomassExtractionResponseInfo.oil}</td>
                                </tr>
                            </tbody>
                        </table>
                        {/* //aqueous extraction */}
                        <div className="tabletitle" style={{ marginRight: '7px' }}>Aqueous Extraction</div>
                        <div style={{ paddingBottom: '20px' }}>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Associated PO : {aqueousExtractionResponseInfo.associatedPo}</div>
                                <div className="batchinfo flexAuto">Tolling Facility : {aqueousExtractionResponseInfo.tolling_facility}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Supplier Batch : {aqueousExtractionResponseInfo.supplierBatch}</div>
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Material Extracted : {aqueousExtractionResponseInfo.material_extracted} kg</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto">Start Date : {aqueousExtractionResponseInfo.shippedDate}</div>
                                <div className="batchinfo flexAuto">End Date : {aqueousExtractionResponseInfo.receivedDate}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto">Upwell Hydro : {aqueousExtractionResponseInfo.upwell_hydro} kg</div>
                                <div className="batchinfo flexAuto">Remaining Biomass : {aqueousExtractionResponseInfo.remaining_biomass} kg</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo">Upwell CoA Hydro File : {aqueousExtractionResponseInfo.upwell_coa_files_1_aq_display_name}</div>
                                {aqueousExtractionResponseInfo.upwell_coa_files_1_aq && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(aqueousExtractionResponseInfo.upwell_coa_files_1_aq_display_name, aqueousExtractionResponseInfo.upwell_coa_files_1_aq)} />}
                            </div>

                            <div className="displayFlex">
                                <div className="batchinfo">Upwell CoA Remaining Biomass File : {aqueousExtractionResponseInfo.upwell_coa_files_2_aq_display_name}</div>
                                {aqueousExtractionResponseInfo.upwell_coa_files_2_aq && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(aqueousExtractionResponseInfo.upwell_coa_files_2_aq_display_name, aqueousExtractionResponseInfo.upwell_coa_files_2_aq)} />}
                            </div>

                            <div className="displayFlex">
                                <div className="batchinfo">Extraction Report File : {aqueousExtractionResponseInfo.extraction_report_file_display_name}</div>
                                {aqueousExtractionResponseInfo.extraction_report_file && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(aqueousExtractionResponseInfo.extraction_report_file_display_name, aqueousExtractionResponseInfo.extraction_report_file)} />}
                            </div>
                        </div>

                        <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Upwell Hydro</div>
                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(cfu/ml)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Aerobic Plate Count</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{hydroExtractionResponseInfo.aerobic_plant_count}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>E. Coli</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.e_coil}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total coliforms</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.total_coliforms}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Staphylococcus aureus</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.staphylococcus_aureus}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Mold</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.mold}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Yeast</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.yeast}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Salmonella spp</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.Salmonella_spp_key}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(mg/kg)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Arsenic</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{hydroExtractionResponseInfo.arsenic}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Cadmium</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.cardium}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Lead</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.lead}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Mercury</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.mercury}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Moisture</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{hydroExtractionResponseInfo.moisture}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Ash</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.ash}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Protein</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.protein}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Carbohydrate</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{hydroExtractionResponseInfo.carbohydrate}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Upwell Remaining Biomass</div>
                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(cfu/ml)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Aerobic Plate Count</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{remainingBiomassExtractionResponseInfo.aerobic_plant_count}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>E. Coli</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.e_coil}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Total coliforms</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.total_coliforms}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Staphylococcus aureus</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.staphylococcus_aureus}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Mold</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.mold}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Yeast</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.yeast}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Salmonella spp</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.Salmonella_spp_key}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(mg/kg)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Arsenic</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{remainingBiomassExtractionResponseInfo.arsenic}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Cadmium</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.cardium}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Lead</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.lead}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Mercury</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.mercury}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                        <table className="table" style={{ marginBottom: '20px' }}>
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th style={{ padding: '0px 10px' }}>Analyte</th>
                                    <th style={{ padding: '0px 10px' }}>Value(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Moisture</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{remainingBiomassExtractionResponseInfo.moisture}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Ash</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.ash}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Protein</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.protein}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Carbohydrate</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.carbohydrate}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Oil</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{remainingBiomassExtractionResponseInfo.oil}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>)}

                {sfeExtractionQldbDataFetched && (<div>
                    <div ref={qldbContentRef} className="pdfPage-styling">
                        <div >
                            <img src="./upwelllogo.png" alt="upwell" className="logoStyling" style={{ padding: '0px' }} />
                        </div>
                        <div className="reportheading" >Extraction QLDB Reports</div>
                        <div className="batchNoStyling  display-batch displayFlex">Batch Number: {batchNo} </div>
                        {sfeExtractionQldbList?.length > 0 && (<div>
                            <div className="tabletitle" style={{ marginRight: '7px' }}>SFE Extraction</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th>Hash</th>
                                        <th >Created Time</th>
                                        <th >Associated PO</th>
                                        <th >Supplier Batch</th>
                                        <th >Tolling Facility</th>
                                        <th >Material Extracted</th>
                                        <th >Start Date</th>
                                        <th >End Date</th>
                                        <th >Upwell Butter (kg)</th>
                                        <th >Upwell Wax (kg)</th>
                                        <th >Upwell Oil (kg)</th>
                                        <th >Post SFE Biomass (kg)</th>
                                        {/* <th >Upwell CoA Butter File</th>
                                        <th >Upwell CoA Wax File</th>
                                        <th >Upwell CoA Oil File</th>
                                        <th >Upwell CoA Post SFE Biomass File</th>
                                        <th >SFE Report File</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {sfeExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.sfe_extractionHash}</td>}
                                            {index !== 0 && <td className={isChanged(item, sfeExtractionQldbList[index - 1], 'sfe_extractionHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.sfe_extractionHash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.sfe_extractionMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionMetadata, sfeExtractionQldbList[index - 1].sfe_extractionMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.sfe_extractionMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.associatedPo}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'associatedPo') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.associatedPo}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.supplierBatch}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'supplierBatch') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.supplierBatch}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.tolling_facility}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'tolling_facility') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.tolling_facility}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.material_extracted}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'material_extracted') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.material_extracted}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.shippedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'shippedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.shippedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.receivedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'receivedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.receivedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.upwell_butter}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'upwell_butter') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.upwell_butter}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.upwell_wax}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'upwell_wax') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.upwell_wax}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.upwell_oil}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'upwell_oil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.upwell_oil}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.post_sfe_biomass}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'post_sfe_biomass') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.post_sfe_biomass}</td>}
                                            {/* {index === 0 && <td className="tdbreak">{item.sfe_extractionData.upwell_coa_files_1_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'upwell_coa_files_1_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.upwell_coa_files_1_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.upwell_coa_files_2_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'upwell_coa_files_2_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.upwell_coa_files_2_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.upwell_coa_files_3_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'upwell_coa_files_3_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.upwell_coa_files_3_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.upwell_coa_files_4_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'upwell_coa_files_4_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.upwell_coa_files_4_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.sfe_report_file_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'sfe_report_file_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.sfe_report_file_display_name}</td>} */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Upwell CoA Butter File</th>
                                        <th >Upwell CoA Wax File</th>
                                        <th >Upwell CoA Oil File</th>
                                        <th >Upwell CoA Post SFE Biomass File</th>
                                        <th >SFE Report File</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sfeExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.sfe_extractionMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionMetadata, sfeExtractionQldbList[index - 1].sfe_extractionMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.sfe_extractionMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.upwell_coa_files_1_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'upwell_coa_files_1_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.upwell_coa_files_1_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.upwell_coa_files_2_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'upwell_coa_files_2_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.upwell_coa_files_2_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.upwell_coa_files_3_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'upwell_coa_files_3_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.upwell_coa_files_3_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.upwell_coa_files_4_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'upwell_coa_files_4_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.upwell_coa_files_4_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.sfe_extractionData.sfe_report_file_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.sfe_extractionData, sfeExtractionQldbList[index - 1].sfe_extractionData, 'sfe_report_file_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.sfe_extractionData.sfe_report_file_display_name}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>)}

                        {butterExtractionQldbList?.length > 0 && (<div>
                            <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Upwell Butter</div>
                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Hash</th>
                                        <th >Created Time</th>
                                        <th >Aerobic Plate Count (cfu/ml)</th>
                                        <th >E. Coli (cfu/ml)</th>
                                        <th >Total Coliforms (cfu/ml)</th>
                                        <th >Staphylococcus aureus (cfu/ml)</th>
                                        <th >Mold (cfu/ml)</th>
                                        <th >Yeast (cfu/ml)</th>
                                        <th >Salmonella spp (cfu/ml)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {butterExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.upwellCoa_butterHash}</td>}
                                            {index !== 0 && <td className={isChanged(item, butterExtractionQldbList[index - 1], 'upwellCoa_butterHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.upwellCoa_butterHash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_butterMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterMetadata, butterExtractionQldbList[index - 1].upwellCoa_butterMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_butterMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.aerobic_plant_count}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'aerobic_plant_count') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.aerobic_plant_count}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.e_coil}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'e_coil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.e_coil}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.total_coliforms}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'total_coliforms') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.total_coliforms}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.staphylococcus_aureus}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'staphylococcus_aureus') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.staphylococcus_aureus}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.mold}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'mold') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.mold}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.yeast}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'yeast') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.yeast}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.Salmonella_spp_key}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'Salmonella_spp_key') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.Salmonella_spp_key}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Moisture (%)</th>
                                        <th >Oil (%)</th>
                                        <th >Alkenone (%)</th>
                                        <th >Fucoxanthin (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {butterExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_butterMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterMetadata, butterExtractionQldbList[index - 1].upwellCoa_butterMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_butterMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.moisture}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'moisture') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.moisture}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.oil}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'oil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.oil}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.alkenone}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'alkenone') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.alkenone}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.fucoxanthin}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'fucoxanthin') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.fucoxanthin}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Arsenic (mg/kg)</th>
                                        <th >Cadmium (mg/kg)</th>
                                        <th >Lead (mg/kg)</th>
                                        <th >Mercury (mg/kg)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {butterExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_butterMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterMetadata, butterExtractionQldbList[index - 1].upwellCoa_butterMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_butterMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.arsenic}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'arsenic') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.arsenic}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.cardium}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'cardium') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.cardium}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.lead}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'lead') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.lead}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.mercury}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'mercury') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.mercury}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Fatty Acids Analysis</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Total saturated (%)</th>
                                        <th >Total monounsaturated (%)</th>
                                        <th >Total n-3 PUFA (%)</th>
                                        <th >Total n-6 PUFA (%)</th>
                                        <th >Total n-9 PUFA (%)</th>
                                        <th >Total PUFA (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {butterExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_butterMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterMetadata, butterExtractionQldbList[index - 1].upwellCoa_butterMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_butterMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.total_saturated}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'total_saturated') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.total_saturated}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.total_monousaturated}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'total_monousaturated') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.total_monousaturated}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.total_n_u_3pufa}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'total_n_u_3pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.total_n_u_3pufa}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.total_n_u_6pufa}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'total_n_u_6pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.total_n_u_6pufa}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.total_n_u_9pufa}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'total_n_u_9pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.total_n_u_9pufa}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.total_pufa}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'total_pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.total_pufa}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Specific Analysis</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Acid Value, mg KOH/g (%)</th>
                                        <th >Melting Point, ºC (%)</th>
                                        <th >Peroxide Value, meq/kg (%)</th>
                                        <th >Iodine (%)</th>
                                        <th >Saponification Value, mg KOH/g (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {butterExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_butterMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterMetadata, butterExtractionQldbList[index - 1].upwellCoa_butterMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_butterMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.acid_value_mg_KOH_g}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'acid_value_mg_KOH_g') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.acid_value_mg_KOH_g}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.melting_point_c}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'melting_point_c') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.melting_point_c}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.peroxide_value_meq_kg}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'peroxide_value_meq_kg') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.peroxide_value_meq_kg}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.iodine}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'iodine') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.iodine}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_butterData.saponification_value_mg_koh_g}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_butterData, butterExtractionQldbList[index - 1].upwellCoa_butterData, 'saponification_value_mg_koh_g') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_butterData.saponification_value_mg_koh_g}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>)}

                        {oleoExtractionQldbList?.length > 0 && (<div>
                            <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Upwell Oleo</div>
                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Hash</th>
                                        <th >Created Time</th>
                                        <th >Aerobic Plate Count (cfu/ml)</th>
                                        <th >E. Coli (cfu/ml)</th>
                                        <th >Total Coliforms (cfu/ml)</th>
                                        <th >Staphylococcus aureus (cfu/ml)</th>
                                        <th >Mold (cfu/ml)</th>
                                        <th >Yeast (cfu/ml)</th>
                                        <th >Salmonella spp (cfu/ml)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {oleoExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.upwellCoa_oleoHash}</td>}
                                            {index !== 0 && <td className={isChanged(item, oleoExtractionQldbList[index - 1], 'upwellCoa_oleoHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.upwellCoa_oleoHash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_oleoMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoMetadata, oleoExtractionQldbList[index - 1].upwellCoa_oleoMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_oleoMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.aerobic_plant_count}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'aerobic_plant_count') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.aerobic_plant_count}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.e_coil}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'e_coil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.e_coil}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.total_coliforms}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'total_coliforms') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.total_coliforms}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.staphylococcus_aureus}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'staphylococcus_aureus') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.staphylococcus_aureus}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.mold}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'mold') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.mold}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.yeast}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'yeast') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.yeast}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.Salmonella_spp_key}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'Salmonella_spp_key') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.Salmonella_spp_key}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Moisture (%)</th>
                                        <th >Oil (%)</th>
                                        <th >Fucoxanthin (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {oleoExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_oleoMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoMetadata, oleoExtractionQldbList[index - 1].upwellCoa_oleoMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_oleoMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.moisture}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'moisture') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.moisture}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.oil}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'oil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.oil}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.fucoxanthin}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'fucoxanthin') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.fucoxanthin}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Arsenic (mg/kg)</th>
                                        <th >Cadmium (mg/kg)</th>
                                        <th >Lead (mg/kg)</th>
                                        <th >Mercury (mg/kg)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {oleoExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_oleoMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoMetadata, oleoExtractionQldbList[index - 1].upwellCoa_oleoMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_oleoMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.arsenic}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'arsenic') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.arsenic}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.cardium}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'cardium') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.cardium}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.lead}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'lead') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.lead}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.mercury}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'mercury') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.mercury}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Fatty Acids Analysis</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Total saturated (%)</th>
                                        <th >Total monounsaturated (%)</th>
                                        <th >Total n-3 PUFA (%)</th>
                                        <th >Total n-6 PUFA (%)</th>
                                        <th >Total n-9 PUFA (%)</th>
                                        <th >Total PUFA (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {oleoExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_oleoMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoMetadata, oleoExtractionQldbList[index - 1].upwellCoa_oleoMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_oleoMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.total_saturated}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'total_saturated') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.total_saturated}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.total_monousaturated}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'total_monousaturated') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.total_monousaturated}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.total_n_u_3pufa}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'total_n_u_3pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.total_n_u_3pufa}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.total_n_u_6pufa}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'total_n_u_6pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.total_n_u_6pufa}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.total_n_u_9pufa}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'total_n_u_9pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.total_n_u_9pufa}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_oleoData.total_pufa}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_oleoData, oleoExtractionQldbList[index - 1].upwellCoa_oleoData, 'total_pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_oleoData.total_pufa}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>)}

                        {waxExtractionQldbList?.length > 0 && (<div>
                            <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Upwell Wax</div>
                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Hash</th>
                                        <th >Created Time</th>
                                        <th >Aerobic Plate Count (cfu/ml)</th>
                                        <th >E. Coli (cfu/ml)</th>
                                        <th >Total Coliforms (cfu/ml)</th>
                                        <th >Staphylococcus aureus (cfu/ml)</th>
                                        <th >Mold (cfu/ml)</th>
                                        <th >Yeast (cfu/ml)</th>
                                        <th >Salmonella spp (cfu/ml)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {waxExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.upwellCoa_waxHash}</td>}
                                            {index !== 0 && <td className={isChanged(item, waxExtractionQldbList[index - 1], 'upwellCoa_waxHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.upwellCoa_waxHash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_waxMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxMetadata, waxExtractionQldbList[index - 1].upwellCoa_waxMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_waxMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.aerobic_plant_count}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'aerobic_plant_count') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.aerobic_plant_count}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.e_coil}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'e_coil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.e_coil}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.total_coliforms}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'total_coliforms') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.total_coliforms}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.staphylococcus_aureus}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'staphylococcus_aureus') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.staphylococcus_aureus}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.mold}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'mold') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.mold}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.yeast}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'yeast') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.yeast}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.Salmonella_spp_key}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'Salmonella_spp_key') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.Salmonella_spp_key}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Moisture (%)</th>
                                        <th >Alkenone (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {waxExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_waxMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxMetadata, waxExtractionQldbList[index - 1].upwellCoa_waxMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_waxMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.moisture}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'moisture') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.moisture}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.alkenone}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'alkenone') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.alkenone}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Specific Analysis</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Acid Value, mg KOH/g (%)</th>
                                        <th >Melting Point, ºC (%)</th>
                                        <th >Peroxide Value, meq/kg (%)</th>
                                        <th >Iodine (%)</th>
                                        <th >Saponification Value, mg KOH/g (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {waxExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_waxMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxMetadata, waxExtractionQldbList[index - 1].upwellCoa_waxMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_waxMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.acid_value_mg_KOH_g}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'acid_value_mg_KOH_g') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.acid_value_mg_KOH_g}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.melting_point_c}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'melting_point_c') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.melting_point_c}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.peroxide_value_meq_kg}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'peroxide_value_meq_kg') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.peroxide_value_meq_kg}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.iodine}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'iodine') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.iodine}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_waxData.saponification_value_mg_koh_g}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_waxData, waxExtractionQldbList[index - 1].upwellCoa_waxData, 'saponification_value_mg_koh_g') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_waxData.saponification_value_mg_koh_g}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>)}

                        {postSfeBiomassExtractionQldbList?.length > 0 && (<div>
                            <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Upwell Post SFE Biomass</div>
                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Hash</th>
                                        <th >Created Time</th>
                                        <th >Aerobic Plate Count (cfu/ml)</th>
                                        <th >E. Coli (cfu/ml)</th>
                                        <th >Total Coliforms (cfu/ml)</th>
                                        <th >Staphylococcus aureus (cfu/ml)</th>
                                        <th >Mold (cfu/ml)</th>
                                        <th >Yeast (cfu/ml)</th>
                                        <th >Salmonella spp (cfu/ml)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {postSfeBiomassExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.upwellCoa_post_sfe_biomassHash}</td>}
                                            {index !== 0 && <td className={isChanged(item, postSfeBiomassExtractionQldbList[index - 1], 'upwellCoa_post_sfe_biomassHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.upwellCoa_post_sfe_biomassHash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_post_sfe_biomassMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassMetadata, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_post_sfe_biomassMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.aerobic_plant_count}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'aerobic_plant_count') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.aerobic_plant_count}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.e_coil}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'e_coil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.e_coil}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.total_coliforms}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'total_coliforms') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.total_coliforms}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.staphylococcus_aureus}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'staphylococcus_aureus') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.staphylococcus_aureus}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.mold}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'mold') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.mold}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.yeast}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'yeast') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.yeast}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.Salmonella_spp_key}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'Salmonella_spp_key') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.Salmonella_spp_key}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Arsenic (mg/kg)</th>
                                        <th >Cadmium (mg/kg)</th>
                                        <th >Lead (mg/kg)</th>
                                        <th >Mercury (mg/kg)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {postSfeBiomassExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_post_sfe_biomassMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassMetadata, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_post_sfe_biomassMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.arsenic}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'arsenic') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.arsenic}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.cardium}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'cardium') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.cardium}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.lead}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'lead') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.lead}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.mercury}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'mercury') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.mercury}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Moisture (%)</th>
                                        <th >Ash (%)</th>
                                        <th >Protein (%)</th>
                                        <th >Carbohydrate (%)</th>
                                        <th >Oil (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {postSfeBiomassExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_post_sfe_biomassMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassMetadata, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_post_sfe_biomassMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.moisture}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'moisture') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.moisture}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.ash}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'ash') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.ash}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.protein}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'protein') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.protein}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.carbohydrate}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'carbohydrate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.carbohydrate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_post_sfe_biomassData.oil}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_post_sfe_biomassData, postSfeBiomassExtractionQldbList[index - 1].upwellCoa_post_sfe_biomassData, 'oil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_post_sfe_biomassData.oil}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>)}

                        {aqueousExtractionQldbList?.length > 0 && (<div>
                            <div className="tabletitle" style={{ marginRight: '7px' }}>Aqueous Extraction</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th>Hash</th>
                                        <th >Created Time</th>
                                        <th >Associated PO</th>
                                        <th >Supplier Batch</th>
                                        <th >Tolling Facility</th>
                                        <th >Material Extracted (kg)</th>
                                        <th >Start Date</th>
                                        <th >End Date</th>
                                        <th >Upwell Hydro (kg)</th>
                                        <th >Remaining Biomass (kg)</th>
                                        <th >Upwell CoA Hydro File</th>
                                        <th >Upwell CoA Remaining Biomass File</th>
                                        <th >Extraction Report File</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {aqueousExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.aqueous_extractionHash}</td>}
                                            {index !== 0 && <td className={isChanged(item, aqueousExtractionQldbList[index - 1], 'aqueous_extractionHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.aqueous_extractionHash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.aqueous_extractionMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionMetadata, aqueousExtractionQldbList[index - 1].aqueous_extractionMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.aqueous_extractionMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.aqueous_extractionData.associatedPo}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionData, aqueousExtractionQldbList[index - 1].aqueous_extractionData, 'associatedPo') ? 'currentdata tdbreak' : 'tdbreak'}>{item.aqueous_extractionData.associatedPo}</td>}
                                            {index === 0 && <td className="tdbreak">{item.aqueous_extractionData.supplierBatch}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionData, aqueousExtractionQldbList[index - 1].aqueous_extractionData, 'supplierBatch') ? 'currentdata tdbreak' : 'tdbreak'}>{item.aqueous_extractionData.supplierBatch}</td>}
                                            {index === 0 && <td className="tdbreak">{item.aqueous_extractionData.tolling_facility}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionData, aqueousExtractionQldbList[index - 1].aqueous_extractionData, 'tolling_facility') ? 'currentdata tdbreak' : 'tdbreak'}>{item.aqueous_extractionData.tolling_facility}</td>}
                                            {index === 0 && <td className="tdbreak">{item.aqueous_extractionData.material_extracted}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionData, aqueousExtractionQldbList[index - 1].aqueous_extractionData, 'material_extracted') ? 'currentdata tdbreak' : 'tdbreak'}>{item.aqueous_extractionData.material_extracted}</td>}
                                            {index === 0 && <td className="tdbreak">{item.aqueous_extractionData.shippedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionData, aqueousExtractionQldbList[index - 1].aqueous_extractionData, 'shippedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.aqueous_extractionData.shippedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.aqueous_extractionData.receivedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionData, aqueousExtractionQldbList[index - 1].aqueous_extractionData, 'receivedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.aqueous_extractionData.receivedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.aqueous_extractionData.upwell_hydro}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionData, aqueousExtractionQldbList[index - 1].aqueous_extractionData, 'upwell_hydro') ? 'currentdata tdbreak' : 'tdbreak'}>{item.aqueous_extractionData.upwell_hydro}</td>}
                                            {index === 0 && <td className="tdbreak">{item.aqueous_extractionData.remaining_biomass}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionData, aqueousExtractionQldbList[index - 1].aqueous_extractionData, 'remaining_biomass') ? 'currentdata tdbreak' : 'tdbreak'}>{item.aqueous_extractionData.remaining_biomass}</td>}
                                            {index === 0 && <td className="tdbreak">{item.aqueous_extractionData.upwell_coa_files_1_aq_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionData, aqueousExtractionQldbList[index - 1].aqueous_extractionData, 'upwell_coa_files_1_aq_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.aqueous_extractionData.upwell_coa_files_1_aq_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.aqueous_extractionData.upwell_coa_files_2_aq_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionData, aqueousExtractionQldbList[index - 1].aqueous_extractionData, 'upwell_coa_files_2_aq_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.aqueous_extractionData.upwell_coa_files_2_aq_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.aqueous_extractionData.extraction_report_file_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.aqueous_extractionData, aqueousExtractionQldbList[index - 1].aqueous_extractionData, 'extraction_report_file_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.aqueous_extractionData.extraction_report_file_display_name}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>)}

                        {hydroExtractionQldbList?.length > 0 && (<div>
                            <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Upwell Hydro</div>
                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Hash</th>
                                        <th >Created Time</th>
                                        <th >Aerobic Plate Count (cfu/ml)</th>
                                        <th >E. Coli (cfu/ml)</th>
                                        <th >Total coliforms (cfu/ml)</th>
                                        <th >Staphylococcus aureus (cfu/ml)</th>
                                        <th >Mold (cfu/ml)</th>
                                        <th >Yeast (cfu/ml)</th>
                                        <th >Salmonella spp (cfu/ml)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hydroExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.upwellCoa_hydroHash}</td>}
                                            {index !== 0 && <td className={isChanged(item, hydroExtractionQldbList[index - 1], 'upwellCoa_hydroHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.upwellCoa_hydroHash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_hydroMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroMetadata, hydroExtractionQldbList[index - 1].upwellCoa_hydroMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_hydroMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.aerobic_plant_count}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'aerobic_plant_count') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.aerobic_plant_count}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.e_coil}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'e_coil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.e_coil}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.total_coliforms}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'total_coliforms') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.total_coliforms}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.staphylococcus_aureus}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'staphylococcus_aureus') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.staphylococcus_aureus}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.mold}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'mold') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.mold}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.yeast}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'yeast') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.yeast}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.Salmonella_spp_key}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'Salmonella_spp_key') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.Salmonella_spp_key}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Arsenic (mg/kg)</th>
                                        <th >Cadmium (mg/kg)</th>
                                        <th >Lead (mg/kg)</th>
                                        <th >Mercury (mg/kg)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hydroExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_hydroMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroMetadata, hydroExtractionQldbList[index - 1].upwellCoa_hydroMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_hydroMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.arsenic}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'arsenic') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.arsenic}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.cardium}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'cardium') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.cardium}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.lead}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'lead') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.lead}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.mercury}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'mercury') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.mercury}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Moisture (%)</th>
                                        <th >Ash (%)</th>
                                        <th >Protein (%)</th>
                                        <th >Carbohydrate (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hydroExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_hydroMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroMetadata, hydroExtractionQldbList[index - 1].upwellCoa_hydroMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_hydroMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.moisture}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'moisture') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.moisture}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.ash}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'ash') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.ash}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.protein}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'protein') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.protein}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_hydroData.carbohydrate}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_hydroData, hydroExtractionQldbList[index - 1].upwellCoa_hydroData, 'carbohydrate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_hydroData.carbohydrate}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>)}

                        {remainingBiomassExtractionQldbList?.length > 0 && (<div>
                            <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA - Remaining Biomass</div>
                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Hash</th>
                                        <th >Created Time</th>
                                        <th >Aerobic Plate Count (cfu/ml)</th>
                                        <th >E. Coli (cfu/ml)</th>
                                        <th >Total coliforms (cfu/ml)</th>
                                        <th >Staphylococcus aureus (cfu/ml)</th>
                                        <th >Mold (cfu/ml)</th>
                                        <th >Yeast (cfu/ml)</th>
                                        <th >Salmonella spp (cfu/ml)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {remainingBiomassExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.upwellCoa_remaining_biomassHash}</td>}
                                            {index !== 0 && <td className={isChanged(item, remainingBiomassExtractionQldbList[index - 1], 'upwellCoa_remaining_biomassHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.upwellCoa_remaining_biomassHash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_remaining_biomassMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassMetadata, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_remaining_biomassMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.aerobic_plant_count}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'aerobic_plant_count') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.aerobic_plant_count}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.e_coil}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'e_coil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.e_coil}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.total_coliforms}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'total_coliforms') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.total_coliforms}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.staphylococcus_aureus}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'staphylococcus_aureus') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.staphylococcus_aureus}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.mold}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'mold') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.mold}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.yeast}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'yeast') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.yeast}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.Salmonella_spp_key}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'Salmonella_spp_key') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.Salmonella_spp_key}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Arsenic (mg/kg)</th>
                                        <th >Cadmium (mg/kg)</th>
                                        <th >Lead (mg/kg)</th>
                                        <th >Mercury (mg/kg)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {remainingBiomassExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_remaining_biomassMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassMetadata, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_remaining_biomassMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.arsenic}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'arsenic') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.arsenic}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.cardium}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'cardium') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.cardium}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.lead}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'lead') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.lead}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.mercury}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'mercury') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.mercury}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Moisture (%)</th>
                                        <th >Ash (%)</th>
                                        <th >Protein (%)</th>
                                        <th >Carbohydrate (%)</th>
                                        <th >Oil (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {remainingBiomassExtractionQldbList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoa_remaining_biomassMetadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassMetadata, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoa_remaining_biomassMetadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.moisture}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'moisture') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.moisture}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.ash}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'ash') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.ash}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.protein}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'protein') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.protein}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.carbohydrate}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'carbohydrate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.carbohydrate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.upwellCoa_remaining_biomassData.oil}</td>}
                                            {index !== 0 && <td className={isChanged(item.upwellCoa_remaining_biomassData, remainingBiomassExtractionQldbList[index - 1].upwellCoa_remaining_biomassData, 'oil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoa_remaining_biomassData.oil}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>)}

                    </div>
                </div>)}
            </div>
        </div>
    )

}

export default ExtractionReport;