import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../AddNewBatch.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { postData, getData, getIdRefreshToken } from '../../ApiServices/ApiService';

function UpwellWax() {
  const location = useLocation();
  const batchNo = location.state?.name;
  const viewData = location.state?.view;
  const upwellCoa = location.state?.name2;
  const batchInfo = location.state?.batchInfo;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isDataLoad, setIsDataLoad] = useState(false);

  const [dataFetched, setDataFetched] = useState(false);
  const update = location.state?.update;
  const batchId = location.state?.batchId;

  const [selectedDropdownKey, setSelectedDropdownKey] = useState({
    // arsenic_key: "",
    // cardium_key: "",
    // lead_key: "",
    // mercury_key: "",
    // detection_key: "",
    Salmonella_spp_key: "",
  });

  const handleChange = (event, inputName) => {
    setSelectedDropdownKey({ ...selectedDropdownKey, [inputName]: event.target.value });
  };

  const [isAdmin, setisAdmin] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      if (!dataFetched) {
        if (localStorage.getItem('isAdminLogin')) {
          const adminTypeValue = localStorage.getItem('isAdminLogin');
          if (adminTypeValue === 'false') {
            setisAdmin(true);
          } else {
            if (viewData) {
              setisAdmin(true);
            } else {
              setisAdmin(false);
            }
          }
        }
        if (update) {
          console.log('If update', update);
          getUpwellWaxDetails();
        } else {
          console.log('els update', update);
        }
      }
    } else {
      callLogout();
    }
  }, [dataFetched, update, batchNo, batchId, viewData]);

  const getUpwellWaxDetails = () => {
    const getPayload = {
      op: "GET_UPWELL_COA_EXTRACTION",
      batchno: batchNo,
      type_batch: "wax",
      supplierBatch: batchId
    }
    setIsDataLoad(true);
    setError('');
    getData('test2', JSON.stringify(getPayload), userData.id_token).then(resp => {
      // setIsDataLoad(false);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        setIsDataLoad(false);
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setError('');
                setDataFetched(true);
                setMicrobiology((prevMicrobiology) => ({
                  ...prevMicrobiology,
                  ...respData.dataJ[0],
                }));

                setSpecificAnalysis((prevSpecificAnalysis) => ({
                  ...prevSpecificAnalysis,
                  ...respData.dataJ[0],
                }));

                setGeneral((prevGeneral) => ({
                  ...prevGeneral,
                  ...respData.dataJ[0],
                }));

                setSelectedDropdownKey((prevSelectedDropdownKey) => ({
                  ...prevSelectedDropdownKey,
                  ...respData.dataJ[0],
                }));
              } else {
                setError(respData.info);
                upwellWaxDetailsEmpty();
              }
            } else {
              setError(respData.info);
              upwellWaxDetailsEmpty();
            }
          } else {
            setError('Error While Loading Manual Entry Upwell CoA - Wax Details');
            upwellWaxDetailsEmpty();
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getUpwellWax', '');
            setIsDataLoad(true);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Loading Manual Entry Upwell CoA - Wax Details');
          upwellWaxDetailsEmpty();
        }
      }).catch((error) => {
        setIsDataLoad(false);
        console.log('network error:', error);
        setError('Network Error While Loading Manual Entry Upwell CoA - Wax Details');
        upwellWaxDetailsEmpty();
      });
    }).catch((error) => {
      setIsDataLoad(false);
      console.log('network error:', error);
      setError('Network Error While Loading Manual Entry Upwell CoA - Wax Details');
      upwellWaxDetailsEmpty();
    });
  }

  const upwellWaxDetailsEmpty = () => {
    setMicrobiology({
      aerobic_plant_count: '',
      e_coil: '',
      total_coliforms: '',
      staphylococcus_aureus: '',
      mold: '',
      yeast: '',
      Salmonella_spp: '',
    })
    setGeneral({
      moisture: '',
      alkenone: '',
    })
    setSpecificAnalysis({
      acid_value_mg_KOH_g: '',
      melting_point_c: '',
      peroxide_value_meq_kg: '',
      iodine: '',
      saponification_value_mg_koh_g: ''
    })
    setSelectedDropdownKey({
      Salmonella_spp_key: "",
    })
  }

  const callRefreshTokenApi = (type, event) => {
    const payload = {
      "email": userData.email,
      "oldRefreshToken": userData.refresh_token
    }
    getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
      if (resp.statusCode === 200) {
        if (resp.message.toLowerCase() === 'success') {
          userData.id_token = resp.dataJ.id_token;
          localStorage.setItem('userInfo', JSON.stringify(userData));
          if (type === 'getUpwellWax') {
            getUpwellWaxDetails();
          } else if (type === 'insertUpwellWax') {
            insertUpwellWaxData(event);
          } else if (type === 'updateUpwellWax') {
            updateUpwellWaxData(event);
          } else {
            console.log('type', type)
          }
        } else {
          callLogout();
        }
      } else {
        callLogout();
      }
    }).catch((error) => {
      console.log('refresh error', error);
      callLogout();
    });
  }

  const callLogout = () => {
    localStorage.removeItem('isAdminLogin');
    localStorage.removeItem('userInfo');
    localStorage.clear();
    navigate('/');
    window.location.reload();
  }

  const [microbiology, setMicrobiology] = useState({
    aerobic_plant_count: '',
    e_coil: '',
    total_coliforms: '',
    staphylococcus_aureus: '',
    mold: '',
    yeast: '',
    Salmonella_spp: '',
  });
  const [general, setGeneral] = useState({
    moisture: '',
    alkenone: '',
  });

  const [specificAnalysis, setSpecificAnalysis] = useState({
    acid_value_mg_KOH_g: '',
    melting_point_c: '',
    peroxide_value_meq_kg: '',
    iodine: '',
    saponification_value_mg_koh_g: ''
  });



  // const handleMicrobiology = (event, inputName) => {
  //   const newValue = event.target.value.replace(/[-]/g, '');
  //   setMicrobiology({ ...microbiology, [inputName]: newValue });
  // };
  const numberPattern = /^[<NDnd0-9]+$/;
  const handleMicrobiology = (event, inputName) => {
    console.log('event', event);
    console.log('event.target.value', event.target.value)
    const newValue = event.target.value;
    // setMicrobiology({ ...microbiology, [inputName]: newValue });
    if (numberPattern.test(newValue)) {
      setMicrobiology({ ...microbiology, [inputName]: newValue });
    } else {
      if (newValue) {
      } else {
        setMicrobiology({ ...microbiology, [inputName]: '' });
      }
    }
  };

  // const handleHeavyMetals = (event, inputName) => {
  //   const newValue = event.target.value.replace(/[-]/g, '');
  //   setHeavyMetals({ ...heavyMetals, [inputName]: newValue });
  // };

  // const handleFattyAcids = (event, inputName) => {
  //   const newValue = event.target.value.replace(/[-]/g, '');
  //   setFattyAcids({ ...fattyAcids, [inputName]: newValue });
  // };

  const handleGeneral = (event, inputName) => {
    const newValue = event.target.value.replace(/[-]/g, '');
    setGeneral({ ...general, [inputName]: newValue });
  };


  const handleKeyPress = (event) => {
    // Prevent "-" and "." keys from being entered
    if (event.key === '-' || event.key === '+' || event.key === 'e') {
      event.preventDefault();
    }
  };

  const handleSpecificAnalysis = (event, inputName) => {
    const newValue = event.target.value.replace(/[-]/g, '');
    setSpecificAnalysis({ ...specificAnalysis, [inputName]: newValue });
  };

  const handleRedirect = (path) => {
    if (path === '/postBiomass') {
      navigate(path, { state: { name: batchNo, batchId: batchId, name2: upwellCoa, update: update, view: viewData, batchInfo: batchInfo } });
    } else {
      navigate(path, { state: { name: batchNo, update: update, view: viewData, batchInfo: batchInfo } });
    }
  };

  const handleSubmit = (path) => {

    const upwellWaxdata = {
      microbiology,
      //  heavyMetals,
      //  fattyAcids,
      general,
      batchNo,
      upwellCoa,
      specificAnalysis
    }
    console.log('data', upwellWaxdata);
    if (update) {
      updateUpwellWaxData(path);
    } else {
      insertUpwellWaxData(path);
    }

    // navigate(path,{ state: { name: batchNo,batchId: batchId, name2 : upwellCoa,update:update } });
  };

  const insertUpwellWaxData = async (path) => {
    const payload = {
      "op": "INSERT_UPWELL_COA_EXTRACTION",
      // "op": "SUPPLIER_COA_RAW_BIOMASS_TYPE1",
      "batchno": batchNo,
      "supplierBatch": batchId,
      "aerobic_plant_count": microbiology.aerobic_plant_count,
      "e_coil": microbiology.e_coil,
      "total_coliforms": microbiology.total_coliforms,
      "staphylococcus_aureus": microbiology.staphylococcus_aureus,
      "mold": microbiology.mold,
      "yeast": microbiology.yeast,
      "Salmonella_spp": microbiology.Salmonella_spp,
      "moisture": general.moisture,
      "alkenone": general.alkenone,
      "acid_value_mg_KOH_g": specificAnalysis.acid_value_mg_KOH_g,
      "melting_point_c": specificAnalysis.melting_point_c,
      "peroxide_value_meq_kg": specificAnalysis.peroxide_value_meq_kg,
      "iodine": specificAnalysis.iodine,
      "saponification_value_mg_koh_g": specificAnalysis.saponification_value_mg_koh_g,
      // "arsenic_key": selectedDropdownKey.arsenic_key,
      // "cardium_key": selectedDropdownKey.cardium_key,
      // "lead_key": selectedDropdownKey.lead_key,
      // "mercury_key": selectedDropdownKey.mercury_key,
      // "detection_key": selectedDropdownKey.detection_key,
      "Salmonella_spp_key": selectedDropdownKey.Salmonella_spp_key,
      "type_batch": "wax"
    }
    setLoading(true);
    setError('');
    await postData('test2', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setError(respData.info);
              // setTimeout(() => {
              setLoading(false);
              navigate(path, { state: { name: batchNo, batchId: batchId, name2: upwellCoa, update: update, view: viewData, batchInfo: batchInfo } });
              // }, 2000);
            } else {
              setLoading(false);
              setError(respData.info);
            }
          } else {
            setLoading(false);
            setError('Error While Adding Manual Entry Upwell CoA - Wax Details');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('insertUpwellWax', path);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setError('Error Occurred While Adding Manual Entry Upwell CoA - Wax Details');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setError('Network Error While Adding Manual Entry Upwell CoA - Wax Details');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setError('Network Error While Adding Manual Entry Upwell CoA - Wax Details!');
    });
  }

  const updateUpwellWaxData = async (path) => {
    const payload = {
      "op": "UPDATE_UPWELL_COA_EXTRACTION",
      // "op": "UPDATE_SUPPLIER_COA_RAW_BIOMASS_DETAILS_TYPE_BATCH",
      "batchno": batchNo,
      "supplierBatch": batchId,
      "aerobic_plant_count": microbiology.aerobic_plant_count,
      "e_coil": microbiology.e_coil,
      "total_coliforms": microbiology.total_coliforms,
      "staphylococcus_aureus": microbiology.staphylococcus_aureus,
      "mold": microbiology.mold,
      "yeast": microbiology.yeast,
      "Salmonella_spp": microbiology.Salmonella_spp,
      "moisture": general.moisture,
      "alkenone": general.alkenone,
      "acid_value_mg_KOH_g": specificAnalysis.acid_value_mg_KOH_g,
      "melting_point_c": specificAnalysis.melting_point_c,
      "peroxide_value_meq_kg": specificAnalysis.peroxide_value_meq_kg,
      "iodine": specificAnalysis.iodine,
      "saponification_value_mg_koh_g": specificAnalysis.saponification_value_mg_koh_g,
      // "arsenic_key": selectedDropdownKey.arsenic_key,
      // "cardium_key": selectedDropdownKey.cardium_key,
      // "lead_key": selectedDropdownKey.lead_key,
      // "mercury_key": selectedDropdownKey.mercury_key,
      // "detection_key": selectedDropdownKey.detection_key,
      "Salmonella_spp_key": selectedDropdownKey.Salmonella_spp_key,
      "type_batch": "wax"
    }
    setLoading(true);
    setError('');
    await postData('test2', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setError(respData.info);
              // setTimeout(() => {
              setLoading(false);
              navigate(path, { state: { name: batchNo, batchId: batchId, name2: upwellCoa, update: update, view: viewData, batchInfo: batchInfo } });

              // }, 2000);
            } else {
              setLoading(false);
              setError(respData.info);
            }
          } else {
            setLoading(false);
            setError('Error While Updating Manual Entry Upwell CoA - Wax Details');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('updateUpwellWax', path);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setError('Error Occurred While Updating Manual Entry Upwell CoA - Wax Details');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setError('Network Error While Updating Manual Entry Upwell CoA - Wax Details');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setError('Network Error While Updating Manual Entry Upwell CoA - Wax Details!');
    });
  }

  return (
    <div>
      <div className="upwelltitle" style={{ display: 'flex' }}><img src="./upwelllogo.png" alt="upwell" className="logoStyling" />
        <span className="addTitle">Batch details: Manual entry Upwell CoA - Upwell wax</span></div>
      <div className="maincontainerbiomass">

        <div className="supplierCoaContainer">
          <div className="supplierCoaDiv">
            <div><span className="addTitle-2">Manual entry Upwell CoA - Upwell wax</span></div>
            {isDataLoad && (
              <div className="dot-loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            )
            }
            <div className="batchNoStyling">Batch Number:  {batchNo}</div>
            <div className="batchNoStyling">UpwellWax CoA: {upwellCoa && <span> {upwellCoa.upwell_coa_files_2_display_name}</span>}</div>

            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <div className="sub-title">Microbiology (cfu/ml)</div>
                <div className="aling-right">
                  <span className="input-title"> Aerobic Plate Count</span>
                  <span className="display-flex-span "><input type="text" id="aerobic_plant_count" value={microbiology.aerobic_plant_count}
                    onKeyPress={handleKeyPress} onChange={(e) => handleMicrobiology(e, 'aerobic_plant_count')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                <div className="aling-right">
                  <span className="input-title"> E. Coli</span>
                  <span className="display-flex-span "><input type="text" id="e_coil" value={microbiology.e_coil}
                    onKeyPress={handleKeyPress} onChange={(e) => handleMicrobiology(e, 'e_coil')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                <div className="aling-right">
                  <span className="input-title"> Total Coliforms</span>
                  <span className="display-flex-span "><input type="text" id="total_coliforms" value={microbiology.total_coliforms}
                    onKeyPress={handleKeyPress} onChange={(e) => handleMicrobiology(e, 'total_coliforms')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                <div className="aling-right">
                  <span className="input-title"> Staphylococcus aureus</span>
                  <span className="display-flex-span "><input type="text" id="staphylococcus_aureus" value={microbiology.staphylococcus_aureus}
                    onKeyPress={handleKeyPress} onChange={(e) => handleMicrobiology(e, 'staphylococcus_aureus')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                <div className="aling-right">
                  <span className="input-title"> Mold</span>
                  <span className="display-flex-span "><input type="text" id="mold" value={microbiology.mold}
                    onKeyPress={handleKeyPress} onChange={(e) => handleMicrobiology(e, 'mold')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                <div className="aling-right">
                  <span className="input-title"> Yeast</span>
                  <span className="display-flex-span "><input type="text" id="yeast" value={microbiology.yeast}
                    onKeyPress={handleKeyPress} onChange={(e) => handleMicrobiology(e, 'yeast')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                <div className="aling-right">
                  <span className="input-title"> Salmonella spp</span>
                  <span className="display-flex-span ">
                    <select value={selectedDropdownKey.Salmonella_spp_key} onChange={(e) => handleChange(e, 'Salmonella_spp_key')} style={{ outline: 'none', padding: '5px' }} disabled={isAdmin} >
                      <option value="">Select</option>
                      <option value="+(ve)">+</option>
                      <option value="-(ve)">-</option>
                    </select>
                  </span>
                  {/* <span className="display-flex-span "><input type="number" id="Salmonella_spp" value={microbiology.Salmonella_spp}
                        onKeyPress={handleKeyPress} onChange={(e) => handleMicrobiology(e, 'Salmonella_spp')}  placeholder="" className="inputStyle1"  disabled={isAdmin} /></span> */}
                </div>

              </div>
              {/* <div className="flexAuto">
                  <div className="sub-title">Heavy Metals (mg/kg)</div>
                  <div className="aling-right">
                     <span className="input-title"> Arsenic</span>
                      <span><input type="number" id="arsenic" value={heavyMetals.arsenic}
                        onKeyPress={handleKeyPress} onChange={(e) => handleHeavyMetals(e, 'arsenic')}  placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                  <div className="aling-right">
                     <span className="input-title"> Cadmium</span> 
                     <span><input type="number" id="cadmium" value={heavyMetals.cadmium}
                      onKeyPress={handleKeyPress} onChange={(e) => handleHeavyMetals(e, 'cadmium')}  placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                  <div className="aling-right">
                     <span className="input-title"> Lead</span> 
                     <span><input type="number" id="lead" value={heavyMetals.lead}
                      onKeyPress={handleKeyPress} onChange={(e) => handleHeavyMetals(e, 'lead')}  placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                  <div className="aling-right">
                     <span className="input-title"> Mercury</span>
                      <span><input type="number" id="mercury" value={heavyMetals.mercury}
                       onKeyPress={handleKeyPress} onChange={(e) => handleHeavyMetals(e, 'mercury')}  placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                 
              </div>
              <div className="flexAuto">
                  <div className="sub-title">Fatty Acids Analysis (%)</div>
                  <div className="aling-right">
                     <span className="input-title"> Total saturated</span>
                      <span><input type="number" id="saturated" value={fattyAcids.saturated}
                       onKeyPress={handleKeyPress} onChange={(e) => handleFattyAcids(e, 'saturated')}  placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                  <div className="aling-right">
                     <span className="input-title"> Total monounsaturated</span> 
                     <span><input type="number" id="monounsaturated" value={fattyAcids.monounsaturated}
                       onKeyPress={handleKeyPress} onChange={(e) => handleFattyAcids(e, 'monounsaturated')} placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                  <div className="aling-right">
                     <span className="input-title"> Total n-3 PUFA</span>
                      <span><input type="number" id="n3PUFA" value={fattyAcids.n3PUFA}
                        onKeyPress={handleKeyPress} onChange={(e) => handleFattyAcids(e, 'n3PUFA')} placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                  <div className="aling-right">
                     <span className="input-title"> Total n-6 PUFA</span>
                      <span><input type="number" id="n6PUFA" value={fattyAcids.n6PUFA}
                        onKeyPress={handleKeyPress} onChange={(e) => handleFattyAcids(e, 'n6PUFA')} placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                  <div className="aling-right">
                     <span className="input-title">Total n-9 PUFA</span>
                      <span><input type="number" id="n9PUFA" value={fattyAcids.n9PUFA}
                        onKeyPress={handleKeyPress} onChange={(e) => handleFattyAcids(e, 'n9PUFA')} placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                  <div className="aling-right">
                     <span className="input-title">Total PUFA</span>
                      <span><input type="number" id="pUFA" value={fattyAcids.pUFA} 
                        onKeyPress={handleKeyPress} onChange={(e) => handleFattyAcids(e, 'pUFA')} placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>

              </div> */}
              <div className="flexAuto">
                <div className="sub-title">General (%)</div>
                <div className="aling-right">
                  <span className="input-title"> Moisture</span>
                  <span className="display-flex-span "><input type="number" id="moisture" value={general.moisture}
                    onKeyPress={handleKeyPress} onChange={(e) => handleGeneral(e, 'moisture')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                {/* <div className="aling-right">
                     <span className="input-title"> Ash</span> 
                     <span><input type="number" id="aSh" value={general.aSh}
                       onKeyPress={handleKeyPress} onChange={(e) => handleGeneral(e, 'aSh')} placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                  <div className="aling-right">
                     <span className="input-title"> Protein</span>
                      <span><input type="number" id="protein" value={general.protein}
                        onKeyPress={handleKeyPress} onChange={(e) => handleGeneral(e, 'protein')} placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                  <div className="aling-right">
                     <span className="input-title"> Carbohydrate</span>
                      <span><input type="number" id="carbohydrate" value={general.carbohydrate}
                        onKeyPress={handleKeyPress} onChange={(e) => handleGeneral(e, 'carbohydrate')} placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div>
                  <div className="aling-right">
                     <span className="input-title">Oil</span>
                      <span><input type="number" id="oil" value={general.oil}
                        onKeyPress={handleKeyPress} onChange={(e) => handleGeneral(e, 'oil')} placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div> */}
                <div className="aling-right">
                  <span className="input-title">Alkenone</span>
                  <span className="display-flex-span "><input type="number" id="alkenone" value={general.alkenone}
                    onKeyPress={handleKeyPress} onChange={(e) => handleGeneral(e, 'alkenone')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                {/* <div className="aling-right">
                     <span className="input-title">Fucoxanthin</span>
                      <span><input type="number" id="fucoxanthin" value={general.fucoxanthin} 
                        onKeyPress={handleKeyPress} onChange={(e) => handleGeneral(e, 'fucoxanthin')} placeholder="" className="inputStyle1"  disabled={isAdmin} /></span>
                  </div> */}

              </div>
              <div className="flexAuto">
                <div className="sub-title">Specific Analysis (%)</div>
                <div className="aling-right">
                  <span className="input-title"> Acid Value, mg KOH/g</span>
                  <span className="display-flex-span "><input type="number" id="acid_value_mg_KOH_g" value={specificAnalysis.acid_value_mg_KOH_g}
                    onKeyPress={handleKeyPress} onChange={(e) => handleSpecificAnalysis(e, 'acid_value_mg_KOH_g')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                <div className="aling-right">
                  <span className="input-title">Melting Point, ºC</span>
                  <span className="display-flex-span "><input type="number" id="melting_point_c" value={specificAnalysis.melting_point_c}
                    onKeyPress={handleKeyPress} onChange={(e) => handleSpecificAnalysis(e, 'melting_point_c')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                <div className="aling-right">
                  <span className="input-title">Peroxide Value, meq/kg</span>
                  <span className="display-flex-span "><input type="number" id="peroxide_value_meq_kg" value={specificAnalysis.peroxide_value_meq_kg}
                    onKeyPress={handleKeyPress} onChange={(e) => handleSpecificAnalysis(e, 'peroxide_value_meq_kg')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                <div className="aling-right">
                  <span className="input-title">Iodine</span>
                  <span className="display-flex-span "><input type="number" id="iodine" value={specificAnalysis.iodine}
                    onKeyPress={handleKeyPress} onChange={(e) => handleSpecificAnalysis(e, 'iodine')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>
                <div className="aling-right">
                  <span className="input-title">Saponification Value, mg KOH/g</span>
                  <span className="display-flex-span "><input type="number" id="saponification_value_mg_koh_g" value={specificAnalysis.saponification_value_mg_koh_g}
                    onKeyPress={handleKeyPress} onChange={(e) => handleSpecificAnalysis(e, 'saponification_value_mg_koh_g')} placeholder="" className="inputStyle1" disabled={isAdmin} /></span>
                </div>

              </div>
            </div>
            {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
          </div>
          <div className="paddingTop">
            {isAdmin ? (<div>
              <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome} className="homeButton" /></button>
              <button type="submit" onClick={() => handleRedirect('/postBiomass')} className="loginbuttonStyle" disabled={loading}>NEXT</button>
            </div>) : (<div>
              <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome} className="homeButton" /></button>

              {update && <button type="submit" onClick={() => handleSubmit('/postBiomass')} className="loginbuttonStyle" disabled={loading}>
                {loading ? 'Loading...' : 'Update'}
              </button>
              }
              {update && <button type="button" onClick={() => handleRedirect('/postBiomass')} className="loginbuttonStyle" disabled={loading}>NEXT</button>}

              {!update && <button type="submit" onClick={() => handleSubmit('/postBiomass')} className="loginbuttonStyle" disabled={loading}>
                {loading ? 'Loading...' : 'NEXT'}
              </button>
              }
            </div>)}


          </div>
        </div>
      </div>
    </div>
  );
}

export default UpwellWax;