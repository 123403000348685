import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './AddNewBatch.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { postData, getData, getIdRefreshToken } from '../ApiServices/ApiService';

function LogisticsExtractor() {
  const location = useLocation();
  const batchNo = location.state?.name;
  const update = location.state?.update;
  const viewData = location.state?.view;
  const batchInfo = location.state?.batchInfo;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isDataLoad, setIsDataLoad] = useState(false);

  const [addSupplier, setAddSupplier] = useState('');
  const [addShipper, setAddShipper] = useState('');
  const [addShippedTo, setAddShippedTo] = useState('');
  const [addAir_Sea, setAddAirSea] = useState('');

  const [dataFetched, setDataFetched] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [issupplier, setIssupplier] = useState(false);
  const [supplierOptionList, setSupplierOptionList] = useState([]);
  const [isShipper, setIsShipper] = useState(false);
  const [shipperOptionList, setShipperOptionList] = useState([]);
  const [isShippedTo, setIsShippedTo] = useState(false);
  const [shippedToList, setShippedToList] = useState([]);
  const [addDropdownError, setAddDropdownError] = useState('');
  const [supplierIdError, setSupplierIdError] = useState('');
  const [supplierError, setSupplierError] = useState('');
  const [shipperError, setShipperError] = useState('');
  const [shippedToError, setShippedToError] = useState('');

  const [isAdmin, setisAdmin] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      if (!dataFetched) {
        if (localStorage.getItem('isAdminLogin')) {
          const adminTypeValue = localStorage.getItem('isAdminLogin');
          if (adminTypeValue === 'false') {
            setisAdmin(true);
          } else {
            if (viewData) {
              setisAdmin(true);
            } else {
              setisAdmin(false);
            }
          }
        }
        if (update) {
          console.log('If update', update);
          getExtractorSupplierBatchList();
        } else {
          console.log('els update', update);
        }
      }
      if (!viewData) {
        if (!issupplier) {
          getSupplierList();
        }
        if (!isShipper) {
          getShipperList();
        }
        if (!isShippedTo) {
          getShippedToList();
        }
      }

    } else {
      callLogout();
    }

  }, [dataFetched, update, batchNo, issupplier, isShipper, isShippedTo, viewData]);

  // const options_1 = ['option1', 'option2', 'option3'];
  // const options_2 = ['option1', 'option2', 'option3'];
  // const options_3 = ['option1', 'option2', 'option3'];
  const options_4 = ['Air', 'Sea'];

  const getExtractorSupplierBatchList = () => {
    const getPayload = {
      op: "GET_SUPPLIER_BATCH_SUPPLIER_SFE_TO_EXTRACTOR",
      batchno: batchNo
    }
    setSupplierIdError('');
    getData('logi', JSON.stringify(getPayload), userData.id_token).then(resp => {
      setDataFetched(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setSupplierIdError('');
                setSupplierList(respData.dataJ);
              } else {
                setSupplierIdError(respData.info);
                setSupplierList([]);
              }
            } else {
              setSupplierIdError(respData.info);
              setSupplierList([]);
            }
          } else {
            setSupplierIdError('Error While Getting Supplier Batch List');
            setSupplierList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getExtractorBatchList', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setSupplierIdError('Error Occurred While Loading Supplier Batch List');
          setSupplierList([]);
        }
      }).catch((error) => {
        console.log('network error:', error);
        setSupplierIdError('Network Error While Getting Supplier List');
        setSupplierList([]);
      });
    }).catch((error) => {
      console.log('network error:', error);
      setSupplierIdError('Network Error While Getting Supplier List!');
      setSupplierList([]);
    });
  }

  const getSupplierList = () => {
    const payload = {
      "op": "GET_SUPPLIER_DROPDOWN"
    }
    setSupplierError('');
    getData('logi', JSON.stringify(payload), userData.id_token).then(resp => {
      setIssupplier(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setSupplierError('');
                setSupplierOptionList(respData.dataJ);
              } else {
                setSupplierError(respData.info);
                setSupplierOptionList([]);
              }
            } else {
              setSupplierError(respData.info);
              setSupplierOptionList([]);
            }
          } else {
            setSupplierError('Error While Getting Supplier List');
            setSupplierOptionList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getSupplierList', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setSupplierError('Error Occurred While Loading Supplier List');
          setSupplierOptionList([]);
        }
      }).catch((error) => {
        console.log('network error:', error);
        setSupplierError('Network Error While Loading Supplier List');
        setSupplierOptionList([]);
      });
    }).catch((error) => {
      console.log('network error:', error);
      setSupplierError('Network Error While Loading Supplier List!');
      setSupplierOptionList([]);
    });
  }

  const getShipperList = () => {
    const payload = {
      "op": "GET_SHIPPER_DROPDOWN"
    }
    setShipperError('');
    getData('logi', JSON.stringify(payload), userData.id_token).then(resp => {
      setIsShipper(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setShipperError('');
                setShipperOptionList(respData.dataJ);
              } else {
                setShipperError(respData.info);
                setShipperOptionList([]);
              }
            } else {
              setShipperError(respData.info);
              setShipperOptionList([]);
            }
          } else {
            setShipperError('Error While Getting Shipper List');
            setShipperOptionList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getShipperList', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setShipperError('Error Occurred While Loading Shipper List');
          setShipperOptionList([]);
        }
      }).catch((error) => {
        console.log('network error:', error);
        setShipperError('Network Error While Loading Shipper List');
        setShipperOptionList([]);
      });
    }).catch((error) => {
      console.log('network error:', error);
      setShipperError('Network Error While Loading Shipper List!');
      setShipperOptionList([]);
    });
  }

  const getShippedToList = () => {
    const payload = {
      "op": "GET_SHIPPED_TO_DROPDOWN"
    }
    setShippedToError('');
    getData('logi', JSON.stringify(payload), userData.id_token).then(resp => {
      setIsShippedTo(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setShippedToError('');
                setShippedToList(respData.dataJ);
              } else {
                setShippedToError(respData.info);
                setShippedToList([]);
              }
            } else {
              setShippedToError(respData.info);
              setShippedToList([]);
            }
          } else {
            setShippedToError('Error While Getting Shipped To List');
            setShippedToList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getShippedToList', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setShippedToError('Error Occurred While Loading Shipped To List');
          setShippedToList([]);
        }
      }).catch((error) => {
        console.log('network error:', error);
        setShippedToError('Network Error While Loading Shipped To List');
        setShippedToList([]);
      });
    }).catch((error) => {
      console.log('network error:', error);
      setShippedToError('Network Error While Loading Shipped To List');
      setShippedToList([]);
    });

  }

  const callRefreshTokenApi = (type, path) => {
    const payload = {
      "email": userData.email,
      "oldRefreshToken": userData.refresh_token
    }
    getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
      if (resp.statusCode === 200) {
        if (resp.message.toLowerCase() === 'success') {
          userData.id_token = resp.dataJ.id_token;
          localStorage.setItem('userInfo', JSON.stringify(userData));
          if (type === 'getExtractorBatchList') {
            getExtractorSupplierBatchList();
          } else if (type === 'getSupplierList') {
            getSupplierList();
          } else if (type === 'getShipperList') {
            getShipperList();
          } else if (type === 'getShippedToList') {
            getShippedToList();
          } else if (type === 'handleSaveSupplier') {
            handleSaveSupplier();
          } else if (type === 'handleSaveShipper') {
            handleSaveShipper();
          } else if (type === 'handleSaveShippedTo') {
            handleSaveShippedTo();
          } else if (type === 'insertExtractor') {
            insertExtractor(path);
          } else if (type === 'updateExtractor') {
            updateExtractor(path);
          } else if (type === 'getExtractor') {
            getExtractor(path);
          } else {
            console.log('type', type)
          }
        } else {
          callLogout();
        }
      } else {
        callLogout();
      }
    }).catch((error) => {
      console.log('refresh error', error);
      callLogout();
    });
  }

  const callLogout = () => {
    localStorage.removeItem('isAdminLogin');
    localStorage.removeItem('userInfo');
    localStorage.clear();
    navigate('/');
    window.location.reload();
  }

  const handleAddSupplier = (event) => {
    setAddSupplier(event.target.value);
  };

  const handleSupplierClosePopup = () => {
    document.getElementById('popupid').style.display = 'none';
  };

  const handleSaveSupplier = () => {
    if (addSupplier.trim().length <= 0) {
      setAddDropdownError('Enter Supplier'); return
    }
    const payload = {
      "op": "UPDATE_SUPPLIER_DROPDOWN",
      "suppliers_list": addSupplier
    }
    setLoading(true);
    setAddDropdownError('');
    postData('logi', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setLoading(false);
              setAddDropdownError(respData.info);
              setIssupplier(false);
              setTimeout(() => {
                document.getElementById('popupid').style.display = 'none';
              }, 1000);
            } else {
              setLoading(false);
              setAddDropdownError(respData.info);
            }
          } else {
            setLoading(false);
            setAddDropdownError('Error While Adding Supplier');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('handleSaveSupplier', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setAddDropdownError('Error Occurred While Adding Supplier');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setAddDropdownError('Network Error While Adding Supplier');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setAddDropdownError('Network Error While Adding Supplier!');
    });
  };

  const handleAddShipper = (event) => {
    setAddShipper(event.target.value);
  };

  const handleShipperClosePopup = () => {
    document.getElementById('popupid2').style.display = 'none';
  };

  const handleSaveShipper = () => {
    console.log('id', addShipper);
    if (addShipper.trim().length <= 0) {
      setAddDropdownError('Enter Shipper'); return
    }
    const payload = {
      "op": "UPDATE_SHIPPER_DROPDOWN",
      "suppliers_list": addShipper
    }
    setLoading(true);
    setAddDropdownError('');
    postData('logi', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setLoading(false);
              setAddDropdownError(respData.info);
              setIsShipper(false);
              setTimeout(() => {
                document.getElementById('popupid2').style.display = 'none';
              }, 1000);
            } else {
              setLoading(false);
              setAddDropdownError(respData.info);
            }
          } else {
            setLoading(false);
            setAddDropdownError('Error While Adding Shipper');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('handleSaveShipper', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setAddDropdownError('Error Occurred While Adding Shipper');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setAddDropdownError('Network Error While Adding Shipper');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setAddDropdownError('Network Error While Adding Shipper!');
    });
  };

  const handleAddShippedTo = (event) => {
    setAddShippedTo(event.target.value);
  };

  const handleShippedToClosePopup = () => {
    document.getElementById('popupid3').style.display = 'none';
  };

  const handleSaveShippedTo = () => {
    console.log('id', addShippedTo);
    if (addShippedTo.trim().length <= 0) {
      setAddDropdownError('Enter Shipped To'); return
    }
    const payload = {
      "op": "UPDATE_SHIPPED_TO_DROPDOWN",
      "suppliers_list": addShippedTo
    }
    setLoading(true);
    setAddDropdownError('');
    postData('logi', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setLoading(false);
              setAddDropdownError(respData.info);
              setIsShippedTo(false);
              setTimeout(() => {
                document.getElementById('popupid3').style.display = 'none';
              }, 1000);
            } else {
              setLoading(false);
              setAddDropdownError(respData.info);
            }
          } else if (resp.statusCode === 401 || resp.statusCode === 403) {
            callRefreshTokenApi('handleSaveShippedTo', '');
          } else {
            setLoading(false);
            setAddDropdownError('Error While Adding Shipped To');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('handleSaveShippedTo', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setAddDropdownError('Error Occurred While Adding Shipped To');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setAddDropdownError('Network Error While Adding Shipped To');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setAddDropdownError('Network Error While Adding Shipped To');
    });
  };

  const handleAddAirSea = (event) => {
    setAddAirSea(event.target.value);
  };

  const handleAirSeaClosePopup = () => {
    document.getElementById('popupid4').style.display = 'none';
  };

  const handleSaveAirSea = () => {
    console.log('id', addAir_Sea)
  };

  const handleRedirect = (path) => {
    if (path === '/client') {
      navigate(path, { state: { name: batchNo, update: update, view: viewData, batchInfo: batchInfo } });
    } else {
      navigate(path, { state: { name: batchNo, batchInfo: batchInfo } });
    }
  };

  const [shippingPdfs, setShippingPdfs] = useState({
    shipping_manifest_file_logi_2: '',
    proof_of_delivery_file_logi_2: '',
  });

  const [fileNames, setFileNames] = useState({
    shipping_manifest_file_display_name: '',
    proof_of_delivery_display_name: ''
  });


  const [batchPoIds, setBatchPoIds] = useState({
    supplierBatch: '',
    associatedPo: '',
  });

  const [shippingDates, setShippingDates] = useState({
    shippedDate: '',
    receivedDate: '',
  });

  const [supplierShipperInfo, setSupplierShipperInfo] = useState({
    Supplier: '',
    shipped_to: '',
    shipper: '',
    air_sea: '',
  });

  const handleFileChange = (event, inputFile, inputName) => {
    setError('')
    var validImageFileExtensions = ['.pdf'];
    // setShippingPdfs({ ...shippingPdfs, [inputName]: event.target.files[0] });
    if (event.target.files[0]) {
      if (!validImageFileExtensions.some(el => event.target.files[0].name.toLowerCase().endsWith(el))) {
        setError("File not supported! only PDF files allowed");
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = () => {
        const base64Pdf = reader.result.split(',')[1]; // Get the base64 data
        console.log('base64Pdf', base64Pdf)
        setShippingPdfs({ ...shippingPdfs, [inputFile]: base64Pdf });
      };
    }
    if (event.target.files[0]) {
      setFileNames({ ...fileNames, [inputName]: event.target.files[0].name });
    }
  };
  const handleDropdownChange = (event, dropdownName) => {
    // setSupplierShipperInfo({ ...supplierShipperInfo, [dropdownName]: event.target.value });
    setAddDropdownError('');
    if (event.target.value === 'addSupplier') {
      setAddSupplier('');
      document.getElementById('popupid').style.display = 'flex';
    } else if (event.target.value === 'addShipped') {
      setAddShippedTo('');
      document.getElementById('popupid3').style.display = 'flex';
    } else if (event.target.value === 'addShipper') {
      setAddShipper('');
      document.getElementById('popupid2').style.display = 'flex';
    } else if (event.target.value === 'addAir') {
      document.getElementById('popupid4').style.display = 'flex';
    } else {
      setSupplierShipperInfo({ ...supplierShipperInfo, [dropdownName]: event.target.value });
    }
  };

  // const handleTextChange = (event, inputName) => {
  //   const newValue = event.target.value.replace(/[-.]/g, '');
  //   setBatchPoIds({ ...batchPoIds, [inputName]: newValue });
  // };
  const alphanumericRegex = /^[a-zA-Z0-9]*$/;
  const handleTextChange = (event, inputName) => {
    const newValue = event.target.value.replace(/[-.]/g, '');
    if (alphanumericRegex.test(newValue)) {
      setBatchPoIds({ ...batchPoIds, [inputName]: newValue });
    } else {
      // If it doesn't match, don't update the state (ignore special characters)
    }
  };
  const handleKeyPress = (event) => {
    // Prevent "-" and "." keys from being entered
    if (event.key === '-' || event.key === '.' || event.key === '+' || event.key === 'e') {
      event.preventDefault();
    }
  };

  const handleDateChange = (event, inputName) => {
    setShippingDates({ ...shippingDates, [inputName]: event.target.value });
  };

  const handleSubmit = (path) => {
    if (!batchPoIds.supplierBatch) {
      setError('Supplier Batch is Required');
      return
    }
    if (!batchPoIds.associatedPo) {
      setError('associatedPo is Required');
      return
    }
    if (shippingDates.receivedDate) {
      if (shippingDates.shippedDate > shippingDates.receivedDate) {
        setError('Received date cannot be greater than Shipped date');
        return
      }
    }
    if (update) {
      updateExtractor(path);
    } else {
      insertExtractor(path);
    }
  };

  const insertExtractor = async (path) => {
    const payload = {
      "op": "INSERT_LOGI_SFE_TOLLING_TO_EXTRACTOR",
      "batchno": batchNo,
      "supplierBatch": batchPoIds.supplierBatch,
      "associatedPo": batchPoIds.associatedPo,
      "shipped_to": supplierShipperInfo.shipped_to,
      "air_sea": supplierShipperInfo.air_sea,
      "shippedDate": shippingDates.shippedDate,
      "receivedDate": shippingDates.receivedDate,
      "shipper": supplierShipperInfo.shipper,
      "Supplier": supplierShipperInfo.Supplier,
      "proof_of_delivery_file_logi_2": shippingPdfs.proof_of_delivery_file_logi_2,
      "shipping_manifest_file_logi_2": shippingPdfs.shipping_manifest_file_logi_2,
      "proof_of_delivery_display_name": fileNames.proof_of_delivery_display_name,
      "shipping_manifest_file_display_name": fileNames.shipping_manifest_file_display_name
    }
    setLoading(true);
    setError('');
    await postData('logi', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setError(respData.info);
              // setTimeout(() => {
              setLoading(false);
              navigate(path, { state: { name: batchNo, update: update, view: viewData, batchInfo: batchInfo } });
              // }, 2000);
            } else {
              setLoading(false);
              setError(respData.info);
            }
          } else {
            setLoading(false);
            setError('Error While Adding Logistics Extractor Data');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('insertExtractor', path);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Adding Logistics Extractor Data');
          setLoading(false);
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setError('Network Error While Adding Logistics Extractor Data')
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setError('Network Error While Adding Logistics Extractor Data!')
    });
  }

  const updateExtractor = async (path) => {
    const payload = {
      "op": "UPDATE_LOGI_SFE_TOLLING_TO_EXTRACTOR",
      "batchno": batchNo,
      "supplierBatch": batchPoIds.supplierBatch,
      "associatedPo": batchPoIds.associatedPo,
      "shipped_to": supplierShipperInfo.shipped_to,
      "air_sea": supplierShipperInfo.air_sea,
      "shippedDate": shippingDates.shippedDate,
      "receivedDate": shippingDates.receivedDate,
      "shipper": supplierShipperInfo.shipper,
      "Supplier": supplierShipperInfo.Supplier,
      "proof_of_delivery_file_logi_2": shippingPdfs.proof_of_delivery_file_logi_2,
      "shipping_manifest_file_logi_2": shippingPdfs.shipping_manifest_file_logi_2,
      "proof_of_delivery_display_name": fileNames.proof_of_delivery_display_name,
      "shipping_manifest_file_display_name": fileNames.shipping_manifest_file_display_name
    }
    setLoading(true);
    setError('');
    await postData('logi', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setError(respData.info);
              // setTimeout(() => {
              setLoading(false);
              navigate(path, { state: { name: batchNo, update: update, view: viewData, batchInfo: batchInfo } });
              // }, 2000);
            } else {
              setLoading(false);
              setError(respData.info);
            }
          } else {
            setLoading(false);
            setError('Error While Updating Logistics Extractor Data');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('updateExtractor', path);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setError('Error Occurred While Updating Logistics Extractor Data');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setError('Network Error While Updating Logistics Extractor Data');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setError('Network Error While Updating Logistics Extractor Data!');
    });
  }

  const handleSuplierChange = (event) => {
    console.log('update', event);
    if (!event) {
      extractorDataEmpty(); return;
    }
    getExtractor(event);
  }

  const getExtractor = async (event) => {
    const getPayload = {
      "op": "GET_LOGI_SFE_TOLLING_TO_EXTRACTOR",
      "batchno": batchNo,
      "supplierBatch": event
    }
    setError('');
    setIsDataLoad(true);
    await getData('logi', JSON.stringify(getPayload), userData.id_token).then(resp => {
      // setIsDataLoad(false);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        setIsDataLoad(false);
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ !== undefined) {
                setError('');
                setSupplierShipperInfo((prevSupplierShipperInfo) => ({
                  ...prevSupplierShipperInfo,
                  ...respData.dataJ,
                }));

                setBatchPoIds((prevBatchPoIds) => ({
                  ...prevBatchPoIds,
                  ...respData.dataJ,
                }));

                setShippingDates((prevShippingDates) => ({
                  ...prevShippingDates,
                  ...respData.dataJ,
                }));

                setShippingPdfs((prevShippingPdfs) => ({
                  ...prevShippingPdfs,
                  ...respData.dataJ,
                }));

                setFileNames((prevFileFileNames) => ({
                  ...prevFileFileNames,
                  ...respData.dataJ,
                }));

              } else {
                setError(respData.info);
                extractorDataEmpty();
              }
            } else {
              setError(respData.info);
              extractorDataEmpty();
            }
          } else {
            setError('Error While Getting Logistics Extractor Data');
            extractorDataEmpty();
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getExtractor', event);
            setIsDataLoad(true);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Getting Logistics Extractor Data');
          extractorDataEmpty();
        }
      }).catch((error) => {
        setIsDataLoad(false);
        setError('Network Error While Getting Logistics Extractor Data');
        extractorDataEmpty();
        console.log('network error:', error);
      });
    }).catch((error) => {
      setIsDataLoad(false);
      setError('Network Error While Getting Logistics Extractor Data!');
      extractorDataEmpty();
      console.log('network error:', error);
    });
  }

  const extractorDataEmpty = () => {
    setSupplierShipperInfo({
      Supplier: '',
      shipped_to: '',
      shipper: '',
      air_sea: '',
    });
    setBatchPoIds({
      supplierBatch: '',
      associatedPo: '',
    });
    setShippingDates({
      shippedDate: '',
      receivedDate: '',
    });

    setShippingPdfs({
      shipping_manifest_file_logi_2: '',
      proof_of_delivery_file_logi_2: ''
    });

    setFileNames({
      shipping_manifest_file_display_name: '',
      proof_of_delivery_display_name: ''
    });
  }

  return (
    <div>
      <div className="upwelltitle" style={{ display: 'flex' }}><img src="./upwelllogo.png" alt="upwell" className="logoStyling" />
        <span className="addTitle">Batch details: Logistics SFE tolling to Extractor</span></div>
      <div className="maincontainerbiomass">

        <div className="biomassContainer">
          {/* <div className="addTitle">Batch Details: Raw Biomass</div> */}
          <div className="biomassDiv">
            {/* <form onSubmit={handleSubmit}> */}
            {isDataLoad && (
              <div className="dot-loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            )
            }
            <div className="batchNoStyling">Batch Number: {batchNo} </div>
            {update && (<div className="select-batch-style"> Select the Supplier Batch To Get the Data </div>)}

            {update && (
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Select Supplier batch:</legend>
                  {supplierList.length > 0 ? (
                    <select onChange={(e) => handleSuplierChange(e.target.value)} style={{ border: 'none', outline: 'none', width: '150px' }}>
                      <option value="">Select Supplier</option>
                      {supplierList.map((item, index) => (
                        <option key={index}>
                          {item.supplierBatch}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select onChange={(e) => handleSuplierChange(e.target.value)} style={{ border: 'none', outline: 'none', width: '150px' }}>
                      {supplierIdError === '' && supplierList?.length <= 0 ? (
                        <option value="" selected disabled>Loading...</option>) : (
                        <option value="" selected disabled>No Suppliers</option>)}
                    </select>
                  )}

                </fieldset>
                {supplierIdError && <p className="error">{supplierIdError}</p>}
              </div>
            )}

            <div className="displayFlex marginStyle">
              {/* {!update && ( */}
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Supplier batch #s:</legend>
                  <input type="text" id="supplierBatch" value={batchPoIds.supplierBatch}
                    onChange={(e) => handleTextChange(e, 'supplierBatch')}
                    placeholder="" className="inputStyle" disabled={isAdmin} style={{ border: 'none' }} />
                </fieldset>
              </div>
              {/* )} */}
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Associated PO #:</legend>
                  <input type="text" id="associatedPo" value={batchPoIds.associatedPo}
                    onChange={(e) => handleTextChange(e, 'associatedPo')}
                    placeholder="" className="inputStyle" disabled={isAdmin} style={{ border: 'none' }} />
                </fieldset>
              </div>

            </div>

            {viewData ? (  <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Supplier:</legend>
                  <input type="text" value={supplierShipperInfo.Supplier}
                    onChange={(e) => handleDropdownChange(e, 'Supplier')}
                    placeholder="" className="inputStyle" disabled={isAdmin} style={{ border: 'none' }} />
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Shipped to:</legend>
                  <input type="text" value={supplierShipperInfo.shipped_to}
                    onChange={(e) => handleDropdownChange(e, 'shipped_to')}
                    placeholder="" className="inputStyle" disabled={isAdmin} style={{ border: 'none' }} />
                </fieldset>
              </div>
            </div>):(
                <div className="displayFlex marginStyle">
                <div className="flexAuto">
                  <fieldset>
                    <legend className="scheduler-border">Supplier:</legend>
                    <select className="dropdownStyle" value={supplierShipperInfo.Supplier} onChange={(e) => handleDropdownChange(e, 'Supplier')} disabled={isAdmin}>
                      <option value="">Select an option</option>
                      {supplierOptionList?.map((option, index) => (
                        <option key={index} value={option.suppliers_list}>
                          {option.suppliers_list}
                        </option>
                      ))}
                      <option value="addSupplier" style={{ color: 'blue' }}>Add New Option</option>
                    </select>
                  </fieldset>
                  {supplierError && <p style={{ color: 'red' }}>{supplierError}</p>}
                </div>
                <div className="flexAuto">
                  <fieldset>
                    <legend className="scheduler-border">Shipped to:</legend>
                    <select className="dropdownStyle" value={supplierShipperInfo.shipped_to} onChange={(e) => handleDropdownChange(e, 'shipped_to')} disabled={isAdmin}>
                      <option value="">Select an option</option>
                      {shippedToList?.map((option, index) => (
                        <option key={index} value={option.suppliers_list}>
                          {option.suppliers_list}
                        </option>
                      ))}
                      <option value="addShipped" style={{ color: 'blue' }}>Add New Option</option>
                    </select>
                  </fieldset>
                  {shippedToError && <p style={{ color: 'red' }}>{shippedToError}</p>}
                </div>
              </div>
            )}

            <div className="displayFlex marginStyle">
  
              {viewData ? (<div className="flexAuto">
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Shipper:</legend>
                  <input type="text" value={supplierShipperInfo.shipper}
                    onChange={(e) => handleDropdownChange(e, 'shipper')}
                    placeholder="" className="inputStyle" style={{ border: 'none' }} disabled={isAdmin} />
                </fieldset>
              </div>
              </div>):(
                  <div className="flexAuto">
                  <fieldset>
                    <legend className="scheduler-border">Shipper:</legend>
                    <select className="dropdownStyle" value={supplierShipperInfo.shipper} onChange={(e) => handleDropdownChange(e, 'shipper')} disabled={isAdmin}>
                      <option value="">Select an option</option>
                      {shipperOptionList?.map((option, index) => (
                        <option key={index} value={option.suppliers_list}>
                          {option.suppliers_list}
                        </option>
                      ))}
                      <option value="addShipper" style={{ color: 'blue' }}>Add New Option</option>
                    </select>
                  </fieldset>
                  {shipperError && <p style={{ color: 'red' }}>{shipperError}</p>}
                </div>
              )}
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Air/Sea:</legend>
                  <select className="dropdownStyle" value={supplierShipperInfo.air_sea} onChange={(e) => handleDropdownChange(e, 'air_sea')} disabled={isAdmin}>
                    <option value="">Select an option</option>
                    {options_4.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                    {/* <option value="addSupplier" style={{color:'blue'}}>Add New Option</option> */}
                  </select>
                </fieldset>
              </div>
            </div>

            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Shipped:</legend>
                  <input type="date" id="shipped" max={new Date().toISOString().split('T')[0]} value={shippingDates.shippedDate}
                    onChange={(e) => handleDateChange(e, 'shippedDate')} className="inputStyle" disabled={isAdmin} />
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Shipping manifest:</legend>
                  <div className="file-input-container">
                    <label htmlFor="file-input4" className="custom-file-label">
                      {fileNames.shipping_manifest_file_display_name ? fileNames.shipping_manifest_file_display_name : 'Choose File'}
                    </label>
                    <input
                      type="file"
                      id="file-input4" accept=".pdf"
                      onChange={(e) => handleFileChange(e, 'shipping_manifest_file_logi_2', 'shipping_manifest_file_display_name')}
                      style={{ display: 'none' }} disabled={isAdmin}
                    />
                  </div>
                </fieldset>
              </div>

            </div>

            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Received: </legend>
                  <input type="date" id="received" max={new Date().toISOString().split('T')[0]} value={shippingDates.receivedDate}
                    onChange={(e) => handleDateChange(e, 'receivedDate')} className="inputStyle" disabled={isAdmin} />
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Proof of delivery:</legend>
                  <div className="file-input-container">
                    <label htmlFor="file-input5" className="custom-file-label">
                      {fileNames.proof_of_delivery_display_name ? fileNames.proof_of_delivery_display_name : 'Choose File'}
                    </label>
                    <input
                      type="file"
                      id="file-input5" accept=".pdf"
                      onChange={(e) => handleFileChange(e, 'proof_of_delivery_file_logi_2', 'proof_of_delivery_display_name')}
                      style={{ display: 'none' }} disabled={isAdmin}
                    />
                  </div>
                </fieldset>
              </div>

            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </div>
          <div className="paddingTop">
            {isAdmin ? (<div>
              <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome} className="homeButton" /></button>
              <button type="submit" onClick={() => handleRedirect('/client')} className="loginbuttonStyle" disabled={loading}>NEXT</button>
            </div>) : (<div>
              <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome} className="homeButton" /></button>
              {update ? (
                <button type="submit" onClick={() => handleSubmit('/client')} className="loginbuttonStyle" disabled={loading}>{loading ? 'Loading...' : 'UPDATE'}</button>
              ) : (<button type="submit" onClick={() => handleSubmit('/client')} className="loginbuttonStyle" disabled={loading}>{loading ? 'Loading...' : 'NEXT'}</button>)}

              {update && <button type="button" onClick={() => handleRedirect('/client')} className="loginbuttonStyle" disabled={loading}>NEXT</button>}

            </div>)}

          </div>
        </div>

      </div>


      <div id="popupid" className="popup">
        <div className="popup-content">
          <h2>Add Supplier</h2>
          <div className="marginStyle">
            <fieldset>
              <legend className="scheduler-border">Supplier:</legend>
              <input type="text" id="addSupplier" value={addSupplier}
                onChange={(e) => handleAddSupplier(e, 'addSupplier')}
                placeholder="Enter Supplier" className="inputStyle" disabled={isAdmin} style={{ border: 'none' }} />
            </fieldset>
          </div>
          {addDropdownError && <p style={{ color: 'red' }}>{addDropdownError}</p>}
          <div className="popup-btns-flex">
            <button onClick={() => handleSupplierClosePopup()} className="popup-btns">Close</button >
            <button onClick={() => handleSaveSupplier()} disabled={loading} className="popup-btns">{loading ? 'Loading...' : 'Save'}</button>
          </div>
        </div>
      </div>

      <div id="popupid2" className="popup2">
        <div className="popup-content">
          <h2>Add Shipper</h2>
          <div className="marginStyle">
            <fieldset>
              <legend className="scheduler-border">Shipper:</legend>
              <input type="text" id="addShipper" value={addShipper}
                onChange={(e) => handleAddShipper(e, 'addShipper')}
                placeholder="Enter Shipper" className="inputStyle" disabled={isAdmin} style={{ border: 'none' }} />
            </fieldset>
          </div>
          {addDropdownError && <p style={{ color: 'red' }}>{addDropdownError}</p>}
          <div className="popup-btns-flex">
            <button onClick={() => handleShipperClosePopup()} className="popup-btns">Close</button >
            <button onClick={() => handleSaveShipper()} disabled={loading} className="popup-btns">{loading ? 'Loading...' : 'Save'}</button>
          </div>
        </div>
      </div>

      <div id="popupid3" className="popup3">
        <div className="popup-content">
          <h2>Add Shipped To</h2>
          <div className="marginStyle">
            <fieldset>
              <legend className="scheduler-border">Shipped to:</legend>
              <input type="text" id="addShippedTo" value={addShippedTo}
                onChange={(e) => handleAddShippedTo(e, 'addShippedTo')}
                placeholder="Enter Shipped To" className="inputStyle" disabled={isAdmin} style={{ border: 'none' }} />
            </fieldset>
          </div>
          {addDropdownError && <p style={{ color: 'red' }}>{addDropdownError}</p>}
          <div className="popup-btns-flex">
            <button onClick={() => handleShippedToClosePopup()} className="popup-btns">Close</button >
            <button onClick={() => handleSaveShippedTo()} disabled={loading} className="popup-btns">{loading ? 'Loading...' : 'Save'}</button>
          </div>
        </div>
      </div>

      <div id="popupid4" className="popup4">
        <div className="popup-content">
          <h2>Add Air/Sea</h2>
          <div className="marginStyle">
            <fieldset>
              <legend className="scheduler-border">Air/Sea:</legend>
              <input type="text" id="addAir_Sea" value={addAir_Sea}
                onChange={(e) => handleAddAirSea(e, 'addAir_Sea')}
                placeholder="Enter Supplier" className="inputStyle" disabled={isAdmin} style={{ border: 'none' }} />
            </fieldset>
          </div>
          <div className="popup-btns-flex">
            <button onClick={() => handleAirSeaClosePopup()} className="popup-btns">Close</button >
            <button onClick={() => handleSaveAirSea()} disabled={loading} className="popup-btns">{loading ? 'Loading...' : 'Save'}</button>
          </div>
        </div>
      </div>

    </div>
  );
}

export default LogisticsExtractor;