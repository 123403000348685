import environment from '../Environment/environment'; // Path to your env.js file

// const token = JSON.parse(localStorage.getItem('userInfo'));

async function postData(endpoint, data, idToken) {
  try {
    const response = await fetch(`${environment.apiUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'tkn': idToken
      },
      body: JSON.stringify(data),
      // body: data,
    });

    // const responseData = await response.json();
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
  }
}

async function postDataLogin(endpoint, data) {
  try {
    const response = await fetch(`${environment.apiUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      // body: data,
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error posting data:', error);
  }
}


async function getData(endpoint, data, idToken) {
  // console.log('token', idToken)
  // console.log('tokenID',token.id_token)
  try {
    const response = await fetch(`${environment.apiUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'tkn': idToken
      },
      body: data,
    });
    // const responseData = await response.json();
    // console.log('getData responseData : ', responseData);
    // console.log('getData response @@@: ', response);
    // console.log('getData status @@@: ', response.status);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function getIdRefreshToken(endpoint, payload) {
  try {
    // const payload = {
    //   "email":email,
    //   "oldRefreshToken":refreshToken
    // }
    const response = await fetch(`${environment.apiUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify(payload),
      body: payload,
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export { postData, getData, postDataLogin, getIdRefreshToken };
