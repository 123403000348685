import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './AddNewBatch.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { postData, getData, getIdRefreshToken } from "../ApiServices/ApiService";

function AqueousExtraction() {
  const location = useLocation();
  const batchNo = location.state?.name;
  const update = location.state?.update;
  const viewData = location.state?.view;
  const batchInfo = location.state?.batchInfo;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [addTolling, setAddTolling] = useState('');
  const [isDataLoad, setIsDataLoad] = useState(false);

  const [dataFetched, setDataFetched] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [isTollingFacility, setIsTollingFacility] = useState(false);
  const [tollingFacilityList, setTollingFacilityList] = useState([]);
  const [tollingFacilityError, setTollingFacilityError] = useState('');
  const [supplierIdError, setSupplierIdError] = useState('');

  const [isAdmin, setisAdmin] = useState(false);
  const [addDropdownError, setAddDropdownError] = useState('');
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      if (!dataFetched) {
        if (localStorage.getItem('isAdminLogin')) {
          const adminTypeValue = localStorage.getItem('isAdminLogin');
          if (adminTypeValue === 'false') {
            setisAdmin(true);
          } else {
            if (viewData) {
              setisAdmin(true);
            } else {
              setisAdmin(false);
            }
          }
        }
        if (update) {
          console.log('If update', update);
          getAquaExtractionSupplierList();
        } else {
          console.log('els update', update);
        }
      }
      if (!viewData) {
        if (!isTollingFacility) {
          getTollingFacilityList();
        }
      }
    } else {
      callLogout();
    }
  }, [dataFetched, update, batchNo, isTollingFacility, viewData]);

  const getAquaExtractionSupplierList = () => {
    const getPayload = {
      "op": "GET_SUPPLIER_BATCH_AQA_EXT",
      "batchno": batchNo
    }
    setSupplierIdError('');
    getData('test2', JSON.stringify(getPayload), userData.id_token).then(resp => {
      setDataFetched(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setSupplierIdError('');
                setSupplierList(respData.dataJ);
              } else {
                setSupplierIdError(respData.info);
                setSupplierList([]);
              }
            } else {
              setSupplierIdError(respData.info);
              setSupplierList([]);
            }
          } else {
            setSupplierIdError('Error While getting Aqueous Extraction Supplier Batch List');
            setSupplierList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getAqueousBatch', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setSupplierIdError('Error Occurred While Loading Aqueous Extraction Supplier Batch List');
          setSupplierList([]);
        }
      }).catch((error) => {
        console.log('network error:', error);
        setSupplierIdError('Network Error While getting Aqueous Extraction Supplier Batch List');
        setSupplierList([]);
      });
    }).catch((error) => {
      console.log('network error:', error);
      setSupplierIdError('Network Error While getting Aqueous Extraction Supplier Batch List!');
      setSupplierList([]);
    });
  }

  const getTollingFacilityList = () => {
    const payload = {
      "op": "GET_TOLLING_FACILITY_DROPDOWN"
    }
    setTollingFacilityError('');
    getData('test2', JSON.stringify(payload), userData.id_token).then(resp => {
      setIsTollingFacility(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setTollingFacilityError('');
                setTollingFacilityList(respData.dataJ);
              } else {
                setTollingFacilityError(respData.info);
                setTollingFacilityList([]);
              }
            } else {
              setTollingFacilityError(respData.info);
              setTollingFacilityList([]);
            }
          } else {
            setTollingFacilityError('Error While Getting Tolling Facility List');
            setTollingFacilityList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getTollingFacility', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setTollingFacilityError('Error Occurred While Loading Tolling Facility List');
          setTollingFacilityList([]);
        }
      }).catch((error) => {
        setError(error);
        console.log('network error:', error);
        setTollingFacilityError('Network Error While Getting Tolling Facility List');
        setTollingFacilityList([]);
      });
    }).catch((error) => {
      setError(error);
      console.log('network error:', error);
      setTollingFacilityError('Network Error While Getting Tolling Facility List!');
      setTollingFacilityList([]);
    });
  }

  const callRefreshTokenApi = (type, event) => {
    const payload = {
      "email": userData.email,
      "oldRefreshToken": userData.refresh_token
    }
    getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
      if (resp.statusCode === 200) {
        if (resp.message.toLowerCase() === 'success') {
          userData.id_token = resp.dataJ.id_token;
          localStorage.setItem('userInfo', JSON.stringify(userData));
          if (type === 'getAqueousBatch') {
            getAquaExtractionSupplierList();
          } else if (type === 'getTollingFacility') {
            getTollingFacilityList();
          } else if (type === 'getAqueous') {
            getAqueousExtractionDetails(event);
          } else if (type === 'insertAqueous') {
            insertAqueousExtractionData(event);
          } else if (type === 'updateAqueous') {
            updateAqueousExtractionData(event);
          } else if (type === 'updateTolling') {
            handleSaveTolling();
          } else {
            console.log('type', type)
          }
        } else {
          callLogout();
        }
      } else {
        callLogout();
      }
    }).catch((error) => {
      console.log('refresh error', error);
      callLogout();
    });
  }

  const callLogout = () => {
    localStorage.removeItem('isAdminLogin');
    localStorage.removeItem('userInfo');
    localStorage.clear();
    navigate('/');
    window.location.reload();
  }

  // const options = ['option1', 'option2', 'option3'];
  const handleTolling = (event) => {
    setAddTolling(event.target.value);
  };

  const handleClosePopup = () => {
    document.getElementById('popupid').style.display = 'none';
  };

  const handleSaveTolling = () => {
    console.log('id', addTolling);
    if (addTolling.trim().length <= 0) {
      setAddDropdownError('Enter Tolling Facility'); return
    }
    const payload = {
      "op": "UPDATE_TOLLING_FACILITY_DROPDOWN",
      "tolling_facility": addTolling
    }
    setLoading(true);
    setAddDropdownError('');
    postData('test2', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setLoading(false);
              setAddDropdownError(respData.info);
              setIsTollingFacility(false);
              setTimeout(() => {
                document.getElementById('popupid').style.display = 'none';
              }, 1000);
            } else {
              setLoading(false);
              setAddDropdownError(respData.info);
            }
          } else {
            setLoading(false);
            setAddDropdownError('Error While Adding Tolling Facility');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('updateTolling', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setAddDropdownError('Error Occurred While Adding Tolling Facility');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setAddDropdownError('Network Error While Adding Tolling Facility');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setAddDropdownError('Network Error While Adding Tolling Facility!');
    });
  };

  const handleRedirect = (path) => {
    if (path === '/hydero') {
      if (!batchPoIds.supplierBatch) {
        setError('supplier ID is required');
        return
      }
      navigate(path, { state: { name: batchNo, batchId: batchPoIds.supplierBatch, name2: fileNames, update: update, view: viewData, batchInfo: batchInfo } });
    } else {
      navigate(path, { state: { name: batchNo, update: update, view: viewData, batchInfo: batchInfo, batchInfo: batchInfo } });
    }
  };

  const [upwellReport, setUpwellReport] = useState({
    upwell_coa_files_1_aq: '',
    upwell_coa_files_2_aq: '',
    extraction_report_file: '',
  });

  const [fileNames, setFileNames] = useState({
    extraction_report_file_display_name: '',
    upwell_coa_files_1_aq_display_name: '',
    upwell_coa_files_2_aq_display_name: ''
  });

  const [upwellInfo, setUpwellInfo] = useState({
    remaining_biomass: '',
    upwell_hydro: '',
    material_extracted: ''
  });

  const [batchPoIds, setBatchPoIds] = useState({
    supplierBatch: '',
    associatedPo: '',
  });

  const [shippingDates, setShippingDates] = useState({
    shippedDate: '',
    receivedDate: '',
  });

  const [tolling_facility, setTollingFacility] = useState('');


  const handleUpwellReport = (event, inputName, fileName) => {
    setError('')
    var validImageFileExtensions = ['.pdf'];

    if (event.target.files[0]) {
      if (!validImageFileExtensions.some(el => event.target.files[0].name.toLowerCase().endsWith(el))) {
        setError("File not supported! only PDF files allowed");
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = () => {
        const base64Pdf = reader.result.split(',')[1]; // Get the base64 data
        console.log('base64Pdf', base64Pdf)
        setUpwellReport({ ...upwellReport, [inputName]: base64Pdf });
      };
    }
    if (event.target.files[0]) {
      setFileNames({ ...fileNames, [fileName]: event.target.files[0].name });
    }


  };

  const handleUpwellInfo = (event, inputName) => {
    setUpwellInfo({ ...upwellInfo, [inputName]: event.target.value });
  };

  // const handleTextChange = (event, inputName) => {
  //   setBatchPoIds({ ...batchPoIds, [inputName]: event.target.value });
  // };
  const alphanumericRegex = /^[a-zA-Z0-9]*$/;
  const handleTextChange = (event, inputName) => {
    const newValue = event.target.value.replace(/[-.]/g, '');
    if (alphanumericRegex.test(newValue)) {
      setBatchPoIds({ ...batchPoIds, [inputName]: newValue });
    } else {
      // If it doesn't match, don't update the state (ignore special characters)
    }
  };
  const handleDateChange = (event, inputName) => {
    setShippingDates({ ...shippingDates, [inputName]: event.target.value });
  };

  const handleSelectChange = (event) => {
    // setTollingFacility(event.target.value);
    setAddDropdownError('');
    if (event.target.value === 'add') {
      setAddTolling('');
      document.getElementById('popupid').style.display = 'flex';
    } else {
      setTollingFacility(event.target.value);
    }
  };

  const handleKeyPress = (event) => {
    // Prevent "-" and "." keys from being entered
    if (event.key === '-' || event.key === '.' || event.key === '+' || event.key === 'e') {
      event.preventDefault();
    }
  };

  const handleSubmit = (path) => {
    if (!batchPoIds.supplierBatch) {
      setError('Supplier Batch is Required');
      return
    }
    if (!batchPoIds.associatedPo) {
      setError('AssociatedPo is Required');
      return
    }
    if (shippingDates.receivedDate) {
      if (shippingDates.shippedDate > shippingDates.receivedDate) {
        setError('Received date cannot be greater than Shipped date');
        return
      }
    }
    const extractionData = {
      batchNo,
      batchPoIds,
      upwellInfo,
      shippingDates,
      tolling_facility
    }
    console.log('data', extractionData);
    if (update) {
      updateAqueousExtractionData(path);
    } else {
      insertAqueousExtractionData(path);
    }
  };

  const insertAqueousExtractionData = async (path) => {
    const payload = {
      "op": "INSERT_AQUEOUS_EXTRACTION",
      "batchno": batchNo,
      "supplierBatch": batchPoIds.supplierBatch,
      "tolling_facility": tolling_facility,
      "shippedDate": shippingDates.shippedDate,
      "receivedDate": shippingDates.receivedDate,
      "associatedPo": batchPoIds.associatedPo,
      "material_extracted": upwellInfo.material_extracted,
      "upwell_hydro": upwellInfo.upwell_hydro,
      "remaining_biomass": upwellInfo.remaining_biomass,
      "extraction_report_file": upwellReport.extraction_report_file,
      "upwell_coa_files_1_aq": upwellReport.upwell_coa_files_1_aq,
      "upwell_coa_files_2_aq": upwellReport.upwell_coa_files_2_aq,
      "extraction_report_file_display_name": fileNames.extraction_report_file_display_name,
      "upwell_coa_files_1_aq_display_name": fileNames.upwell_coa_files_1_aq_display_name,
      "upwell_coa_files_2_aq_display_name": fileNames.upwell_coa_files_2_aq_display_name
    }
    setLoading(true);
    setError('');
    await postData('test2', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setError(respData.info);
              // setTimeout(() => {
              setLoading(false);
              // navigate(path, { state: { name: batchNo,update: update,view:viewData } });  
              navigate(path, { state: { name: batchNo, batchId: batchPoIds.supplierBatch, name2: fileNames, update: update, view: viewData, batchInfo: batchInfo } });
              // }, 2000);
            } else {
              setLoading(false);
              setError(respData.info);
            }
          } else {
            setLoading(false);
            setError('Error While Adding Aqueous Extraction Data');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('insertAqueous', path);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Adding Aqueous Extraction Data');
          setLoading(false);
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setError('Network Error While Adding Aqueous Extraction Data');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setError('Network Error While Adding Aqueous Extraction Data!');
    });
  }

  const updateAqueousExtractionData = async (path) => {
    const payload = {
      "op": "UPDATE_AQUEOUS_EXTRACTION",
      "batchno": batchNo,
      "supplierBatch": batchPoIds.supplierBatch,
      "tolling_facility": tolling_facility,
      "shippedDate": shippingDates.shippedDate,
      "receivedDate": shippingDates.receivedDate,
      "associatedPo": batchPoIds.associatedPo,
      "material_extracted": upwellInfo.material_extracted,
      "upwell_hydro": upwellInfo.upwell_hydro,
      "remaining_biomass": upwellInfo.remaining_biomass,
      "extraction_report_file": upwellReport.extraction_report_file,
      "upwell_coa_files_1_aq": upwellReport.upwell_coa_files_1_aq,
      "upwell_coa_files_2_aq": upwellReport.upwell_coa_files_2_aq,
      "extraction_report_file_display_name": fileNames.extraction_report_file_display_name,
      "upwell_coa_files_1_aq_display_name": fileNames.upwell_coa_files_1_aq_display_name,
      "upwell_coa_files_2_aq_display_name": fileNames.upwell_coa_files_2_aq_display_name
    }
    setLoading(true);
    setError('');
    await postData('test2', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setError(respData.info);
              // setTimeout(() => {
              setLoading(false);
              // navigate(path, { state: { name: batchNo,update: update,view:viewData } });     
              navigate(path, { state: { name: batchNo, batchId: batchPoIds.supplierBatch, name2: fileNames, update: update, view: viewData, batchInfo: batchInfo } });
              // }, 2000);
            } else {
              setLoading(false);
              setError(respData.info);
            }
          } else {
            setLoading(false);
            setError('Error While Updating Aqueous Extraction Data');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('updateAqueous', path);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setError('Error Occurred While Updating Aqueous Extraction Data');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setError('Network Error While Updating Aqueous Extraction Data');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setError('Network Error While Updating Aqueous Extraction Data!');
    });
  }

  const handleSuplierChange = (event) => {
    console.log('update', event);
    getAqueousExtractionDetails(event);
  }

  const getAqueousExtractionDetails = async (event) => {
    const getPayload = {
      "op": "GET_AQUEOUS_EXTRACTION",
      "batchno": batchNo,
      "supplierBatch": event
    }
    setError('');
    setIsDataLoad(true);
    await getData('test2', JSON.stringify(getPayload), userData.id_token).then(resp => {
      // setIsDataLoad(false);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        setIsDataLoad(false);
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ !== undefined) {
                setError('');
                setTollingFacility(respData.dataJ.tolling_facility);
                setUpwellInfo((prevUpwellInfo) => ({
                  ...prevUpwellInfo,
                  ...respData.dataJ,
                }));

                setBatchPoIds((prevBatchPoIds) => ({
                  ...prevBatchPoIds,
                  ...respData.dataJ,
                }));

                setShippingDates((prevShippingDates) => ({
                  ...prevShippingDates,
                  ...respData.dataJ,
                }));

                setUpwellReport((prevUpwellReport) => ({
                  ...prevUpwellReport,
                  ...respData.dataJ,
                }));

                setFileNames((prevFileNames) => ({
                  ...prevFileNames,
                  ...respData.dataJ,
                }));
              } else {
                setError(respData.info);
                aqueousExtractionDetailsEmpty();
              }
            } else {
              setError(respData.info);
              aqueousExtractionDetailsEmpty();
            }
          } else {
            setError('Error While Getting Aqueous Extraction Details');
            aqueousExtractionDetailsEmpty();
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getAqueous', event);
            setIsDataLoad(true);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Getting Aqueous Extraction Details');
          aqueousExtractionDetailsEmpty();
        }
      }).catch((error) => {
        setIsDataLoad(false)
        console.log('network error:', error);
        setError('Error While Getting Aqueous Extraction Details');
        aqueousExtractionDetailsEmpty();
      });
    }).catch((error) => {
      setIsDataLoad(false)
      console.log('network error:', error);
      setError('Error While Getting Aqueous Extraction Details');
      aqueousExtractionDetailsEmpty();
    });
  }

  const aqueousExtractionDetailsEmpty = () => {
    setTollingFacility('');
    setUpwellInfo({
      remaining_biomass: '',
      upwell_hydro: '',
      material_extracted: ''
    });
    setBatchPoIds({
      supplierBatch: '',
      associatedPo: '',
    });
    setShippingDates({
      shippedDate: '',
      receivedDate: '',
    });

    setUpwellReport({
      upwell_coa_files_1_aq: '',
      upwell_coa_files_2_aq: '',
      extraction_report_file: '',
    });

    setFileNames({
      extraction_report_file_display_name: '',
      upwell_coa_files_1_aq_display_name: '',
      upwell_coa_files_2_aq_display_name: ''
    });
  }

  return (
    <div>
      <div className="upwelltitle" style={{ display: 'flex' }}><img src="./upwelllogo.png" alt="upwell" className="logoStyling" />
        <span className="addTitle">Batch details: Aqueous extraction</span></div>
      <div className="maincontainerbiomass">

        <div className="extractionContainer">
          {/* <div className="addTitle">Batch Details: Raw Biomass</div> */}
          <div className="sfe-extraction-Div">
            {/* <form onSubmit={handleSubmit}> */}
            {isDataLoad && (
              <div className="dot-loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            )
            }
            <div className="batchNoStyling">Batch Number: {batchNo} </div>
            {update && (<div className="select-batch-style"> Select the Supplier Batch To Get the Data </div>)}


            <div className="displayFlex marginStyle">
              {update && (
                <div className="flexAuto">
                  <fieldset>
                    <legend className="scheduler-border">Select Supplier batch:</legend>
                    {supplierList?.length > 0 ? (
                      <select onChange={(e) => handleSuplierChange(e.target.value)} style={{ border: 'none', outline: 'none', width: '150px' }}>
                        <option value="">Select Supplier</option>
                        {supplierList?.map((item, index) => (
                          <option key={index}>
                            {item.supplierBatch}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select onChange={(e) => handleSuplierChange(e.target.value)} style={{ border: 'none', outline: 'none', width: '150px' }}>
                        {supplierIdError === '' && supplierList?.length <= 0 ? (
                          <option value="" selected disabled>Loading...</option>) : (
                          <option value="" selected disabled>No Suppliers</option>)}
                      </select>
                    )}

                  </fieldset>
                  {supplierIdError && <p className="error">{supplierIdError}</p>}
                </div>
              )}
              {viewData ? (<div className="flexAuto">
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Tolling facility:</legend>
                  <input type="text" value={tolling_facility}
                    onChange={() => handleSelectChange}
                    placeholder="" className="inputStyle" style={{ border: 'none' }} disabled={isAdmin} />
                </fieldset>
              </div>
              </div>):(<div className="flexAuto">
              <fieldset  >
                  <legend className="scheduler-border">Tolling facility:</legend>
                  <select className="dropdownStyle" value={tolling_facility} onChange={handleSelectChange} disabled={isAdmin}>
                    <option value="">Select an option</option>
                    {tollingFacilityList?.map((option, index) => (
                      <option key={index} value={option.tolling_facility}>
                        {option.tolling_facility}
                      </option>
                    ))}
                    <option value="add" style={{ color: 'blue' }}>Add New Option</option>
                  </select>
                </fieldset>
                {tollingFacilityError && <p className="error">{tollingFacilityError}</p>}
              </div>) }

              <div className="flexAuto">
              </div>

            </div>



            <div className="displayFlex marginStyle">
              {/* {!update && ( */}
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Supplier batch #:</legend>
                  <input type="text" id="supplierBatch" value={batchPoIds.supplierBatch}
                    onChange={(e) => handleTextChange(e, 'supplierBatch')}
                    placeholder="" className="inputStyle" style={{ border: 'none' }} disabled={isAdmin} />
                </fieldset>
              </div>
              {/* )} */}

              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Material extracted:</legend>
                  <input type="number" id="material_extracted" value={upwellInfo.material_extracted}
                    onChange={(e) => handleUpwellInfo(e, 'material_extracted')} onKeyPress={handleKeyPress}
                    placeholder="" className="inputStyle" disabled={isAdmin} style={{ border: 'none', width: '90%' }} /><span>kg</span>
                </fieldset>
              </div>
              <div className="flexAuto">
              </div>
            </div>
            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Associated PO #:</legend>
                  <input type="text" id="associatedPo" value={batchPoIds.associatedPo}
                    onChange={(e) => handleTextChange(e, 'associatedPo')}
                    placeholder="" className="inputStyle" disabled={isAdmin} style={{ border: 'none' }} />
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Upwell hydro:</legend>
                  <input type="number" id="upwell_hydro" value={upwellInfo.upwell_hydro}
                    onChange={(e) => handleUpwellInfo(e, 'upwell_hydro')} onKeyPress={handleKeyPress}
                    placeholder="" className="inputStyle" style={{ border: 'none', width: '90%' }} disabled={isAdmin} /><span>kg</span>
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">UpwellCoA</legend>
                  <div className="file-input-container">
                    <label htmlFor="upwell_coa_files_1_aq" className="custom-file-label">
                      {fileNames.upwell_coa_files_1_aq_display_name ? fileNames.upwell_coa_files_1_aq_display_name : 'Choose File'}
                    </label>
                    <input
                      type="file"
                      id="upwell_coa_files_1_aq" accept=".pdf"
                      onChange={(e) => handleUpwellReport(e, 'upwell_coa_files_1_aq', 'upwell_coa_files_1_aq_display_name')}
                      style={{ display: 'none' }} disabled={isAdmin}
                    />
                  </div>
                </fieldset>
              </div>


            </div>
            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Start date:</legend>
                  <input type="date" id="shipped" max={new Date().toISOString().split('T')[0]} value={shippingDates.shippedDate}
                    onChange={(e) => handleDateChange(e, 'shippedDate')} className="inputStyle" disabled={isAdmin} />
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Remaining biomass:</legend>
                  <input type="number" id="remaining_biomass" value={upwellInfo.remaining_biomass}
                    onChange={(e) => handleUpwellInfo(e, 'remaining_biomass')} onKeyPress={handleKeyPress}
                    placeholder="" className="inputStyle" style={{ border: 'none', width: '90%' }} disabled={isAdmin} /><span>kg</span>
                </fieldset>
              </div>

              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">UpwellCoA</legend>
                  <div className="file-input-container">
                    <label htmlFor="upwell_coa_files_2_aq" className="custom-file-label">
                      {fileNames.upwell_coa_files_2_aq_display_name ? fileNames.upwell_coa_files_2_aq_display_name : 'Choose File'}
                    </label>
                    <input
                      type="file"
                      id="upwell_coa_files_2_aq" accept=".pdf"
                      onChange={(e) => handleUpwellReport(e, 'upwell_coa_files_2_aq', 'upwell_coa_files_2_aq_display_name')}
                      style={{ display: 'none' }} disabled={isAdmin}
                    />
                  </div>
                </fieldset>
              </div>

            </div>

            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">End date: </legend>
                  <input type="date" id="received" max={new Date().toISOString().split('T')[0]} value={shippingDates.receivedDate}
                    onChange={(e) => handleDateChange(e, 'receivedDate')} className="inputStyle" disabled={isAdmin} />
                </fieldset>
              </div>


            </div>

            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset  >
                  <legend className="scheduler-border">Extraction report:</legend>
                  <div className="file-input-container">
                    <label htmlFor="extraction_report_file" className="custom-file-label">
                      {fileNames.extraction_report_file_display_name ? fileNames.extraction_report_file_display_name : 'Choose File'}
                    </label>
                    <input
                      type="file"
                      id="extraction_report_file" accept=".pdf"
                      onChange={(e) => handleUpwellReport(e, 'extraction_report_file', 'extraction_report_file_display_name')}
                      style={{ display: 'none' }} disabled={isAdmin}
                    />
                  </div>
                </fieldset>
              </div>

            </div>

            {error && <p style={{ color: 'red' }}>{error}</p>}

          </div>
          <div className="paddingTop">
            {/* {isAdmin?(<div>
              <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome}  className="homeButton"/></button>
              <button type="button" onClick={() => handleRedirect('/extraction')} className="loginbuttonStyle" disabled={loading}>View Extraction</button>
              <button type="button" onClick={() => handleRedirect('/viewBatch')} className="loginbuttonStyle" disabled={loading}>Batch</button>
              <button type="button" onClick={() => handleRedirect('/logistics')} className="loginbuttonStyle" disabled={loading}>LOGISTICS</button>
            </div>):(<div>
              <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome}  className="homeButton"/></button>

              <button type="button" onClick={() => handleRedirect('/logistics')} className="loginbuttonStyle" disabled={loading}>LOGISTICS</button>
              <button type="button" onClick={() => handleRedirect('/extraction')} className="loginbuttonStyle" disabled={loading}>Add Extraction</button>
              {update ?(
              <button type="button" onClick={() => handleRedirect('/update')} className="loginbuttonStyle" disabled={loading}>Batch</button>):(
                
              <button type="button" onClick={() => handleRedirect('/add')} className="loginbuttonStyle" disabled={loading}>Batch</button>
              )}

              {update ?(
                <button type="submit" onClick={() => handleSubmit()} className="loginbuttonStyle" disabled={loading}>{loading ? 'Loading...' : 'UPDATE'}</button>
                ):( <button type="submit" onClick={() => handleSubmit()} className="loginbuttonStyle" disabled={loading}>{loading ? 'Loading...' : 'SAVE'}</button>)}

            </div>)} */}
            {isAdmin ? (<div>
              <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome} className="homeButton" /></button>
              {/* <button type="button" onClick={() => handleRedirect('/extraction')} className="loginbuttonStyle" disabled={loading}>View Extraction</button>
              <button type="button" onClick={() => handleRedirect('/viewBatch')} className="loginbuttonStyle" disabled={loading}>Batch</button>
              <button type="button" onClick={() => handleRedirect('/logistics')} className="loginbuttonStyle" disabled={loading}>LOGISTICS</button> */}
              <button type="button" onClick={() => handleRedirect('/hydero')} className="loginbuttonStyle" disabled={loading}>NEXT</button>
            </div>) : (<div>
              <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome} className="homeButton" /></button>

              {/* <button type="button" onClick={() => handleRedirect('/logistics')} className="loginbuttonStyle" disabled={loading}>LOGISTICS</button> */}
              {/* <button type="button" onClick={() => handleRedirect('/extraction')} className="loginbuttonStyle" disabled={loading}>Add Extraction</button> */}
              {/* {update ?(
              <button type="button" onClick={() => handleRedirect('/update')} className="loginbuttonStyle" disabled={loading}>Batch</button>):(
                
              <button type="button" onClick={() => handleRedirect('/add')} className="loginbuttonStyle" disabled={loading}>Batch</button>
              )} */}

              {update ? (
                <button type="submit" onClick={() => handleSubmit('/hydero')} className="loginbuttonStyle" disabled={loading}>{loading ? 'Loading...' : 'UPDATE'}</button>
              ) : (<button type="submit" onClick={() => handleSubmit('/hydero')} className="loginbuttonStyle" disabled={loading}>{loading ? 'Loading...' : 'NEXT'}</button>)}

              {update && <button type="button" onClick={() => handleRedirect('/hydero')} className="loginbuttonStyle" disabled={loading}>NEXT</button>}

            </div>)}


          </div>
        </div>
      </div>
      <div id="popupid" className="popup">
        <div className="popup-content">
          <h2>Add Tolling facility</h2>
          <div className="marginStyle">
            <fieldset>
              <legend className="scheduler-border">Tolling facility:</legend>
              <input type="text" id="addTolling" value={addTolling}
                onChange={(e) => handleTolling(e, 'addTolling')}
                placeholder="Enter Tolling Facility" className="inputStyle" style={{ border: 'none' }} />
            </fieldset>
          </div>
          {addDropdownError && <p style={{ color: 'red' }}>{addDropdownError}</p>}
          <div className="popup-btns-flex">
            <button onClick={() => handleClosePopup()} className="popup-btns">Close</button >
            <button onClick={() => handleSaveTolling()} disabled={loading} className="popup-btns">{loading ? 'Loading...' : 'Save'}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AqueousExtraction;