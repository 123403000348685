import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../AddNewBatch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { getData, getIdRefreshToken } from '../../ApiServices/ApiService';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function BiomassReport() {
  const location = useLocation();
  const batchNo = location.state?.name;
  const update = location.state?.update;
  const viewData = location.state?.view;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isDataLoad, setIsDataLoad] = useState(false);

  const [supplierList, setSupplierList] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [isRawBioMassDataLoaded, setIsRawBioMassDataLoaded] = useState(false);

  const [qldbDataFetched, setQldbDataFetched] = useState(false);
  const [qldbError, setQldbError] = useState('');
  const [qldbDataList, setQldbDataList] = useState([]);
  const [rawBiomassQldbList, setRawBiomassQldbList] = useState([]);
  const [supplierCoaQldbList, setSupplierCoaQldbList] = useState([]);
  const [upwellCoaQldbList, setUpwellCoaQldbList] = useState([]);
  const qldbContentRef = useRef(null);
  const [presignedUrlError, setPresignedUrlError] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [singlePdf, setSinglePdf] = useState(false);
  const [mergePdf, setMergePdf] = useState(false);
  const [qldbPdf, setQldbPdf] = useState(false);

  const userData = JSON.parse(localStorage.getItem('userInfo'));
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      if (!dataFetched) {
        getSupplierBatchList();
      }
    } else {
      callLogout();
    }
  }, [dataFetched, batchNo, viewData])

  const getSupplierBatchList = () => {
    const getPayload = {
      "op": "GET_SUPPLIER_DETAILS_RAWBIOMASS_FILES",
      "batchNo": batchNo
    }
    setError('');
    getData('file1', JSON.stringify(getPayload), userData.id_token).then(resp => {
      setDataFetched(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === "success") {
              if (respData.dataJ.length > 0) {
                setError('');
                setSupplierList(respData.dataJ);
              } else {
                setError(respData.info);
                setSupplierList([]);
              }
            } else {
              setError(respData.info);
              setSupplierList([]);
            }
          } else {
            setError('Error While Getting Supplier Batch List');
            setSupplierList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getBatch', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Loading Supplier Batch List');
          setSupplierList([]);
        }
      }).catch((error) => {
        console.log('network error:', error);
        setError('Network Error While Loading');
        setSupplierList([]);
      });
    }).catch((error) => {
      console.log('network error:', error);
      setError('Network Error While Loading!');
      setSupplierList([]);
    });
  }

  const callRefreshTokenApi = (type, event) => {
    const payload = {
      "email": userData.email,
      "oldRefreshToken": userData.refresh_token
    }
    getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
      if (resp.statusCode === 200) {
        if (resp.message.toLowerCase() === 'success') {
          userData.id_token = resp.dataJ.id_token;
          localStorage.setItem('userInfo', JSON.stringify(userData));
          if (type === 'getBatch') {
            getSupplierBatchList();
          } else if (type === 'getRawBiomassAll') {
            callRawBiomassInfo(event);
          } else if (type === 'getRawBiomassQldb') {
            callQldbReport(event);
          } else if (type === 'getReportAll') {
            getAllReportPresignedUrl();
          } else {
            console.log('type', type)
          }
        } else {
          callLogout();
        }
      } else {
        callLogout();
      }
    }).catch((error) => {
      console.log('refresh error', error);
      callLogout();
    });
  }

  const callLogout = () => {
    localStorage.removeItem('isAdminLogin');
    localStorage.removeItem('userInfo');
    localStorage.clear();
    navigate('/');
    window.location.reload();
  }

  const [rawBiomassInfo, setRawBiomassInfo] = useState({
    supplierBatch: '',
    associatedPo: '',
    supplier: '',
    shippedDate: '',
    receivedDate: '',
    supplier_coa_file: '',
    supplier_pesticides_files: '',
    upwell_coa_files: '',
    shipping_manifest_files: '',
    proof_of_delivery_files: '',
    supplier_coa_file_display_name: '',
    supplier_pesticides_files_display_name: '',
    upwell_coa_files_display_name: '',
    shipping_manifest_files_display_name: '',
    proof_of_delivery_files_display_name: '',
  })

  const [supplierCoaInfo, setSupplierCoaInfo] = useState({
    aerobic_plant_count: '',
    e_coil: '',
    total_coliforms: '',
    staphylococcus_aureus: '',
    mold: '',
    yeast: '',
    Salmonella_spp: '',
    Salmonella_spp_key: '',
    arsenic: '',
    cardium: '',
    lead: '',
    mercury: '',
    moisture: '',
    ash: '',
    protein: '',
    carbohydrate: '',
    oil: '',
    alkenone: ''
  })

  const [upwellCoaInfo, setUpwellCoaInfo] = useState({
    aerobic_plant_count: '',
    e_coil: '',
    total_coliforms: '',
    staphylococcus_aureus: '',
    mold: '',
    yeast: '',
    Salmonella_spp: '',
    Salmonella_spp_key: '',
    arsenic: '',
    cardium: '',
    lead: '',
    mercury: '',
    total_saturated: '',
    total_monousaturated: '',
    total_n_u_3pufa: '',
    total_n_u_6pufa: '',
    total_n_u_9pufa: '',
    total_pufa: '',
    moisture: '',
    ash: '',
    protein: '',
    carbohydrate: '',
    oil: '',
    alkenone: '',
    fucoxanthin: '',
    detection: '',
    detection_key: ''
  })

  const handleSuplierChange = async (event) => {
    console.log('handleSuplierChange', event);
    setSelectedSupplier(event);
    callRawBiomassInfo(event);
    callQldbReport(event);
  }

  const callRawBiomassInfo = (event) => {
    const getPayload = {
      "op": "GET_ALL_RAW_BIOMASS_DETAILS",
      "batchNo": batchNo,
      "supplierBatch": event
    }
    setError('');
    setIsDataLoad(true);
    setIsRawBioMassDataLoaded(false);
    getData('file1', JSON.stringify(getPayload), userData.id_token).then(resp => {
      // setIsDataLoad(false);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        setIsDataLoad(false);
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === "success") {
              if (respData.dataJ.length > 0) {
                setIsRawBioMassDataLoaded(true);
                setRawBiomassInfo((prevRawBiomassInfo) => ({
                  ...prevRawBiomassInfo,
                  ...respData.dataJ[0].rawbiomassData,
                }));

                setSupplierCoaInfo((prevSupplierCoaInfo) => ({
                  ...prevSupplierCoaInfo,
                  ...respData.dataJ[0].supplierCaa,
                }))

                setUpwellCoaInfo((prevUpwellCoaInfo) => ({
                  ...prevUpwellCoaInfo,
                  ...respData.dataJ[0].upwellCaa,
                }))
              } else {
                setError(respData.info);
                rawBiomassData();
              }
            } else {
              setError(respData.info);
              rawBiomassData();
            }
          } else {
            setError('Error While Getting Raw Biomass Data');
            rawBiomassData();
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getRawBiomassAll', event);
            setIsDataLoad(true);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Getting Raw Biomass Data');
          rawBiomassData();
        }
      }).catch((error) => {
        setIsDataLoad(false);
        setError('Network Error While Getting Raw Biomass Data');
        console.log('network error:', error);
        rawBiomassData();
      });
    }).catch((error) => {
      setIsDataLoad(false);
      setError('Network Error While Getting Raw Biomass Data!');
      console.log('network error:', error);
      rawBiomassData();
    });
  }

  const callQldbReport = (event) => {
    const getPayload2 = {
      "op": "GET_ALL_RAW_BIOMASS_DETAILS_QLDB",
      "batchNo": batchNo,
      "supplierBatch": event
    }
    setQldbError('');
    setIsDataLoad(true);
    setQldbDataFetched(false);
    getData('file1', JSON.stringify(getPayload2), userData.id_token).then(resp => {
      // setIsDataLoad(false);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        setIsDataLoad(false);
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === "success") {
              let rawBiomassQldb = respData.dataJ.rawbiomass;
              let supplierCoaQldb = respData.dataJ.supplierCoa;
              let upwellCoaQldb = respData.dataJ.upwellCoa;
              if (rawBiomassQldb.length > 0 || supplierCoaQldb.length > 0 || upwellCoaQldb.length > 0) {
                setQldbDataFetched(true);
                setQldbError('');
                setRawBiomassQldbList(rawBiomassQldb);
                setSupplierCoaQldbList(supplierCoaQldb);
                setUpwellCoaQldbList(upwellCoaQldb);
              } else {
                setQldbError(respData.info);
                qldbListEmpty();
              }
            } else {
              setQldbError(respData.info);
              qldbListEmpty();
            }
          } else {
            setQldbError('Error While Getting Raw Biomass QLDB Report');
            qldbListEmpty();
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getRawBiomassQldb', event);
            setIsDataLoad(true);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setQldbError('Error Occurred While Getting Raw Biomass QLDB Report');
          qldbListEmpty();
        }
      }).catch((error) => {
        setIsDataLoad(false);
        setQldbError('Network Error While Getting Raw Biomass QLDB Report');
        qldbListEmpty();
      })
    }).catch((error) => {
      setIsDataLoad(false);
      setQldbError('Network Error While Getting Raw Biomass QLDB Report!');
      qldbListEmpty();
    })
  }

  const rawBiomassData = () => {
    setRawBiomassInfo({
      supplierBatch: '',
      associatedPo: '',
      supplier: '',
      shippedDate: '',
      receivedDate: '',
      supplier_coa_file: '',
      supplier_pesticides_files: '',
      upwell_coa_files: '',
      shipping_manifest_files: '',
      proof_of_delivery_files: '',
      supplier_coa_file_display_name: '',
      supplier_pesticides_files_display_name: '',
      upwell_coa_files_display_name: '',
      shipping_manifest_files_display_name: '',
      proof_of_delivery_files_display_name: '',
    })
    setSupplierCoaInfo({
      aerobic_plant_count: '',
      e_coil: '',
      total_coliforms: '',
      staphylococcus_aureus: '',
      mold: '',
      yeast: '',
      Salmonella_spp: '',
      Salmonella_spp_key: '',
      arsenic: '',
      cardium: '',
      lead: '',
      mercury: '',
      moisture: '',
      ash: '',
      protein: '',
      carbohydrate: '',
      oil: '',
      alkenone: ''
    })
    setUpwellCoaInfo({
      aerobic_plant_count: '',
      e_coil: '',
      total_coliforms: '',
      staphylococcus_aureus: '',
      mold: '',
      yeast: '',
      Salmonella_spp: '',
      Salmonella_spp_key: '',
      arsenic: '',
      cardium: '',
      lead: '',
      mercury: '',
      total_saturated: '',
      total_monousaturated: '',
      total_n_u_3pufa: '',
      total_n_u_6pufa: '',
      total_n_u_9pufa: '',
      total_pufa: '',
      moisture: '',
      ash: '',
      protein: '',
      carbohydrate: '',
      oil: '',
      alkenone: '',
      fucoxanthin: '',
      detection: '',
      detection_key: ''
    })
  }

  const qldbListEmpty = () => {
    setRawBiomassQldbList([]);
    setSupplierCoaQldbList([]);
    setUpwellCoaQldbList([]);
  }


  const contentRef = useRef(null);
  const downloadPdf = () => {
    setSinglePdf(true);
    const content = contentRef.current;

    if (!content) {
      return;
    }

    // Use html2canvas to capture the content as an image
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm');


      const imgWidth = 210;
      const pageWidth = 210;
      const pageHeight = 295;


      // var margin = 0.5; // 0.5 inch margin on all sides
      // var marginInPoints = margin * 72;

      // var contentWidth = pdf.internal.pageSize.getWidth() - 2 * marginInPoints;

      // var imgWidth = contentWidth;

      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      const topMargin = 10;
      const bottomMargin = 10;
      const leftMargin = 10;
      const rightMargin = 10;

      // pdf.setPageMargins(leftMargin, topMargin, rightMargin, bottomMargin)
      // Add the captured image to the PDF
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Download the PDF
      pdf.save('RawBiomass Report.pdf');
      setSinglePdf(false);
    });
  };

  const downloadUploadedPdf = (fileName, fileBase64) => {
    console.log('downloadUploadedPdf value , ', fileName, fileBase64);

    const byteArray = new Uint8Array(window.atob(fileBase64).split('').map((char) => char.charCodeAt(0)));

    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.style.display = 'none';

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  const downloadQldbPdf = () => {
    setQldbPdf(true);
    const content = qldbContentRef.current;

    if (!content) {
      return;
    }

    // Use html2canvas to capture the content as an image
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210;
      const pageHeight = 295;

      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const pdf = new jsPDF('p', 'mm');
      let position = 0;

      // Add the captured image to the PDF
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Download the PDF
      pdf.save('Raw Biomass QLDB Reports.pdf');
      setQldbPdf(false);
    });
  }

  const handleRedirect = (path) => {
    navigate(path, { state: { name: batchNo, update: update, view: viewData } });
  }

  const isChanged = (current, previous, key) => {
    if (current[key] !== previous[key]) {
      return true;
    }
    return false;
  }

  const gmtToLocalTime = (gmtTime) => {
    const gmtDate = new Date(gmtTime);
    const localTime = gmtDate.toLocaleString();
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const localTimeString = localTime.toLocaleString(undefined, options);
    return localTimeString.replace(/\//g, '-').replace(/\,/g, '');
  }

  const getAllReportPresignedUrl = () => {
    setMergePdf(true);
    const getPayload = {
      "op": "GET_ALL_RAW_BIOMASS_DETAILS_BACKED_REPORT",
      "batchNo": batchNo,
      "supplierBatch": selectedSupplier
    }
    setPresignedUrlError('');
    setIsDataLoad(true);
    getData('reports', JSON.stringify(getPayload), userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        setIsDataLoad(false);
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === "success") {
              setPresignedUrlError('');
              downloadAllReport(respData.dataJ.presignedurl);
            } else {
              setMergePdf(false);
              setPresignedUrlError(respData.info);
            }
          } else {
            setMergePdf(false);
            setPresignedUrlError('Error While Getting Raw Biomass All Report Data');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getReportAll', '');
            setIsDataLoad(true);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setPresignedUrlError('Error Occurred While Getting Raw Biomass All Report Data');
          setMergePdf(false);
        }
      })
    }).catch((error) => {
      setIsDataLoad(false);
      setMergePdf(false);
      setPresignedUrlError('Network Error While Getting Raw Biomass All Report Data');
      console.log('network error:', error);
    });
  }
  const downloadAllReport = (presignedUrl) => {
    console.log('presigned_@@@@@ : ', presignedUrl);

    const link = document.createElement('a');
    link.href = presignedUrl;
    link.download = 'Raw Biomass.pdf';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    setMergePdf(false);
  };

  return (
    <div>
      <div className="reporttitle" style={{ display: 'flex' }}> <img src="./upwelllogo.png" alt="upwell" className="logoStyling" />
        <span className="addTitle">Raw Biomass Report</span>
        <button onClick={() => handleRedirect('/dashboard')} className="home-btn" style={{ paddingRight: '50px' }}> <FontAwesomeIcon icon={faHome} className="homeButton" /></button>
      </div>
      <div className="reportContainer">
        {isDataLoad && (
          <div className="dot-loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        )}
        <div className="batchNoStyling">Batch Number: {batchNo} </div>
        {/* <div className="select-batch-style"> Select the Supplier Batch To Get the Data </div> */}
        <div className="">
          <div className="">
            <fieldset>
              <legend className="scheduler-border">Select Supplier batch:</legend>
              {supplierList?.length > 0 ? (
                <select onChange={(e) => handleSuplierChange(e.target.value)} style={{ border: 'none', outline: 'none', width: '150px' }}>
                  <option value="">Select Supplier</option>
                  {supplierList?.map((item, index) => (
                    <option key={index}>
                      {item.supplierBatch}
                    </option>
                  ))}
                </select>
              ) : (
                <select onChange={(e) => handleSuplierChange(e.target.value)} style={{ border: 'none', outline: 'none', width: '150px' }}>
                  {error === '' && supplierList?.length <= 0 ? (
                    <option value="" selected disabled>Loading...</option>) : (
                    <option value="" selected disabled>No Suppliers</option>)}
                </select>
              )}
            </fieldset>
          </div>
        </div>
        {error && <p className="error">{error}</p>}

        <div className="download-div">
          {isRawBioMassDataLoaded && <span><button onClick={downloadPdf} className="dwnld-btn">{singlePdf ? 'Generating...' : 'Download PDF'}</button></span>}
          {qldbDataFetched && <span><button onClick={downloadQldbPdf} className="dwnld-btn" style={{ maxWidth: '180px' }}>{qldbPdf ? 'Generating...' : 'Download QLDB PDF'}</button></span>}
          {selectedSupplier !== '' && <span><button onClick={getAllReportPresignedUrl} className="dwnld-btn">{mergePdf ? 'Generating...' : 'Download All'}</button></span>}
          <div>{presignedUrlError && <p className="error"> {presignedUrlError}</p>}</div>
        </div>


        {isRawBioMassDataLoaded && (<div>
          <div ref={contentRef} className="pdfPage-styling">
            <div >
              <img src="./upwelllogo.png" alt="upwell" className="logoStyling" style={{ padding: '0px' }} />
            </div>
            <div className="reportheading" >Raw Biomass</div>
            <div className="displayFlex">
              <div className="batchNoStyling  display-batch">Batch Number: {batchNo} </div>
            </div>
            <div>
              <div className="batchinfo" style={{ fontWeight: '700' }}>Associated PO : {rawBiomassInfo.associatedPo}</div>
              <div className="displayFlex">
                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Supplier Batch : {rawBiomassInfo.supplierBatch}</div>
                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Supplier : {rawBiomassInfo.supplier}</div>
              </div>
              <div className="displayFlex">
                <div className="batchinfo flexAuto">Shipped Date : {rawBiomassInfo.shippedDate}</div>
                <div className="batchinfo flexAuto">Received Date : {rawBiomassInfo.receivedDate}</div>
              </div>

              <div className="displayFlex">
                <div className="batchinfo">Supplier CoA File : {rawBiomassInfo.supplier_coa_file_display_name}</div>
                {rawBiomassInfo.supplier_coa_file && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(rawBiomassInfo.supplier_coa_file_display_name, rawBiomassInfo.supplier_coa_file)} />}
              </div>
              <div className="displayFlex">
                <div className="batchinfo">Supplier Pesticides File : {rawBiomassInfo.supplier_pesticides_files_display_name}</div>
                {rawBiomassInfo.supplier_pesticides_files && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(rawBiomassInfo.supplier_pesticides_files_display_name, rawBiomassInfo.supplier_pesticides_files)} />}
              </div>
              <div className="displayFlex">
                <div className="batchinfo">Upwell CoA File : {rawBiomassInfo.upwell_coa_files_display_name}</div>
                {rawBiomassInfo.upwell_coa_files && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(rawBiomassInfo.upwell_coa_files_display_name, rawBiomassInfo.upwell_coa_files)} />}
              </div>
              <div className="displayFlex">
                <div className="batchinfo">Shipping Manifest File : {rawBiomassInfo.shipping_manifest_files_display_name}</div>
                {rawBiomassInfo.shipping_manifest_files && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(rawBiomassInfo.shipping_manifest_files_display_name, rawBiomassInfo.shipping_manifest_files)} />}
              </div>
              <div className="displayFlex">
                <div className="batchinfo">Proof Of Delivery File : {rawBiomassInfo.proof_of_delivery_files_display_name}</div>
                {rawBiomassInfo.proof_of_delivery_files && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(rawBiomassInfo.proof_of_delivery_files_display_name, rawBiomassInfo.proof_of_delivery_files)} />}
              </div>
            </div>

            <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Supplier CoA</div>
            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
            <table className="table" style={{ marginBottom: '20px' }}>
              <thead style={{ background: 'black', color: 'white' }}>
                <tr>
                  <th style={{ padding: '0px 10px' }}>Analyte</th>
                  <th style={{ padding: '0px 10px' }}>Value(cfu/ml)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Aerobic Plate Count</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }} >{supplierCoaInfo.aerobic_plant_count}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>E. Coli</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.e_coil}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Total Coliforms</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.total_coliforms}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Staphylococcus aureus</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.staphylococcus_aureus}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Mold</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.mold}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Yeast</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.yeast}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Salmonella spp</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.Salmonella_spp_key}</td>
                </tr>
              </tbody>
            </table>

            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
            <table className="table" style={{ marginBottom: '20px' }}>
              <thead style={{ background: 'black', color: 'white' }}>
                <tr>
                  <th style={{ padding: '0px 10px' }}>Analyte</th>
                  <th style={{ padding: '0px 10px' }}>Value(mg/kg)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Arsenic</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }} >{supplierCoaInfo.arsenic}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Cadmium</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.cardium}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Lead</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.lead}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Mercury</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.mercury}</td>
                </tr>
              </tbody>
            </table>

            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
            <table className="table" style={{ marginBottom: '20px' }}>
              <thead style={{ background: 'black', color: 'white' }}>
                <tr>
                  <th style={{ padding: '0px 10px' }}>Analyte</th>
                  <th style={{ padding: '0px 10px' }}>Value(%)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Moisture</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }} >{supplierCoaInfo.moisture}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Ash</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.ash}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Protein</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.protein}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Carbohydrate</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.carbohydrate}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Oil</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.oil}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Alkenone</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{supplierCoaInfo.alkenone}</td>
                </tr>
              </tbody>
            </table>

            <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA</div>
            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
            <table className="table" style={{ marginBottom: '20px' }}>
              <thead style={{ background: 'black', color: 'white' }}>
                <tr>
                  <th style={{ padding: '0px 10px' }}>Analyte</th>
                  <th style={{ padding: '0px 10px' }}>Value(cfu/ml)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Aerobic Plate Count</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }} >{upwellCoaInfo.aerobic_plant_count}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>E. Coli</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.e_coil}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Total Coliforms</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.total_coliforms}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Staphylococcus aureus</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.staphylococcus_aureus}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Mold</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.mold}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Yeast</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.yeast}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Salmonella spp</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.Salmonella_spp_key}</td>
                </tr>
              </tbody>
            </table>

            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
            <table className="table" style={{ marginBottom: '20px' }}>
              <thead style={{ background: 'black', color: 'white' }}>
                <tr>
                  <th style={{ padding: '0px 10px' }}>Analyte</th>
                  <th style={{ padding: '0px 10px' }}>Value(mg/kg)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Arsenic</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }} >{upwellCoaInfo.arsenic}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Cadmium</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.cardium}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Lead</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.lead}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Mercury</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.mercury}</td>
                </tr>
              </tbody>
            </table>

            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Fatty Acids Analysis</div>
            <table className="table" style={{ marginBottom: '20px' }}>
              <thead style={{ background: 'black', color: 'white' }}>
                <tr>
                  <th style={{ padding: '0px 10px' }}>Analyte</th>
                  <th style={{ padding: '0px 10px' }}>Value(%)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Total saturated</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }} >{upwellCoaInfo.total_saturated}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Total monounsaturated</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.total_monousaturated}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Total n-3 PUFA</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.total_n_u_3pufa}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Total n-6 PUFA</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.total_n_u_6pufa}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Total n-9 PUFA</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.total_n_u_9pufa}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Total PUFA</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.total_pufa}</td>
                </tr>
              </tbody>
            </table>

            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
            <table className="table">
              <thead style={{ background: 'black', color: 'white' }}>
                <tr>
                  <th style={{ padding: '0px 10px' }}>Analyte</th>
                  <th style={{ padding: '0px 10px' }}>Value(%)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Moisture</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }} >{upwellCoaInfo.moisture}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Ash</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.ash}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Protein</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.protein}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Carbohydrate</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.carbohydrate}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Oil</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.oil}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Alkenone</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.alkenone}</td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Fucoxanthin</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }}>{upwellCoaInfo.fucoxanthin}</td>
                </tr>
              </tbody>
            </table>

            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Pesticides</div>
            <table className="table">
              <thead style={{ background: 'black', color: 'white' }}>
                <tr>
                  <th style={{ padding: '0px 10px' }}>Analyte</th>
                  <th style={{ padding: '0px 10px' }}>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 10px' }}>Detection</td>
                  <td style={{ padding: '0px 14px', fontWeight: '700' }} >{upwellCoaInfo.detection_key}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        )}

        {qldbDataFetched && (<div>
          {/* <div className="download-div" style={{ paddingTop: '20px' }}>
            <button onClick={downloadQldbPdf} className="dwnld-btn" style={{ maxWidth: '180px' }}>Download QLDB PDF</button>
          </div> */}
          <div ref={qldbContentRef} className="pdfPage-styling">
            <div >
              <img src="./upwelllogo.png" alt="upwell" className="logoStyling" style={{ padding: '0px' }} />
            </div>
            <div className="reportheading" >Raw Biomass QLDB Reports</div>
            <div className="batchNoStyling  display-batch displayFlex">Batch Number: {batchNo} </div>

            {rawBiomassQldbList?.length > 0 && (<div>
              <table className="table" style={{ marginBottom: '20px' }}>
                <thead style={{ background: 'black', color: 'white' }}>
                  <tr>
                    <th>Hash</th>
                    <th >Created Time</th>
                    <th >Associated PO</th>
                    <th >Supplier Batch</th>
                    <th >Supplier</th>
                    <th >Shipped Date</th>
                    <th >Received Date</th>
                    <th >Supplier CoA File</th>
                    <th >Supplier Pesticides File</th>
                    <th>Upwell CoA File</th>
                    <th >Shipping Manifest File</th>
                    <th >Proof Of Delivery File</th>
                  </tr>
                </thead>
                <tbody>
                  {rawBiomassQldbList.map((item, index) => (
                    <tr key={index}>
                      {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.rawBiomassHash}</td>}
                      {index !== 0 && <td className={isChanged(item, rawBiomassQldbList[index - 1], 'rawBiomassHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.rawBiomassHash}</td>}
                      {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.rawBiomassMetadata.txTime)}</td>}
                      {index !== 0 && <td className={isChanged(item.rawBiomassMetadata, rawBiomassQldbList[index - 1].rawBiomassMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.rawBiomassMetadata.txTime)}</td>}
                      {index === 0 && <td className="tdbreak">{item.rawbiomassData.associatedPo}</td>}
                      {index !== 0 && <td className={isChanged(item.rawbiomassData, rawBiomassQldbList[index - 1].rawbiomassData, 'associatedPo') ? 'currentdata tdbreak' : 'tdbreak'}>{item.rawbiomassData.associatedPo}</td>}
                      {index === 0 && <td className="tdbreak">{item.rawbiomassData.supplierBatch}</td>}
                      {index !== 0 && <td className={isChanged(item.rawbiomassData, rawBiomassQldbList[index - 1].rawbiomassData, 'supplierBatch') ? 'currentdata tdbreak' : 'tdbreak'}>{item.rawbiomassData.supplierBatch}</td>}
                      {index === 0 && <td className="tdbreak">{item.rawbiomassData.supplier}</td>}
                      {index !== 0 && <td className={isChanged(item.rawbiomassData, rawBiomassQldbList[index - 1].rawbiomassData, 'supplier') ? 'currentdata tdbreak' : 'tdbreak'}>{item.rawbiomassData.supplier}</td>}
                      {index === 0 && <td className="tdbreak">{item.rawbiomassData.shippedDate}</td>}
                      {index !== 0 && <td className={isChanged(item.rawbiomassData, rawBiomassQldbList[index - 1].rawbiomassData, 'shippedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.rawbiomassData.shippedDate}</td>}
                      {index === 0 && <td className="tdbreak">{item.rawbiomassData.receivedDate}</td>}
                      {index !== 0 && <td className={isChanged(item.rawbiomassData, rawBiomassQldbList[index - 1].rawbiomassData, 'receivedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.rawbiomassData.receivedDate}</td>}
                      {index === 0 && <td className="tdbreak">{item.rawbiomassData.supplier_coa_file_display_name}</td>}
                      {index !== 0 && <td className={isChanged(item.rawbiomassData, rawBiomassQldbList[index - 1].rawbiomassData, 'supplier_coa_file_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.rawbiomassData.supplier_coa_file_display_name}</td>}
                      {index === 0 && <td className="tdbreak">{item.rawbiomassData.supplier_pesticides_files_display_name}</td>}
                      {index !== 0 && <td className={isChanged(item.rawbiomassData, rawBiomassQldbList[index - 1].rawbiomassData, 'supplier_pesticides_files_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.rawbiomassData.supplier_pesticides_files_display_name}</td>}
                      {index === 0 && <td className="tdbreak">{item.rawbiomassData.upwell_coa_files_display_name}</td>}
                      {index !== 0 && <td className={isChanged(item.rawbiomassData, rawBiomassQldbList[index - 1].rawbiomassData, 'upwell_coa_files_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.rawbiomassData.upwell_coa_files_display_name}</td>}
                      {index === 0 && <td className="tdbreak">{item.rawbiomassData.shipping_manifest_files_display_name}</td>}
                      {index !== 0 && <td className={isChanged(item.rawbiomassData, rawBiomassQldbList[index - 1].rawbiomassData, 'shipping_manifest_files_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.rawbiomassData.shipping_manifest_files_display_name}</td>}
                      {index === 0 && <td className="tdbreak">{item.rawbiomassData.proof_of_delivery_files_display_name}</td>}
                      {index !== 0 && <td className={isChanged(item.rawbiomassData, rawBiomassQldbList[index - 1].rawbiomassData, 'proof_of_delivery_files_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.rawbiomassData.proof_of_delivery_files_display_name}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            )}

            {supplierCoaQldbList?.length > 0 && (<div>
              <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Supplier CoA</div>
              <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
              <table className="table" style={{ marginBottom: '20px' }}>
                <thead style={{ background: 'black', color: 'white' }}>
                  <tr>
                    <th >Hash</th>
                    <th >Created Time</th>
                    <th >Aerobic Plate Count (cfu/ml)</th>
                    <th >E. Coli (cfu/ml)</th>
                    <th >Total Coliforms (cfu/ml)</th>
                    <th >Staphylococcus aureus (cfu/ml)</th>
                    <th >Mold (cfu/ml)</th>
                    <th >Yeast (cfu/ml)</th>
                    <th >Salmonella spp (cfu/ml)</th>
                  </tr>

                </thead>
                <tbody>
                  {supplierCoaQldbList.map((item, index) => (
                    <tr key={index}>
                      {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.supplierCoaHash}</td>}
                      {index !== 0 && <td className={isChanged(item, supplierCoaQldbList[index - 1], 'supplierCoaHash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.supplierCoaHash}</td>}
                      {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.supplierCoaMetadata.txTime)}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaMetadata, supplierCoaQldbList[index - 1].supplierCoaMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.supplierCoaMetadata.txTime)}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.aerobic_plant_count}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'aerobic_plant_count') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.aerobic_plant_count}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.e_coil}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'e_coil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.e_coil}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.total_coliforms}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'total_coliforms') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.total_coliforms}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.staphylococcus_aureus}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'staphylococcus_aureus') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.staphylococcus_aureus}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.mold}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'mold') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.mold}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.yeast}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'yeast') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.yeast}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.Salmonella_spp_key}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'Salmonella_spp_key') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.Salmonella_spp_key}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
              <table className="table" style={{ marginBottom: '20px' }}>
                <thead style={{ background: 'black', color: 'white' }}>
                  <tr>
                    <th >Created Time</th>
                    <th >Arsenic (mg/kg)</th>
                    <th >Cadmium (mg/kg)</th>
                    <th >Lead (mg/kg)</th>
                    <th >Mercury (mg/kg)</th>
                  </tr>
                </thead>
                <tbody>
                  {supplierCoaQldbList.map((item, index) => (
                    <tr key={index}>
                      {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.supplierCoaMetadata.txTime)}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaMetadata, supplierCoaQldbList[index - 1].supplierCoaMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.supplierCoaMetadata.txTime)}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.arsenic}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'arsenic') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.arsenic}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.cardium}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'cardium') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.cardium}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.lead}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'lead') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.lead}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.mercury}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'mercury') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.mercury}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
              <table className="table" style={{ marginBottom: '20px' }}>
                <thead style={{ background: 'black', color: 'white' }}>
                  <tr>
                    <th >Created Time</th>
                    <th >Moisture (%)</th>
                    <th >Ash (%)</th>
                    <th >Protein (%)</th>
                    <th >Carbohydrate (%)</th>
                    <th >Oil (%)</th>
                    <th >Alkenone (%)</th>
                  </tr>

                </thead>
                <tbody>
                  {supplierCoaQldbList.map((item, index) => (
                    <tr key={index}>
                      {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.supplierCoaMetadata.txTime)}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaMetadata, supplierCoaQldbList[index - 1].supplierCoaMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.supplierCoaMetadata.txTime)}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.moisture}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'moisture') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.moisture}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.ash}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'ash') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.ash}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.protein}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'protein') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.protein}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.carbohydrate}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'carbohydrate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.carbohydrate}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.oil}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'oil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.oil}</td>}
                      {index === 0 && <td className="tdbreak">{item.supplierCoaData.alkenone}</td>}
                      {index !== 0 && <td className={isChanged(item.supplierCoaData, supplierCoaQldbList[index - 1].supplierCoaData, 'alkenone') ? 'currentdata tdbreak' : 'tdbreak'}>{item.supplierCoaData.alkenone}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            )}

            {upwellCoaQldbList?.length > 0 && (<div>
              <div className="tabletitle" style={{ marginRight: '7px', marginTop: '15px' }}>Manual Entry Upwell CoA</div>
              <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Microbiology</div>
              <table className="table" style={{ marginBottom: '20px' }}>
                <thead style={{ background: 'black', color: 'white' }}>
                  <tr>
                    <th >Hash</th>
                    <th >Created Time</th>
                    <th >Aerobic Plate Count (cfu/ml)</th>
                    <th >E. Coli (cfu/ml)</th>
                    <th >Total Coliforms (cfu/ml)</th>
                    <th >Staphylococcus aureus (cfu/ml)</th>
                    <th >Mold (cfu/ml)</th>
                    <th >Yeast (cfu/ml)</th>
                    <th >Salmonella spp (cfu/ml)</th>
                  </tr>
                </thead>
                <tbody>
                  {upwellCoaQldbList.map((item, index) => (
                    <tr key={index}>
                      {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.upwellCoahash}</td>}
                      {index !== 0 && <td className={isChanged(item, upwellCoaQldbList[index - 1], 'upwellCoahash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.upwellCoahash}</td>}
                      {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoaMetadata.txTime)}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaMetadata, upwellCoaQldbList[index - 1].upwellCoaMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoaMetadata.txTime)}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.aerobic_plant_count}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'aerobic_plant_count') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.aerobic_plant_count}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.e_coil}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'e_coil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.e_coil}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.total_coliforms}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'total_coliforms') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.total_coliforms}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.staphylococcus_aureus}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'staphylococcus_aureus') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.staphylococcus_aureus}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.mold}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'mold') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.mold}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.yeast}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'yeast') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.yeast}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.Salmonella_spp_key}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'Salmonella_spp_key') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.Salmonella_spp_key}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Heavy Metals</div>
              <table className="table" style={{ marginBottom: '20px' }}>
                <thead style={{ background: 'black', color: 'white' }}>
                  <tr>
                    <th >Created Time</th>
                    <th >Arsenic (mg/kg)</th>
                    <th >Cadmium (mg/kg)</th>
                    <th >Lead (mg/kg)</th>
                    <th >Mercury (mg/kg)</th>
                  </tr>
                </thead>
                <tbody>
                  {upwellCoaQldbList.map((item, index) => (
                    <tr key={index}>
                      {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoaMetadata.txTime)}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaMetadata, upwellCoaQldbList[index - 1].upwellCoaMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoaMetadata.txTime)}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.arsenic}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'arsenic') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.arsenic}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.cardium}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'cardium') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.cardium}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.lead}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'lead') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.lead}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.mercury}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'mercury') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.mercury}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Fatty Acids Analysis</div>
              <table className="table" style={{ marginBottom: '20px' }}>
                <thead style={{ background: 'black', color: 'white' }}>
                  <tr>
                    <th >Created Time</th>
                    <th >Total saturated (%)</th>
                    <th >Total monounsaturated (%)</th>
                    <th >Total n-3 PUFA (%)</th>
                    <th >Total n-6 PUFA (%)</th>
                    <th >Total n-9 PUFA (%)</th>
                    <th >Total PUFA (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {upwellCoaQldbList.map((item, index) => (
                    <tr key={index}>
                      {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoaMetadata.txTime)}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaMetadata, upwellCoaQldbList[index - 1].upwellCoaMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoaMetadata.txTime)}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.total_saturated}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'total_saturated') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.total_saturated}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.total_monousaturated}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'total_monousaturated') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.total_monousaturated}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.total_n_u_3pufa}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'total_n_u_3pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.total_n_u_3pufa}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.total_n_u_6pufa}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'total_n_u_6pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.total_n_u_6pufa}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.total_n_u_9pufa}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'total_n_u_9pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.total_n_u_9pufa}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.total_pufa}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'total_pufa') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.total_pufa}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>General</div>
              <table className="table" style={{ marginBottom: '20px' }}>
                <thead style={{ background: 'black', color: 'white' }}>
                  <tr>
                    <th >Created Time</th>
                    <th >Moisture (%)</th>
                    <th >Ash (%)</th>
                    <th >Protein (%)</th>
                    <th >Carbohydrate (%)</th>
                    <th >Oil (%)</th>
                    <th >Alkenone (%)</th>
                    <th >Fucoxanthin (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {upwellCoaQldbList.map((item, index) => (
                    <tr key={index}>
                      {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoaMetadata.txTime)}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaMetadata, upwellCoaQldbList[index - 1].upwellCoaMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoaMetadata.txTime)}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.moisture}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'moisture') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.moisture}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.ash}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'ash') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.ash}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.protein}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'protein') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.protein}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.carbohydrate}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'carbohydrate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.carbohydrate}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.oil}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'oil') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.oil}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.alkenone}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'alkenone') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.alkenone}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.fucoxanthin}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'fucoxanthin') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.fucoxanthin}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px', marginTop: '10px' }}>Pesticides</div>
              <table className="table" style={{ marginBottom: '20px' }}>
                <thead style={{ background: 'black', color: 'white' }}>
                  <tr>
                    <th >Created Time</th>
                    <th >Detection</th>
                  </tr>
                </thead>
                <tbody>
                  {upwellCoaQldbList.map((item, index) => (
                    <tr key={index}>
                      {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.upwellCoaMetadata.txTime)}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaMetadata, upwellCoaQldbList[index - 1].upwellCoaMetadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.upwellCoaMetadata.txTime)}</td>}
                      {index === 0 && <td className="tdbreak">{item.upwellCoaData.detection_key}</td>}
                      {index !== 0 && <td className={isChanged(item.upwellCoaData, upwellCoaQldbList[index - 1].upwellCoaData, 'detection_key') ? 'currentdata tdbreak' : 'tdbreak'}>{item.upwellCoaData.detection_key}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            )}
          </div>
        </div>
        )}
      </div>
    </div>
  )
}

export default BiomassReport;
