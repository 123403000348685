import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { postData, getData, getIdRefreshToken } from '../ApiServices/ApiService';
import { Link } from 'react-router-dom';
import './AddNewBatch.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

function UpdateBatch() {
  const location = useLocation();
  const batchDetails = location.state?.batchInfo;
  const [supplierList, setSupplierList] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      if (!dataFetched) {
        getSupplierBatchList();
        if (batchDetails) {
          setBatchNo(batchDetails.batchNo);
          setStartDate(batchDetails.startDate);
          setEndDate(batchDetails.endDate);
          setstartedBy(batchDetails.startedBy);
          setClosedBy(batchDetails.closedBy);
        }
      }
    } else {
      callLogout();
    }
    document.body.classList.remove('other-bg');
    document.body.classList.add('newbatch-bg');
    return () => {
      document.body.classList.remove('newbatch-bg');
      document.body.classList.add('other-bg');
    };
  }, [dataFetched]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    batchNo: '',
    startDate: '',
    endDate: '',
    startedBy: '',
    closedBy: '',
  });
  const [error, setError] = useState(null);
  const handleRedirect = (path) => {
    if (!localStorage.getItem('isAdminLogin')) {
      setError('You are not currently logged in');
      console.log('data not in local'); return
    }
    if (!batchNo) {
      setError('Batch Number is required');
      return
    }
    const newBatchList = {
      batchNo: batchNo,
      startDate: startDate,
      endDate: endDate,
      startedBy: startedBy,
      closedBy: closedBy
    };
    navigate(path, { state: { name: batchNo, update: true, view: false, batchInfo: newBatchList } });
  }

  const getSupplierBatchList = () => {
    const getPayload = {
      "op": "GET_BATCH_DETAILS_SEARCH"
    }
    setError('');
    getData('test1', JSON.stringify(getPayload), userData.id_token).then(resp => {
      setDataFetched(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setError('');
                setSupplierList(respData.dataJ);
              } else {
                setError(respData.info);
                setSupplierList([]);
              }
            } else {
              setError(respData.info);
              setSupplierList([]);
            }
          } else {
            setError('Error While Getting Supplier Batch List');
            setSupplierList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getBatch', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Loading Supplier Batch List');
          setSupplierList([]);
        }
      }).catch((error) => {
        console.log('network error:', error);
        setSupplierList([]);
        setError('Network Error While Loading');
      });
    }).catch((error) => {
      console.log('network error:', error);
      setSupplierList([]);
      setError('Network Error While Loading!');
    });
  }

  const callRefreshTokenApi = (type, event) => {
    const payload = {
      "email": userData.email,
      "oldRefreshToken": userData.refresh_token
    }
    getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
      if (resp.statusCode === 200) {
        if (resp.message.toLowerCase() === 'success') {
          userData.id_token = resp.dataJ.id_token;
          localStorage.setItem('userInfo', JSON.stringify(userData));
          if (type === 'getBatch') {
            getSupplierBatchList();
          } else if (type === 'upadeBatch') {
            handleSubmit(event);
          } else if (type === 'getBatchInfo') {
            handleUpdatedvalue(event);
          } else {
            console.log('type', type)
          }
        } else {
          callLogout();
        }
      } else {
        callLogout();
      }
    }).catch((error) => {
      console.log('refresh error', error);
      callLogout();
    });
  }

  const callLogout = () => {
    localStorage.removeItem('isAdminLogin');
    localStorage.removeItem('userInfo');
    localStorage.clear();
    navigate('/');
    window.location.reload();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!localStorage.getItem('isAdminLogin')) {
      setError('You are not currently logged in');
      console.log('data not in local'); return
    }
    const formErrors = {};
    if (!batchNo) {
      formErrors.batchNo = 'Batch Number is required';
      setErrors(formErrors);
      return
    }
    if (!startDate) {
      formErrors.startDate = 'Start Date is required';
      setErrors(formErrors);
      return
    }

    if (endDate) {
      if (startDate > endDate) {
        formErrors.responseError = 'From date cannot be greater than To date'
        setError('From date cannot be greater than To date');
        return
      }
    }

    if (!startedBy) {
      formErrors.startedBy = 'startedBy is required';
      setErrors(formErrors);
      return
    }


    const payload = {
      op: 'UPDATE_BATCH_DETAILS',
      batchNo: batchNo,
      startDate: startDate,
      endDate: endDate,
      startedBy: startedBy,
      closedBy: closedBy
    };
    setError('');
    setLoading(true);
    await postData('test1', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === "success") {
              setError(respData.info);
              // setTimeout(() => {  
              setLoading(false);
              // }, 2000);
            } else {
              setLoading(false);
              setError(respData.info);
            }
          } else {
            setLoading(false);
            setError('Error While Updating Batch Details');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('upadeBatch', e);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setError('Error Occurred While Updating Batch Details');
        }
      }).catch((error) => {
        setLoading(false);
        setError('Network Error While Updating');
        console.log('network error:', error);
      });
    }).catch((error) => {
      setLoading(false);
      setError('Network Error While Updating!');
      console.log('network error:', error);
    });
  };

  const [batchNo, setBatchNo] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startedBy, setstartedBy] = useState('');
  const [closedBy, setClosedBy] = useState('');


  // const alphanumericRegex = /^[a-zA-Z0-9]*$/;
  // const handleBatchNoChange = (event) => {
  //   const newValue = event.target.value.replace(/[-.]/g, '');
  //     // setBatchNo(newValue);
  //     if (alphanumericRegex.test(newValue)) {
  //       setBatchNo(newValue);
  //     } else {
  //       // If it doesn't match, don't update the state (ignore special characters)
  //     }
  // };

  // const handleKeyPress = (event) => {
  //   // Prevent "-" and "." keys from being entered
  //   if (event.key === '-' || event.key === '.' || event.key === '+' || event.key === 'e') {
  //     event.preventDefault();
  //   }
  // };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleStartedByChange = (event) => {
    setstartedBy(event.target.value);
  };

  const handleClosedByChange = (event) => {
    setClosedBy(event.target.value);
  };


  const handleUpdatedvalue = async (event) => {
    const getPayload = {
      "op": "get_batch_details",
      "batchNo": event
    }
    setError('');
    await getData('test1', JSON.stringify(getPayload), userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setError('');
                setBatchNo(respData.dataJ[0].batchNo);
                setStartDate(respData.dataJ[0].startDate);
                setEndDate(respData.dataJ[0].endDate);
                setstartedBy(respData.dataJ[0].startedBy);
                setClosedBy(respData.dataJ[0].closedBy);
              } else {
                setError(respData.info);
                getBatchDetailsEmpty();
              }
            } else {
              setError(respData.info);
              getBatchDetailsEmpty();
            }
          } else {
            setError('Error While Getting Supplier Batch Details');
            getBatchDetailsEmpty();
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getBatchInfo', event);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Loading Supplier Batch Details');
          getBatchDetailsEmpty();
        }
      }).catch((error) => {
        setError('Network Error While Loading');
        getBatchDetailsEmpty();
        console.log('network error:', error);
      });
    }).catch((error) => {
      setError('Network Error While Loading!');
      getBatchDetailsEmpty();
      console.log('network error:', error);
    });
  }

  const getBatchDetailsEmpty = () => {
    setBatchNo('');
    setStartDate('');
    setEndDate('');
    setstartedBy('');
    setClosedBy('');
  }

  return (
    <div>
      <div className="upwelltitle" style={{ display: 'flex' }}>
        <img src="./upwelllogo.png" alt="upwell" className="logoStyling" /> <span className="addTitle-batch">Update Batch Details</span>
        <span className="home-btn-span"><Link to={'/dashboard'}>
          <button className="home-btn btn-width"> <FontAwesomeIcon icon={faHome} className="homeButton" /></button> </Link>
        </span>
      </div>
      <div className="maincontainernew">

        <div className="containerStyle">
          <div>
            <form onSubmit={handleSubmit}>
              <div className="boxStyling">
                <div className="displayFlex marginStyle">
                  <div className="flexAuto">
                    {supplierList?.length > 0 ? (
                      <fieldset>
                        <legend className="scheduler-border">Batch Number:</legend>
                        <select value={batchNo} onChange={(e) => handleUpdatedvalue(e.target.value)} style={{ border: 'none', outline: 'none', width: '150px' }}>
                          <option value="">Select Supplier</option>
                          {supplierList?.map((item, index) => (
                            <option key={index} value={item.batchNo}>
                              {item.batchNo}
                            </option>
                          ))}
                        </select>
                        {/* <input type="text" id="batchNo" name="batchNo" value={batchNo} onKeyPress={handleKeyPress} onChange={handleBatchNoChange} className="inputStyle"  style={{border:'none'}}/> */}
                      </fieldset>
                    ) : (
                      <div>Loading....</div>
                    )

                    }

                    {errors.batchNo && <p className="error">{errors.batchNo}</p>}
                  </div>
                  {/* <div className="flexAuto searchicon">
            <button type="button" onClick={handleUpdatedvalue} className="home-btn"><i className="fas fa-search"></i></button>            
            </div> */}
                </div>

                <div className="displayFlex marginStyle">
                  <div className="flexAuto">
                    <fieldset>
                      <legend className="scheduler-border">Start Date:</legend>
                      <input type="date" id="startDate" max={new Date().toISOString().split('T')[0]} value={startDate} onChange={handleStartDateChange} className="inputStyle" placeholder="MM/DD/YYYY" />
                    </fieldset>
                    {errors.startDate && <p className="error">{errors.startDate}</p>}
                  </div>
                  <div className="flexAuto">
                    <fieldset>
                      <legend className="scheduler-border">End Date:</legend>
                      <input type="date" id="endDate" max={new Date().toISOString().split('T')[0]} value={endDate} onChange={handleEndDateChange} className="inputStyle" placeholder="MM/DD/YYYY" />
                    </fieldset>
                    {errors.endDate && <p className="error">{errors.endDate}</p>}
                  </div>
                </div>
                <div className="displayFlex marginStyle">
                  <div className="flexAuto">
                    <fieldset>
                      <legend className="scheduler-border">Started by:</legend>
                      <input type="text" id="startedBy" value={startedBy} onChange={handleStartedByChange} className="inputStyle" style={{ border: 'none' }} />
                    </fieldset>
                    {errors.startedBy && <p className="error">{errors.startedBy}</p>}
                  </div>
                  <div className="flexAuto">
                    <fieldset>
                      <legend className="scheduler-border">Closed by:</legend>
                      <input type="text" id="closedBy" value={closedBy} onChange={handleClosedByChange} className="inputStyle" style={{ border: 'none' }} />
                    </fieldset>
                    {errors.closedBy && <p className="error">{errors.closedBy}</p>}
                  </div>

                </div>

              </div>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <button type="submit" className="buttonStyle4" >
                {loading ? 'Loading...' : 'Update'}
              </button>
            </form>

            <div className="paddingTop">

              <button type="submit" onClick={() => handleRedirect('/rawbiomass')} className="buttonStyle" disabled={loading}>
                {loading ? 'Loading...' : 'RAW BIOMASS'} </button>

              <button type="submit" onClick={() => handleRedirect('/extraction')} className="buttonStyle1" disabled={loading}>
                {loading ? 'Loading...' : 'EXTRACTION'} </button>

              <button type="submit" onClick={() => handleRedirect('/logistics')} className="buttonStyle2" disabled={loading}>
                {loading ? 'Loading...' : 'LOGISTICS'}</button>

              <button type="submit" onClick={() => handleRedirect('/products')} className="buttonStyle3" disabled={loading}>
                {loading ? 'Loading...' : 'PRODUCTS'}</button>

            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateBatch;