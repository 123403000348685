import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './AddNewBatch.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { postData, getData, getIdRefreshToken } from '../ApiServices/ApiService';

function LogisticsClient() {
  const location = useLocation();
  const batchNo = location.state?.name;
  const update = location.state?.update;
  const viewData = location.state?.view;
  const batchInfo = location.state?.batchInfo;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');


  const [addShipper, setAddShipper] = useState('');
  const [addShippedTo, setAddShippedTo] = useState('');
  const [addAir_Sea, setAddAirSea] = useState('');
  const [isDataLoad, setIsDataLoad] = useState(false);

  const [isShipper, setIsShipper] = useState(false);
  const [shipperOptionList, setShipperOptionList] = useState([]);
  const [isShippedTo, setIsShippedTo] = useState(false);
  const [shippedToList, setShippedToList] = useState([]);
  const [shipperError, setShipperError] = useState('');
  const [shippedToError, setShippedToError] = useState('');

  // const options_1 = ['option1', 'option2', 'option3'];
  // const options_2 = ['option1', 'option2', 'option3'];
  const options_3 = ['Air', 'Sea'];

  const [dataFetched, setDataFetched] = useState(false);
  const [addDropdownError, setAddDropdownError] = useState('');
  const [isAdmin, setisAdmin] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      if (!dataFetched) {
        if (localStorage.getItem('isAdminLogin')) {
          const adminTypeValue = localStorage.getItem('isAdminLogin');
          if (adminTypeValue === 'false') {
            setisAdmin(true);
          } else {
            if (viewData) {
              setisAdmin(true);
            } else {
              setisAdmin(false);
            }
          }
        }
        if (update) {
          console.log('If update', update);
          getUpwellClientData();
        } else {
          console.log('els update', update);
        }
      }
      if (!viewData) {
        if (!isShipper) {
          getShipperList();
        }
        if (!isShippedTo) {
          getShippedToList();
        }
      }

    } else {
      callLogout();
    }
  }, [dataFetched, update, batchNo, isShippedTo, isShipper, viewData]);

  const getUpwellClientData = () => {
    const getPayload = {
      op: "GET_UPWELL_TO_CLIENT",
      batchno: batchNo
    }
    setError('');
    setIsDataLoad(true);
    getData('logi', JSON.stringify(getPayload), userData.id_token).then(resp => {
      // setIsDataLoad(false);
      setDataFetched(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        setIsDataLoad(false);
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ !== undefined) {
                setError('');
                // setSupplierList(response);
                // setClientPO(response.Client_po_file_logi);
                setMaterialInfo((prevMaterialInfo) => ({
                  ...prevMaterialInfo,
                  ...respData.dataJ,
                }));

                setShippingDates((prevShippingDates) => ({
                  ...prevShippingDates,
                  ...respData.dataJ,
                }));

                setShipperInfo((prevShipperInfo) => ({
                  ...prevShipperInfo,
                  ...respData.dataJ,
                }));

                setShippingPdfs((prevShippingPdfs) => ({
                  ...prevShippingPdfs,
                  ...respData.dataJ,
                }));

                setFileNames((prevFileNames) => ({
                  ...prevFileNames,
                  ...respData.dataJ,
                }));
              } else {
                setError(respData.info);
                upwellClientDataEmpty();
              }
            } else {
              setError(respData.info);
              upwellClientDataEmpty();
            }
          } else {
            setError('Error While Loading Upwell Data');
            upwellClientDataEmpty();
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getUpwellClientData', '');
            setIsDataLoad(true);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Getting Upwell Data');
          upwellClientDataEmpty();
        }
      }).catch((error) => {
        setIsDataLoad(false);
        console.log('network error:', error);
        setError('Network Error While Loading Upwell Data');
        upwellClientDataEmpty();
      });
    }).catch((error) => {
      setIsDataLoad(false);
      console.log('network error:', error);
      setError('Network Error While Loading Upwell Data!');
      upwellClientDataEmpty();
    });
  }

  const upwellClientDataEmpty = () => {
    setShippingDates({
      shippedDate: '',
      receivedDate: '',
    });
    setShipperInfo({
      shipped_to: '',
      shipper: '',
      air_sea: '',
    });
    setMaterialInfo({
      upwell_butter: '',
      upwell_wax: '',
      upwell_oleo: '',
      upwell_hydro: '',
      spent_biomass: ''
    });

    setShippingPdfs({
      shipping_manifest_file_logi: '',
      proof_of_delivery_file_logi: '',
      Client_po_file_logi: ''
    });

    setFileNames({
      shipping_manifest_file_logi_display_name: '',
      proof_of_delivery_file_logi_display_name: '',
      Client_po_file_logi_display_name: ''
    });
  }

  const getShipperList = () => {
    const payload = {
      "op": "GET_SHIPPER_DROPDOWN"
    }
    setShipperError('');
    getData('logi', JSON.stringify(payload), userData.id_token).then(resp => {
      setIsShipper(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setShipperError('');
                setShipperOptionList(respData.dataJ);
              } else {
                setShipperError(respData.info);
                setShipperOptionList([]);
              }
            } else {
              setShipperError(respData.info);
              setShipperOptionList([]);
            }
          } else {
            setShipperError('Error While Getting Shipper List');
            setShipperOptionList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getShipperList', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setShipperError('Error Occurred While Loading Shipper List');
          setShipperOptionList([]);
        }
      }).catch((error) => {
        console.log('network error:', error);
        setShipperError('Network Error While Getting Shipper List');
        setShipperOptionList([]);
      });
    }).catch((error) => {
      console.log('network error:', error);
      setShipperError('Network Error While Getting Shipper List!');
      setShipperOptionList([]);
    });

  }

  const getShippedToList = () => {
    const payload = {
      "op": "GET_SHIPPED_TO_DROPDOWN"
    }
    setShippedToError('');
    getData('logi', JSON.stringify(payload), userData.id_token).then(resp => {
      setIsShippedTo(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setShippedToError('');
                setShippedToList(respData.dataJ);
              } else {
                setShippedToError(respData.info);
                setShippedToList([]);
              }
            } else {
              setShippedToError(respData.info);
              setShippedToList([]);
            }
          } else {
            setShippedToError('Error While Getting Shipped To List');
            setShippedToList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getShippedToList', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setShippedToError('Error Occurred While Loading Shipped To List');
          setShippedToList([]);
        }
      })
    }).catch((error) => {
      console.log('network error:', error);
      setShippedToError('Network Error While Loading Shipped To List');
      setShippedToList([]);
    });
  }

  const callRefreshTokenApi = (type, path) => {
    const payload = {
      "email": userData.email,
      "oldRefreshToken": userData.refresh_token
    }
    getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
      if (resp.statusCode === 200) {
        if (resp.message.toLowerCase() === 'success') {
          userData.id_token = resp.dataJ.id_token;
          localStorage.setItem('userInfo', JSON.stringify(userData));
          if (type === 'getUpwellClientData') {
            getUpwellClientData();
          } else if (type === 'getShipperList') {
            getShipperList();
          } else if (type === 'getShippedToList') {
            getShippedToList();
          } else if (type === 'handleSaveShipper') {
            handleSaveShipper();
          } else if (type === 'handleSaveShippedTo') {
            handleSaveShippedTo();
          } else if (type === 'insertUpwellClient') {
            insertUpwellClient(path);
          } else if (type === 'updateUpwellClient') {
            updateUpwellClient(path);
          } else {
            console.log('type', type)
          }
        } else {
          callLogout();
        }
      } else {
        callLogout();
      }
    }).catch((error) => {
      console.log('refresh error', error);
      callLogout();
    });
  }

  const callLogout = () => {
    localStorage.removeItem('isAdminLogin');
    localStorage.removeItem('userInfo');
    localStorage.clear();
    navigate('/');
    window.location.reload();
  }

  const handleAddShipper = (event) => {
    setAddShipper(event.target.value);
  };

  const handleShipperClosePopup = () => {
    document.getElementById('popupid2').style.display = 'none';
  };

  const handleSaveShipper = () => {
    console.log('id', addShipper);
    if (addShipper.trim().length <= 0) {
      setAddDropdownError('Enter Shipper'); return
    }
    const payload = {
      "op": "UPDATE_SHIPPER_DROPDOWN",
      "suppliers_list": addShipper
    }
    setLoading(true);
    setAddDropdownError('');
    postData('logi', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setLoading(false);
              setAddDropdownError(respData.info);
              setIsShipper(false);
              setTimeout(() => {
                document.getElementById('popupid2').style.display = 'none';
              }, 1000);
            } else {
              setLoading(false);
              setAddDropdownError(respData.info);
            }
          } else {
            setLoading(false);
            setAddDropdownError('Error While Adding Shipper');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('handleSaveShipper', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setAddDropdownError('Error Occurred While Adding Shipper');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setAddDropdownError('Network Error While Adding Shipper');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setAddDropdownError('Network Error While Adding Shipper');
    });
  };

  const handleAddShippedTo = (event) => {
    setAddShippedTo(event.target.value);
  };

  const handleShippedToClosePopup = () => {
    document.getElementById('popupid3').style.display = 'none';
  };

  const handleSaveShippedTo = () => {
    console.log('id', addShippedTo);
    if (addShippedTo.trim().length <= 0) {
      setAddDropdownError('Enter Shipped To'); return
    }
    const payload = {
      "op": "UPDATE_SHIPPED_TO_DROPDOWN",
      "suppliers_list": addShippedTo
    }
    setLoading(true);
    setAddDropdownError('');
    postData('logi', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setLoading(false);
              setAddDropdownError(respData.info);
              setIsShippedTo(false);
              setTimeout(() => {
                document.getElementById('popupid3').style.display = 'none';
              }, 1000);
            } else {
              setLoading(false);
              setAddDropdownError(respData.info);
            }
          } else {
            setLoading(false);
            setAddDropdownError('Error While Adding Shipped To');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('handleSaveShippedTo', '')
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setAddDropdownError('Error Occurred While Adding Shipped To');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setAddDropdownError('Network Error While Adding Shipped To');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setAddDropdownError('Network Error While Adding Shipped To');
    });
  };

  const handleAddAirSea = (event) => {
    setAddAirSea(event.target.value);
  };

  const handleAirSeaClosePopup = () => {
    document.getElementById('popupid4').style.display = 'none';
  };

  const handleSaveAirSea = () => {
    console.log('id', addAir_Sea)
  };

  const handleRedirect = (path) => {
    if (path === '/products') {
      navigate(path, { state: { name: batchNo, update: update, view: viewData, batchInfo: batchInfo } });
    } else {
      navigate(path, { state: { name: batchNo, update: update, view: viewData, batchInfo: batchInfo } });
    }
  };

  const [shippingPdfs, setShippingPdfs] = useState({
    shipping_manifest_file_logi: '',
    proof_of_delivery_file_logi: '',
    Client_po_file_logi: ''
  });

  const [fileNames, setFileNames] = useState({
    shipping_manifest_file_logi_display_name: '',
    proof_of_delivery_file_logi_display_name: '',
    Client_po_file_logi_display_name: ''
  });

  const [shippingDates, setShippingDates] = useState({
    shippedDate: '',
    receivedDate: '',
  });

  const [shipperInfo, setShipperInfo] = useState({
    shipped_to: '',
    shipper: '',
    air_sea: '',
  });

  const [materialInfo, setMaterialInfo] = useState({
    upwell_butter: '',
    upwell_wax: '',
    upwell_oleo: '',
    upwell_hydro: '',
    spent_biomass: ''
  });
  // const [clientPO, setClientPO] = useState('');

  const handleMaterialInfo = (event, inputName) => {
    const newValue = event.target.value.replace(/[-.]/g, '');
    setMaterialInfo({ ...materialInfo, [inputName]: newValue });
  };

  const handleFileChange = (event, inputFile, inputName) => {
    setError('')
    var validImageFileExtensions = ['.pdf'];
    // setShippingPdfs({ ...shippingPdfs, [inputName]: event.target.files[0] });
    if (event.target.files[0]) {
      if (!validImageFileExtensions.some(el => event.target.files[0].name.toLowerCase().endsWith(el))) {
        setError("File not supported! only PDF files allowed");
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = () => {
        const base64Pdf = reader.result.split(',')[1]; // Get the base64 data
        console.log('base64Pdf', base64Pdf)
        setShippingPdfs({ ...shippingPdfs, [inputFile]: base64Pdf });
      };
    }
    if (event.target.files[0]) {
      setFileNames({ ...fileNames, [inputName]: event.target.files[0].name });
    }
  };
  const handleDropdownChange = (event, dropdownName) => {
    // setShipperInfo({ ...shipperInfo, [dropdownName]: event.target.value });
    setAddDropdownError('');
    if (event.target.value === 'addShipped') {
      setAddShippedTo('');
      document.getElementById('popupid3').style.display = 'flex';
    } else if (event.target.value === 'addShipper') {
      setAddShipper('');
      document.getElementById('popupid2').style.display = 'flex';
    } else if (event.target.value === 'addAir') {
      document.getElementById('popupid4').style.display = 'flex';
    } else {
      setShipperInfo({ ...shipperInfo, [dropdownName]: event.target.value });
    }
  };

  // const handleClientPO = (event) => {
  //   const newValue = event.target.value.replace(/[-.]/g, '');
  //   setClientPO(newValue);
  // };

  const handleKeyPress = (event) => {
    // Prevent "-" and "." keys from being entered
    if (event.key === '-' || event.key === '.' || event.key === '+' || event.key === 'e') {
      event.preventDefault();
    }
  };

  const handleDateChange = (event, inputName) => {
    setShippingDates({ ...shippingDates, [inputName]: event.target.value });
  };

  // const handleSelectChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  const handleSubmit = async (path) => {
    // navigate(path, { state: { name: batchNo } });
    if (!shippingPdfs.Client_po_file_logi) {
      setError('ClientPO is Required');
      return
    }
    if (shippingDates.receivedDate) {
      if (shippingDates.shippedDate > shippingDates.receivedDate) {
        setError('Received date cannot be greater than Shipped date');
        return
      }
    }
    if (update) {
      updateUpwellClient(path);
    } else {
      insertUpwellClient(path);
    }
  };

  const insertUpwellClient = async (path) => {
    const payload = {
      "op": "INSERT_UPWELL_TO_CLIENT",
      "batchno": batchNo,
      "shipped_to": shipperInfo.shipped_to,
      "upwell_butter": materialInfo.upwell_butter,
      "upwell_wax": materialInfo.upwell_wax,
      "upwell_oleo": materialInfo.upwell_oleo,
      "upwell_hydro": materialInfo.upwell_hydro,
      "spent_biomass": materialInfo.spent_biomass,
      "shipper": shipperInfo.shipper,
      "air_sea": shipperInfo.air_sea,
      "shippedDate": shippingDates.shippedDate,
      "receivedDate": shippingDates.receivedDate,
      "Client_po_file_logi": shippingPdfs.Client_po_file_logi,
      "proof_of_delivery_file_logi": shippingPdfs.proof_of_delivery_file_logi,
      "shipping_manifest_file_logi": shippingPdfs.shipping_manifest_file_logi,
      "Client_po_file_logi_display_name": fileNames.Client_po_file_logi_display_name,
      "proof_of_delivery_file_logi_display_name": fileNames.proof_of_delivery_file_logi_display_name,
      "shipping_manifest_file_logi_display_name": fileNames.shipping_manifest_file_logi_display_name
    }
    setLoading(true);
    setError('');
    await postData('logi', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setError(respData.info);
              // setTimeout(() => {
              setLoading(false);
              //   navigate(path, { state: { name: batchNo,update: update } });          
              // }, 2000);
            } else {
              setLoading(false);
              setError(respData.info);
            }
          } else {
            setLoading(false);
            setError('Error While Adding Logistics Upwell Data');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('insertUpwellClient', path);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Adding Logistics Upwell Data');
          setLoading(false);
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setError('Network Error While Adding Logistics Upwell Data');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setError('Network Error While Adding Logistics Upwell Data');
    });
  }

  const updateUpwellClient = async (path) => {
    const payload = {
      "op": "UPDATE_UPWELL_TO_CLIENT",
      "batchno": batchNo,
      "shipped_to": shipperInfo.shipped_to,
      "upwell_butter": materialInfo.upwell_butter,
      "upwell_wax": materialInfo.upwell_wax,
      "upwell_oleo": materialInfo.upwell_oleo,
      "upwell_hydro": materialInfo.upwell_hydro,
      "spent_biomass": materialInfo.spent_biomass,
      "shipper": shipperInfo.shipper,
      "air_sea": shipperInfo.air_sea,
      "shippedDate": shippingDates.shippedDate,
      "receivedDate": shippingDates.receivedDate,
      "Client_po_file_logi": shippingPdfs.Client_po_file_logi,
      "proof_of_delivery_file_logi": shippingPdfs.proof_of_delivery_file_logi,
      "shipping_manifest_file_logi": shippingPdfs.shipping_manifest_file_logi,
      "Client_po_file_logi_display_name": fileNames.Client_po_file_logi_display_name,
      "proof_of_delivery_file_logi_display_name": fileNames.proof_of_delivery_file_logi_display_name,
      "shipping_manifest_file_logi_display_name": fileNames.shipping_manifest_file_logi_display_name
    }
    setLoading(true);
    setError('');
    await postData('logi', payload, userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              setError(respData.info);
              // setTimeout(() => {
              setLoading(false);
              //   navigate(path, { state: { name: batchNo,update: update } });          
              // }, 2000);
            } else {
              setLoading(false);
              setError(respData.info);
            }
          } else {
            setLoading(false);
            setError('Error While Updating Logistics Upwell Data');
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('updateUpwellClient', path);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setLoading(false);
          setError('Error Occurred While Updating Logistics Upwell Data');
        }
      }).catch((error) => {
        setLoading(false);
        console.log('network error:', error);
        setError('Network Error While Updating Logistics Upwell Data');
      });
    }).catch((error) => {
      setLoading(false);
      console.log('network error:', error);
      setError('Network Error While Updating Logistics Upwell Data');
    });
  }

  return (
    <div>
      <div className="upwelltitle" style={{ display: 'flex' }}><img src="./upwelllogo.png" alt="upwell" className="logoStyling" />
        <span className="addTitle">Batch details: Logistics Upwell to Clients</span></div>
      <div className="maincontainerbiomass">

        <div className="biomassContainer">
          {/* <div className="addTitle">Batch Details: Raw Biomass</div> */}
          <div className="biomassDiv">
            {/* <form onSubmit={handleSubmit}> */}
            {isDataLoad && (
              <div className="dot-loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            )
            }
            <div className="batchNoStyling">Batch Number: {batchNo} </div>

            <div className="displayFlex marginStyle">
              {viewData ? (
                   <div className="flexAuto">
                   <fieldset>
                     <legend className="scheduler-border">Shipped to:</legend>
                     <input type="text" value={shipperInfo.shipped_to}
                    onChange={(e) => handleDropdownChange(e, 'shipped_to')}
                    placeholder="" className="inputStyle" style={{ border: 'none' }} disabled={isAdmin} />
                   </fieldset>
                 </div>
              ):(
                   <div className="flexAuto">
                   <fieldset>
                     <legend className="scheduler-border">Shipped to:</legend>
                     <select className="dropdownStyle" value={shipperInfo.shipped_to} onChange={(e) => handleDropdownChange(e, 'shipped_to')} disabled={isAdmin}>
                       <option value="">Select an option</option>
                       {shippedToList?.map((option, index) => (
                         <option key={index} value={option.suppliers_list}>
                           {option.suppliers_list}
                         </option>
                       ))}
                       <option value="addShipped" style={{ color: 'blue' }}>Add New Option</option>
                     </select>
                   </fieldset>
                 </div>
              )}
           
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Client PO#:</legend>
                  {/* <input type="number" id="clientPO" value={clientPO}
                  onChange={(e) => handleClientPO(e)} onKeyPress={handleKeyPress}
                  placeholder="" className="inputStyle"  disabled={isAdmin} style={{border:'none'}}/> */}
                  <div className="file-input-container">
                    <label htmlFor="file-input6" className="custom-file-label">
                      {fileNames.Client_po_file_logi_display_name ? fileNames.Client_po_file_logi_display_name : 'Choose File'}
                    </label>
                    <input
                      type="file"
                      id="file-input6" accept=".pdf"
                      onChange={(e) => handleFileChange(e, 'Client_po_file_logi', 'Client_po_file_logi_display_name')}
                      style={{ display: 'none' }} disabled={isAdmin}
                    />
                  </div>
                </fieldset>
              </div>
            </div>

            <div className="displayFlex marginStyle">
              <div className="flexAuto logistic-metrial" >
                <span style={{ textAlign: 'center' }}>Material</span>
              </div>
              {viewData ? (
                              <div className="flexAuto">
                              <fieldset>
                                <legend className="scheduler-border">Shipper:</legend>
                                <input type="text" value={shipperInfo.shipper}
                    onChange={(e) => handleDropdownChange(e, 'shipper')}
                    placeholder="" className="inputStyle" style={{ border: 'none' }} disabled={isAdmin} />
                              </fieldset>
                            </div>
              ):(
                <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Shipper:</legend>
                  <select className="dropdownStyle" value={shipperInfo.shipper} onChange={(e) => handleDropdownChange(e, 'shipper')} disabled={isAdmin}>
                    <option value="">Select an option</option>
                    {shipperOptionList?.map((option, index) => (
                      <option key={index} value={option.suppliers_list}>
                        {option.suppliers_list}
                      </option>
                    ))}
                    <option value="addShipper" style={{ color: 'blue' }}>Add New Option</option>
                  </select>
                </fieldset>
              </div>
              )}

            </div>

            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Upwell butter:</legend>
                  <input type="number" id="upwell_butter" value={materialInfo.upwell_butter}
                    onChange={(e) => handleMaterialInfo(e, 'upwell_butter')} onKeyPress={handleKeyPress}
                    placeholder="" className="inputStyle" disabled={isAdmin} style={{ border: 'none', width: '90%' }} /><span>kg</span>
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Air/Sea:</legend>
                  <select className="dropdownStyle" value={shipperInfo.air_sea} onChange={(e) => handleDropdownChange(e, 'air_sea')} disabled={isAdmin}>
                    <option value="">Select an option</option>
                    {options_3.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                    {/* <option value="addAir" style={{color:'blue'}}>Add New Option</option> */}
                  </select>
                </fieldset>
              </div>
            </div>
            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Upwell wax:</legend>
                  <input type="number" id="upwell_wax" value={materialInfo.upwell_wax}
                    onChange={(e) => handleMaterialInfo(e, 'upwell_wax')} onKeyPress={handleKeyPress}
                    placeholder="" className="inputStyle" disabled={isAdmin} style={{ border: 'none', width: '90%' }} /><span>kg</span>
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Shipped:</legend>
                  <input type="date" id="shipped" max={new Date().toISOString().split('T')[0]} value={shippingDates.shippedDate}
                    onChange={(e) => handleDateChange(e, 'shippedDate')} className="inputStyle" disabled={isAdmin} />
                </fieldset>
              </div>

            </div>
            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Upwell oleo:</legend>
                  <input type="number" id="upwell_oleo" value={materialInfo.upwell_oleo}
                    onChange={(e) => handleMaterialInfo(e, 'upwell_oleo')} onKeyPress={handleKeyPress}
                    placeholder="" className="inputStyle" disabled={isAdmin} style={{ border: 'none', width: '90%' }} /><span>kg</span>
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Received: </legend>
                  <input type="date" id="received" max={new Date().toISOString().split('T')[0]} value={shippingDates.receivedDate}
                    onChange={(e) => handleDateChange(e, 'receivedDate')} className="inputStyle" disabled={isAdmin} />
                </fieldset>
              </div>


            </div>
            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Upwell hydro:</legend>
                  <input type="number" id="upwell_hydro" value={materialInfo.upwell_hydro}
                    onChange={(e) => handleMaterialInfo(e, 'upwell_hydro')} onKeyPress={handleKeyPress}
                    placeholder="" className="inputStyle" disabled={isAdmin} style={{ border: 'none', width: '90%' }} /><span>kg</span>
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Proof of delivery:</legend>
                  <div className="file-input-container">
                    <label htmlFor="file-input5" className="custom-file-label">
                      {fileNames.proof_of_delivery_file_logi_display_name ? fileNames.proof_of_delivery_file_logi_display_name : 'Choose File'}
                    </label>
                    <input
                      type="file"
                      id="file-input5" accept=".pdf"
                      onChange={(e) => handleFileChange(e, 'proof_of_delivery_file_logi', 'proof_of_delivery_file_logi_display_name')}
                      style={{ display: 'none' }} disabled={isAdmin}
                    />
                  </div>
                </fieldset>
              </div>

            </div>
            <div className="displayFlex marginStyle">
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Spent biomass:</legend>
                  <input type="number" id="spent_biomass" value={materialInfo.spent_biomass}
                    onChange={(e) => handleMaterialInfo(e, 'spent_biomass')} onKeyPress={handleKeyPress}
                    placeholder="" className="inputStyle" disabled={isAdmin} style={{ border: 'none', width: '90%' }} /><span>kg</span>
                </fieldset>
              </div>
              <div className="flexAuto">
                <fieldset>
                  <legend className="scheduler-border">Shipping manifest:</legend>
                  <div className="file-input-container">
                    <label htmlFor="file-input4" className="custom-file-label">
                      {fileNames.shipping_manifest_file_logi_display_name ? fileNames.shipping_manifest_file_logi_display_name : 'Choose File'}
                    </label>
                    <input
                      type="file"
                      id="file-input4" accept=".pdf"
                      onChange={(e) => handleFileChange(e, 'shipping_manifest_file_logi', 'shipping_manifest_file_logi_display_name')}
                      style={{ display: 'none' }} disabled={isAdmin}
                    />
                  </div>
                </fieldset>
              </div>

            </div>



            {error && <p style={{ color: 'red' }}>{error}</p>}
          </div>
          <div className="paddingTop">
            {isAdmin ? (<div>
              <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome} className="homeButton" /></button>
              <button type="button" onClick={() => handleRedirect('/logistics')} className="loginbuttonStyle" disabled={loading}>ADD LOGISTICS</button>
              <button type="button" onClick={() => handleRedirect('/viewBatch')} className="loginbuttonStyle" disabled={loading}>Batch</button>
              <button type="button" onClick={() => handleRedirect('/products')} className="loginbuttonStyle" disabled={loading}>PRODUCTS</button>
            </div>) : (<div>
              <button onClick={() => handleRedirect('/dashboard')} disabled={loading} className="home-btn"><FontAwesomeIcon icon={faHome} className="homeButton" /></button>

              <button type="button" onClick={() => handleRedirect('/products')} className="loginbuttonStyle" disabled={loading}>PRODUCTS</button>
              {update ? (
                <button type="button" onClick={() => handleRedirect('/update')} className="loginbuttonStyle" disabled={loading}>Batch</button>) : (

                <button type="button" onClick={() => handleRedirect('/add')} className="loginbuttonStyle" disabled={loading}>Batch</button>
              )}
              <button type="button" onClick={() => handleRedirect('/logistics')} className="loginbuttonStyle" disabled={loading}>ADD LOGISTICS</button>
              {update ? (
                <button type="submit" onClick={() => handleSubmit()} className="loginbuttonStyle" disabled={loading}>{loading ? 'Loading...' : 'UPDATE'}</button>
              ) : (<button type="submit" onClick={() => handleSubmit()} className="loginbuttonStyle" disabled={loading}>{loading ? 'Loading...' : 'SAVE'}</button>)}

            </div>)}


          </div>
        </div>

      </div>


      <div id="popupid2" className="popup2">
        <div className="popup-content">
          <h2>Add Shipper</h2>
          <div className="marginStyle">
            <fieldset>
              <legend className="scheduler-border">Shipper:</legend>
              <input type="text" id="addShipper" value={addShipper}
                onChange={(e) => handleAddShipper(e, 'addShipper')}
                placeholder="Enter Shipper" className="inputStyle" style={{ border: 'none' }} />
            </fieldset>
            {shipperError && <p className="error">{shipperError}</p>}
          </div>
          {addDropdownError && <p style={{ color: 'red' }}>{addDropdownError}</p>}
          <div className="popup-btns-flex">
            <button onClick={() => handleShipperClosePopup()} className="popup-btns">Close</button >
            <button onClick={() => handleSaveShipper()} disabled={loading} className="popup-btns">{loading ? 'Loading...' : 'Save'}</button>
          </div>
        </div>
      </div>

      <div id="popupid3" className="popup3">
        <div className="popup-content">
          <h2>Add Shipped To</h2>
          <div className="marginStyle">
            <fieldset>
              <legend className="scheduler-border">Shipped to:</legend>
              <input type="text" id="addShippedTo" value={addShippedTo}
                onChange={(e) => handleAddShippedTo(e, 'addShippedTo')}
                placeholder="Enter Shipped To" className="inputStyle" style={{ border: 'none' }} />
            </fieldset>
            {shippedToError && <p className="error">{shippedToError}</p>}
          </div>
          {addDropdownError && <p style={{ color: 'red' }}>{addDropdownError}</p>}
          <div className="popup-btns-flex">
            <button onClick={() => handleShippedToClosePopup()} className="popup-btns">Close</button >
            <button onClick={() => handleSaveShippedTo()} disabled={loading} className="popup-btns">{loading ? 'Loading...' : 'Save'}</button>
          </div>
        </div>
      </div>

      <div id="popupid4" className="popup4">
        <div className="popup-content">
          <h2>Add Air/Sea</h2>
          <div className="marginStyle">
            <fieldset>
              <legend className="scheduler-border">Air/Sea:</legend>
              <input type="text" id="addAir_Sea" value={addAir_Sea}
                onChange={(e) => handleAddAirSea(e, 'addAir_Sea')}
                placeholder="Enter Supplier" className="inputStyle" style={{ border: 'none' }} />
            </fieldset>
          </div>
          <button onClick={() => handleAirSeaClosePopup()}>Close</button>
          <button onClick={() => handleSaveAirSea()} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
        </div>
      </div>

    </div>
  );
}

export default LogisticsClient;