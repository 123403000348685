import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../AddNewBatch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { getData, getIdRefreshToken } from '../../ApiServices/ApiService';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import '../login';

function ProductsReports() {
    const location = useLocation();
    const batchNo = location.state?.name;
    const update = location.state?.update;
    const viewData = location.state?.view;
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [isDataLoad, setIsDataLoad] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [qldbDataFetched, setQldbDataFetched] = useState(false);
    const [qldbError, setQldbError] = useState('');

    const contentRef = useRef(null);
    const qldbContentRef = useRef(null);
    const [qldbDataList, setQldbDataList] = useState([]);
    const [presignedUrlError, setPresignedUrlError] = useState('');
    const [singlePdf, setSinglePdf] = useState(false);
    const [mergePdf, setMergePdf] = useState(false);
    const [qldbPdf, setQldbPdf] = useState(false);
    const userData = JSON.parse(localStorage.getItem('userInfo'));
    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            if (!dataFetched) {
                getProductsData();
            }

            if (!qldbDataFetched) {
                getProductsQldbData();
            }
        } else {
            callLogout();
        }
    }, [dataFetched, qldbDataFetched, batchNo, viewData])

    const getProductsData = () => {
        const getPayload = {
            op: "GET_PRODUCTS",
            batchno: batchNo
        }
        setError('');
        setIsDataLoad(true);
        setDataFetched(false);
        getData('prod', JSON.stringify(getPayload), userData.id_token).then(resp => {
            // setIsDataLoad(false);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ !== undefined) {
                                setError('');
                                setDataFetched(true);
                                setProductInfo((prevProductInfo) => ({
                                    ...prevProductInfo,
                                    ...respData.dataJ,
                                }));
                            } else {
                                setError(respData.info);
                                productInfoEmpty();
                            }
                        } else {
                            setError(respData.info);
                            productInfoEmpty();
                        }
                    } else {
                        setError('Error While Getting Product Details');
                        productInfoEmpty();
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getProducts');
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setError('Error Occurred While Loading Supplier Batch List');
                    productInfoEmpty();
                }
            }).catch((error) => {
                setIsDataLoad(false);
                setError('Error While Getting Product Details Or Network Error');
                console.log('network error:', error);
                productInfoEmpty();
            });
        }).catch((error) => {
            setIsDataLoad(false);
            setError('Error While Getting Product Details Or Network Error');
            console.log('network error:', error);
            productInfoEmpty();
        });
    }

    const getAllReportPresignedUrl = () => {
        setMergePdf(true);
        const getPayload = {
            op: "GET_ALL_PRODUCTS_DETAILS_BACKED_REPORT",
            batchno: batchNo
        }
        setPresignedUrlError('');
        setIsDataLoad(true);
        getData('reports', JSON.stringify(getPayload), userData.id_token).then(resp => {
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            setPresignedUrlError('');
                            downloadAllReport(respData.dataJ.presignedurl);
                        } else {
                            setMergePdf(false);
                            setPresignedUrlError(respData.info);
                        }
                    } else {
                        setMergePdf(false);
                        setPresignedUrlError('Error While Getting Products All Report');
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getReportAll');
                        setIsDataLoad(true);
                    }
                } else {
                    setMergePdf(false);
                    console.log('resp status else  : ', resp.status);
                    setPresignedUrlError('Error Occurred While Getting Products All Report');
                }
            })
        }).catch(error => {
            setMergePdf(false);
            setIsDataLoad(false);
            setPresignedUrlError('Network Error While Getting Products All Report');
            console.log('network error:', error);
        });
    }

    const getProductsQldbData = () => {
        const getPayload = {
            op: "GET_PRODUCTS_QLDB_REPORTS",
            batchno: batchNo
        }
        setQldbError('');
        setIsDataLoad(true);
        setQldbDataFetched(false);
        getData('prod', JSON.stringify(getPayload), userData.id_token).then(resp => {
            // setIsDataLoad(false);
            console.log('resp status before : ', resp.status);
            resp.json().then(respData => {
                setIsDataLoad(false);
                if (resp.status === 200) {
                    if (respData.statusCode === 200) {
                        if (respData.message.toLowerCase() === 'success') {
                            if (respData.dataJ !== undefined) {
                                if (respData.dataJ.products.length > 0) {
                                    setQldbDataFetched(true);
                                    setQldbDataList(respData.dataJ.products);
                                } else {
                                    setQldbError(respData.info);
                                    setQldbDataList([]);
                                }
                            } else {
                                setQldbError(respData.info);
                                setQldbDataList([]);
                            }
                        } else {
                            setQldbError(respData.info);
                            setQldbDataList([]);
                        }
                    } else {
                        setQldbError('Error While Getting Products QLDB Report');
                        setQldbDataList([]);
                    }
                } else if (resp.status === 401 || resp.status === 403) {
                    console.log('resp status else if : ', resp.status);
                    if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
                        || respData.message === 'Access Denied') {
                        callRefreshTokenApi('getProductsQldb');
                        setIsDataLoad(true);
                    }
                } else {
                    console.log('resp status else  : ', resp.status);
                    setQldbError('Error Occurred While Getting Products QLDB Report');
                    setQldbDataList([]);
                }
            }).catch((error) => {
                setQldbError('Network Error While Getting Products QLDB Report');
                console.log('network error:', error);
                setQldbDataList([]);
            });
        }).catch((error) => {
            setQldbError('Network Error While Getting Products QLDB Report!');
            console.log('network error:', error);
            setQldbDataList([]);
        });
    }

    const callRefreshTokenApi = (type) => {
        const payload = {
            "email": userData.email,
            "oldRefreshToken": userData.refresh_token
        }
        getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
            if (resp.statusCode === 200) {
                if (resp.message.toLowerCase() === 'success') {
                    userData.id_token = resp.dataJ.id_token;
                    localStorage.setItem('userInfo', JSON.stringify(userData));
                    if (type === 'getProducts') {
                        getProductsData();
                    } else if (type === 'getProductsQldb') {
                        getProductsQldbData();
                    } else if (type === 'getReportAll') {
                        getAllReportPresignedUrl();
                    } else {
                        console.log('type', type)
                    }
                } else {
                    callLogout();
                }
            } else {
                callLogout();
            }
        }).catch((error) => {
            console.log('refresh error', error);
            callLogout();
        });
    }

    const callLogout = () => {
        localStorage.removeItem('isAdminLogin');
        localStorage.removeItem('userInfo');
        localStorage.clear();
        navigate('/');
        window.location.reload();
    }

    const [productInfo, setProductInfo] = useState({
        shipped_to: '',
        Client_po_file_display_name: '',
        shipper: '',
        air_sea: '',
        shippedDate: '',
        receivedDate: '',
        shipping_manifest_file_display_name: '',
        proof_of_delivery_file_display_name: '',
        upwell_butter: '',
        upwell_wax: '',
        upwell_oleo: '',
        upwell_hydro: '',
        spent_biomass: ''
    });

    const productInfoEmpty = () => {
        setProductInfo({
            shipped_to: '',
            Client_po_file_display_name: '',
            shipper: '',
            air_sea: '',
            shippedDate: '',
            receivedDate: '',
            shipping_manifest_file_display_name: '',
            proof_of_delivery_file_display_name: '',
            upwell_butter: '',
            upwell_wax: '',
            upwell_oleo: '',
            upwell_hydro: '',
            spent_biomass: ''
        });
    }

    const downloadPdf = () => {
        setSinglePdf(true);
        const content = contentRef.current;

        if (!content) {
            return;
        }

        // Use html2canvas to capture the content as an image
        html2canvas(content).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 210;
            const pageHeight = 295;

            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const pdf = new jsPDF('p', 'mm');
            let position = 0;

            // Add the captured image to the PDF
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            // Download the PDF
            pdf.save('Product Reports.pdf');
            setSinglePdf(false);
        });
    };

    const downloadQldbPdf = () => {
        setQldbPdf(true);
        const content = qldbContentRef.current;

        if (!content) {
            return;
        }

        // Use html2canvas to capture the content as an image
        html2canvas(content).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 210;
            const pageHeight = 295;

            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const pdf = new jsPDF('p', 'mm');
            let position = 0;

            // Add the captured image to the PDF
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            // Download the PDF
            pdf.save('Product QLDB Reports.pdf');
            setQldbPdf(false);
        });
    }

    const downloadUploadedPdf = (fileName, fileBase64) => {
        console.log('downloadUploadedPdf value , ', fileName, fileBase64);

        const byteArray = new Uint8Array(window.atob(fileBase64).split('').map((char) => char.charCodeAt(0)));

        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    }

    const handleRedirect = (path) => {
        navigate(path, { state: { name: batchNo, update: update, view: viewData } });
    }

    const isChanged = (current, previous, key) => {
        if (current[key] !== previous[key]) {
            return true;
        }
        return false;
    }

    const gmtToLocalTime = (gmtTime) => {
        const gmtDate = new Date(gmtTime);
        const localTime = gmtDate.toLocaleString();
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        const localTimeString = localTime.toLocaleString(undefined, options);
        return localTimeString.replace(/\//g, '-').replace(/\,/g, '');
    }

    const downloadAllReport = (presignedUrl) => {
        console.log('presignedUrl : ');
        const link = document.createElement('a');
        link.href = presignedUrl;
        link.download = 'Products.pdf';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        setMergePdf(false);
    }

    return (
        <div>
            <div className="reporttitle" style={{ display: 'flex' }}>
                <img src="./upwelllogo.png" alt="upwell" className="logoStyling" />
                <span className="addTitle">Products Report</span>
                <button onClick={() => handleRedirect('/dashboard')} className="home-btn" style={{ paddingRight: '50px' }}> <FontAwesomeIcon icon={faHome} className="homeButton" /></button>
            </div>
            <div className="reportContainer">
                {isDataLoad && (
                    <div className="dot-loader">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                )}
                {error && <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>}
                {/* {qldbError && <div style={{ color: 'red', textAlign: 'center' }}>{qldbError}</div>} */}

                <div className="download-div">
                    {dataFetched && <span><button onClick={downloadPdf} className="dwnld-btn">{singlePdf ? 'Generating...' : 'Download PDF'}</button></span>}
                    {qldbDataFetched && <span><button onClick={downloadQldbPdf} className="dwnld-btn" style={{ maxWidth: '180px' }}>{qldbPdf ? 'Generating...' : 'Download QLDB PDF'}</button></span>}
                    <span><button onClick={getAllReportPresignedUrl} className="dwnld-btn">{mergePdf ? 'Generating...' : 'Download All'}</button></span>
                    <div>{presignedUrlError && <p className="error"> {presignedUrlError}</p>}</div>
                </div>
                {dataFetched && (<div>
                    <div ref={contentRef} className="pdfPage-styling">
                        <div >
                            <img src="./upwelllogo.png" alt="upwell" className="logoStyling" style={{ padding: '0px' }} />
                        </div>
                        <div className="reportheading" >Products</div>
                        <div className="batchNoStyling ">Batch Number: {batchNo} </div>
                        <div className="tabletitle" style={{ marginBottom: '10px', marginRight: '7px' }}>Products Upwell to Clients</div>
                        <div style={{ paddingBottom: '20px' }}>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Shipped To : {productInfo.shipped_to}</div>
                                <div className="batchinfo flexAuto">Area/Sea : {productInfo.air_sea}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto">Shipped Date : {productInfo.shippedDate}</div>
                                <div className="batchinfo flexAuto">Received Date : {productInfo.receivedDate}</div>
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo flexAuto" style={{ fontWeight: '700' }}>Shipper : {productInfo.shipper}</div>
                                {/* <div className="batchinfo flexAuto">Client PO File : {productInfo.Client_po_file_display_name}</div> */}
                            </div>

                            <div className="displayFlex">
                                <div className="batchinfo">Client PO File : {productInfo.Client_po_file_display_name}</div>
                                {productInfo.Client_po_file && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(productInfo.Client_po_file_display_name, productInfo.Client_po_file)} />}
                            </div>
                            <div className="displayFlex">
                                <div className="batchinfo">Shipping Manifest File : {productInfo.shipping_manifest_file_display_name}</div>
                                {productInfo.shipping_manifest_file && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(productInfo.shipping_manifest_file_display_name, productInfo.shipping_manifest_file)} />}
                            </div>

                            <div className="displayFlex">
                                <div className="batchinfo">Proof Of Delivery File : {productInfo.proof_of_delivery_file_display_name}</div>
                                {productInfo.proof_of_delivery_file && <img src="./download.svg" alt="upwell" className="download-img" onClick={() => downloadUploadedPdf(productInfo.proof_of_delivery_file_display_name, productInfo.proof_of_delivery_file)} />}
                            </div>

                            {/* <div className="batchinfo">Shipping Manifest File : {productInfo.shipping_manifest_file_display_name}</div>
                            <div className="batchinfo">Proof Of Delivery File : {productInfo.proof_of_delivery_file_display_name}</div> */}


                        </div>
                        <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Material</div>
                        <table className="table">
                            <thead style={{ background: 'black', color: 'white' }}>
                                <tr>
                                    <th >Analyte</th>
                                    <th >Value(kg)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Upwell Butter</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }} >{productInfo.upwell_butter}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Upwell Wax</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{productInfo.upwell_wax}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Upwell Oleo</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{productInfo.upwell_oleo}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Upwell Hydro</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{productInfo.upwell_hydro}</td></tr>
                                <tr>
                                    <td style={{ padding: '0px 10px' }}>Spent Biomass</td>
                                    <td style={{ padding: '0px 14px', fontWeight: '700' }}>{productInfo.spent_biomass}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                )}

                {qldbDataFetched && (<div>
                    <div ref={qldbContentRef} className="pdfPage-styling">
                        <div >
                            <img src="./upwelllogo.png" alt="upwell" className="logoStyling" style={{ padding: '0px' }} />
                        </div>
                        <div className="reportheading" >Products QLDB Reports</div>
                        <div className="batchNoStyling  display-batch displayFlex">Batch Number: {batchNo} </div>

                        <div>
                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Products Upwell to Clients</div>

                            <table className="table" style={{ marginBottom: '20px' }}>
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th>Hash</th>
                                        <th >Created Time</th>
                                        <th >Shipped To</th>
                                        <th >Shipped Date</th>
                                        <th >Received Date</th>
                                        <th >Shipper</th>
                                        <th >Area/Sea</th>
                                        <th>Client PO File</th>
                                        <th >Shipping Manifest File</th>
                                        <th >Proof Of Delivery File</th>

                                    </tr>

                                </thead>
                                <tbody>
                                    {qldbDataList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td className="tdbreak" style={{ padding: '5px' }}>{item.hash}</td>}
                                            {index !== 0 && <td className={isChanged(item, qldbDataList[index - 1], 'hash') ? 'currentdata tdbreak' : 'tdbreak'} style={{ padding: '5px' }}>{item.hash}</td>}
                                            {index === 0 && <td className="tdbreak">{gmtToLocalTime(item.metadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.metadata, qldbDataList[index - 1].metadata, 'txTime') ? 'currentdata tdbreak' : 'tdbreak'}>{gmtToLocalTime(item.metadata.txTime)}</td>}
                                            {index === 0 && <td className="tdbreak">{item.data.shipped_to}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'shipped_to') ? 'currentdata tdbreak' : 'tdbreak'}>{item.data.shipped_to}</td>}
                                            {index === 0 && <td className="tdbreak">{item.data.shippedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'shippedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.data.shippedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.data.receivedDate}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'receivedDate') ? 'currentdata tdbreak' : 'tdbreak'}>{item.data.receivedDate}</td>}
                                            {index === 0 && <td className="tdbreak">{item.data.shipper}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'shipper') ? 'currentdata tdbreak' : 'tdbreak'}>{item.data.shipper}</td>}
                                            {index === 0 && <td className="tdbreak">{item.data.air_sea}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'air_sea') ? 'currentdata tdbreak' : 'tdbreak'}>{item.data.air_sea}</td>}
                                            {index === 0 && <td className="tdbreak">{item.data.Client_po_file_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'Client_po_file_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.data.Client_po_file_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.data.shipping_manifest_file_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'shipping_manifest_file_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.data.shipping_manifest_file_display_name}</td>}
                                            {index === 0 && <td className="tdbreak">{item.data.proof_of_delivery_file_display_name}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'proof_of_delivery_file_display_name') ? 'currentdata tdbreak' : 'tdbreak'}>{item.data.proof_of_delivery_file_display_name}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="tabletitle" style={{ marginBottom: '0px', marginRight: '7px' }}>Material</div>

                            <table className="table">
                                <thead style={{ background: 'black', color: 'white' }}>
                                    <tr>
                                        <th >Created Time</th>
                                        <th >Upwell Butter (kg)</th>
                                        <th >Upwell Wax (kg)</th>
                                        <th >Upwell Oleo (kg)</th>
                                        <th >Upwell Hydro (kg)</th>
                                        <th >Spent Biomass (kg)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {qldbDataList.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && <td >{gmtToLocalTime(item.metadata.txTime)}</td>}
                                            {index !== 0 && <td className={isChanged(item.metadata, qldbDataList[index - 1].metadata, 'txTime') ? 'currentdata' : ''}>{gmtToLocalTime(item.metadata.txTime)}</td>}
                                            {index === 0 && <td className="materialdata">{item.data.upwell_butter}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'upwell_butter') ? 'currentdata materialdata' : 'materialdata'}>{item.data.upwell_butter}</td>}
                                            {index === 0 && <td className="materialdata">{item.data.upwell_wax}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'upwell_wax') ? 'currentdata materialdata' : 'materialdata'}>{item.data.upwell_wax}</td>}
                                            {index === 0 && <td className="materialdata">{item.data.upwell_oleo}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'upwell_oleo') ? 'currentdata materialdata' : 'materialdata'}>{item.data.upwell_oleo}</td>}
                                            {index === 0 && <td className="materialdata">{item.data.upwell_hydro}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'upwell_hydro') ? 'currentdata materialdata' : 'materialdata'}>{item.data.upwell_hydro}</td>}
                                            {index === 0 && <td className="materialdata">{item.data.spent_biomass}</td>}
                                            {index !== 0 && <td className={isChanged(item.data, qldbDataList[index - 1].data, 'spent_biomass') ? 'currentdata materialdata' : 'materialdata'}>{item.data.spent_biomass}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                )}
            </div>
        </div>
    );

}

export default ProductsReports;