import { useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";

function Protected(props){

    const {Component} = props;
    const navigate = useNavigate();
    // const [storedData, setstoredData] = useState('');
    const storedData = useRef(null);

    useEffect( ()=>{
              // Retrieve data from localStorage when the component mounts
            //   setstoredData(localStorage.getItem('isAdminLogin'));
            storedData.current = localStorage.getItem('isAdminLogin');
              console.log('local data',storedData)
              if (storedData.current) {
                navigate('/dashboard');
              }else{
                navigate('/');
              }
    },[storedData,navigate]);

    return(
        <div>
            <Component />
        </div>
    )

}

export default Protected