import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { getData, getIdRefreshToken } from '../ApiServices/ApiService';
import { Link } from 'react-router-dom';
import './AddNewBatch.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

function UserViewBatch() {
  const location = useLocation();
  const batchDetails = location.state?.batchInfo;
  const [supplierList, setSupplierList] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  // const [userData , setUserData] = useState({})
  const userData = JSON.parse(localStorage.getItem('userInfo'));

  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      console.log('useEffect dataFetched : ', dataFetched);
      if (!dataFetched) {
        if (!localStorage.getItem('isAdminLogin')) {
          setError('You are not currently logged in');
          console.log('data not in local'); return
        }
        getSupplierBatchList();

        if (batchDetails) {
          setBatchNo(batchDetails.batchNo);
          setStartDate(batchDetails.startDate);
          setEndDate(batchDetails.endDate);
          setstartedBy(batchDetails.startedBy);
          setClosedBy(batchDetails.closedBy);
        }
      }

    } else {
      callLogout();
    }
    document.body.classList.remove('other-bg');
    document.body.classList.add('newbatch-bg');
    return () => {
      document.body.classList.remove('newbatch-bg');
      document.body.classList.add('other-bg');
    };
  }, [dataFetched]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const getSupplierBatchList = () => {
    const getPayload = {
      "op": "GET_BATCH_DETAILS_SEARCH"
    }
    setError('');
    getData('test1', JSON.stringify(getPayload), userData?.id_token).then(resp => {
      setDataFetched(true);
      console.log('resp status before : ', resp.status);
      resp.json().then(respData => {
        if (resp.status === 200) {
          if (respData.statusCode === 200) {
            if (respData.message.toLowerCase() === 'success') {
              if (respData.dataJ.length > 0) {
                setError('');
                setSupplierList(respData.dataJ);
              } else {
                setError(respData.info);
                setSupplierList([]);
              }
            } else {
              setError(respData.info);
              setSupplierList([]);
            }
          } else {
            setError('Error While Getting Supplier Batch List');
            setSupplierList([]);
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired'
            || respData.message === 'Access Denied') {
            callRefreshTokenApi('getBatch', '');
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error Occurred While Loading Supplier Batch List');
          setSupplierList([]);
        }
      }).catch((error) => {
        console.log('network error:', error);
        setError('Network Error While Loading');
        setSupplierList([]);
      });
      // if (resp.status === 200) {
      //   console.log('resp status  if : ', resp.status);
      //   resp.json().then((respData) => {
      // if (respData.statusCode === 200) {
      //   if (respData.message.toLowerCase() === 'success') {
      //     if (respData.dataJ.length > 0) {
      //       setError('');
      //       setSupplierList(respData.dataJ);
      //     } else {
      //       setError(respData.info);
      //       setSupplierList([]);
      //     }
      //   } else {
      //     setError(respData.info);
      //     setSupplierList([]);
      //   }
      // } else {
      //   setError('Error While Getting Supplier Batch List');
      //   setSupplierList([]);
      // }
      //   })

      // } else if (resp.status === 401 || resp.status === 403) {
      //   console.log('resp status else if : ', resp.status);
      //   resp.json().then((respData) => {
      // if (respData.message === 'Unauthorized' || respData.message === 'The incoming token has expired') {
      //   callRefreshTokenApi('getBatch', '');
      // }
      //   })

      // } else {
      //   console.log('resp status else  : ', resp.status);
      //   setError('Network Error While Loading !!!');
      //   setSupplierList([]);
      // }

    }).catch((error) => {
      console.log('network error:', error);
      setError('Network Error While Loading!');
      setSupplierList([]);
    });


    // getData('test1', JSON.stringify(getPayload), userData?.id_token).then(resp => {
    //   console.log('Response from server:', resp);
    //   setDataFetched(true);
    //   if (resp.statusCode === 200) {
    //     if (resp.message.toLowerCase() === 'success') {
    //       if (resp.dataJ.length > 0) {
    //         setError('');
    //         setSupplierList(resp.dataJ);
    //       } else {
    //         setError(resp.info);
    //         setSupplierList([]);
    //       }
    //     } else {
    //       setError(resp.info);
    //       setSupplierList([]);
    //     }
    //   } else if (resp.statusCode === 401 || resp.statusCode === 403) {
    //     callRefreshTokenApi('getBatch', '');

    //   } else {
    //     setError('Error While Getting Supplier Batch List');
    //     setSupplierList([]);
    //   }
    // }).catch((error) => {
    //   // callRefreshTokenApi();
    //   console.log('network error:', error);
    //   setError('Network Error While Loading');
    //   setSupplierList([]);
    // });
  }

  const callRefreshTokenApi = (type, event) => {
    const payload = {
      "email": userData.email,
      "oldRefreshToken": userData.refresh_token
    }
    getIdRefreshToken('refresh_tockens', JSON.stringify(payload)).then(resp => {
      if (resp.statusCode === 200) {
        if (resp.message.toLowerCase() === 'success') {
          userData.id_token = resp.dataJ.id_token;
          // userData.refresh_token = resp.dataJ.refresh_token;
          localStorage.setItem('userInfo', JSON.stringify(userData));
          if (type === 'getBatch') {
            getSupplierBatchList();
          } else if (type === 'getBatchInfo') {
            handleUpdatedvalue(event);
          } else {
            console.log('type', type)
          }
        } else {
          callLogout();
        }
      } else {
        callLogout();
      }
    }).catch((error) => {
      console.log('refresh catch error', error);
      callLogout();
    });
  }

  const callLogout = () => {
    localStorage.removeItem('isAdminLogin');
    localStorage.removeItem('userInfo');
    localStorage.clear();
    navigate('/');
    window.location.reload();
  }

  const handleRedirect = (path) => {
    if (!batchNo) {
      setError('Batch Number is required');
      return
    }
    const newBatchList = {
      batchNo: batchNo,
      startDate: startDate,
      endDate: endDate,
      startedBy: startedBy,
      closedBy: closedBy
    };
    navigate(path, { state: { name: batchNo, update: true, view: true, batchInfo: newBatchList } });
  }


  const [batchNo, setBatchNo] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startedBy, setstartedBy] = useState('');
  const [closedBy, setClosedBy] = useState('');


  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleStartedByChange = (event) => {
    setstartedBy(event.target.value);
  };

  const handleClosedByChange = (event) => {
    setClosedBy(event.target.value);
  };

  const handleUpdatedvalue = async (event) => {
    const getPayload = {
      "op": "get_batch_details",
      "batchNo": event
    }
    setError('');
    await getData('test1', JSON.stringify(getPayload), userData.id_token).then(resp => {
      console.log('resp status before : ', resp.status);
      resp.json().then(response => {
        if (resp.status === 200) {
          if (response.statusCode === 200) {
            if (response.message.toLowerCase() === 'success') {
              if (response.dataJ.length > 0) {
                setBatchNo(response.dataJ[0].batchNo);
                setStartDate(response.dataJ[0].startDate);
                setEndDate(response.dataJ[0].endDate);
                setstartedBy(response.dataJ[0].startedBy);
                setClosedBy(response.dataJ[0].closedBy);
              } else {
                setError(response.info);
                getBatchDetailsEmpty();
              }
            } else {
              setError(response.info);
              getBatchDetailsEmpty();
            }
          } else {
            setError('Error While Getting Supplier Batch Details');
            getBatchDetailsEmpty();
          }
        } else if (resp.status === 401 || resp.status === 403) {
          console.log('resp status else if : ', resp.status);
          if (response.message === 'Unauthorized' || response.message === 'The incoming token has expired'
            || response.message === 'Access Denied') {
            callRefreshTokenApi('getBatchInfo', event);
          }
        } else {
          console.log('resp status else  : ', resp.status);
          setError('Error While Loading Supplier Batch Details');
          getBatchDetailsEmpty();
        }
      }).catch((error) => {
        setError('Network Error While Loading');
        getBatchDetailsEmpty();
        console.log('network error:', error);
      });
    }).catch((error) => {
      setError('Network Error While Loading!');
      getBatchDetailsEmpty();
      console.log('network error:', error);
    });
  }

  const getBatchDetailsEmpty = () => {
    setBatchNo('');
    setStartDate('');
    setEndDate('');
    setstartedBy('');
    setClosedBy('');
  }

  return (
    <div>
      <div className="upwelltitle" style={{ display: 'flex' }}>
        <img src="./upwelllogo.png" alt="upwell" className="logoStyling" /> <span className="addTitle-batch">View Batch Details</span>
        <span className="home-btn-span"><Link to={'/dashboard'}>
          <button className="home-btn btn-width"> <FontAwesomeIcon icon={faHome} className="homeButton" /></button> </Link>
        </span>
      </div>
      <div className="maincontainernew">

        <div className="containerStyle">
          <div>
            <form>
              <div className="boxStyling">
                <div className="displayFlex marginStyle">
                  <div className="flexAuto">
                    {supplierList?.length > 0 ? (
                      <fieldset>
                        <legend className="scheduler-border">Batch Number:</legend>
                        <select value={batchNo} onChange={(e) => handleUpdatedvalue(e.target.value)} style={{ border: 'none', outline: 'none', width: '150px' }}>
                          <option value="">Select Supplier</option>
                          {supplierList?.map((item, index) => (
                            <option key={index} value={item.batchNo}>
                              {item.batchNo}
                            </option>
                          ))}
                        </select>
                      </fieldset>
                    ) : (
                      <div>Loading....</div>
                    )

                    }

                  </div>
                  {/* <div className="flexAuto searchicon">
            <button type="button" onClick={handleUpdatedvalue} className="home-btn"><i className="fas fa-search"></i></button>            
            </div> */}
                </div>

                <div className="displayFlex marginStyle">
                  <div className="flexAuto">
                    <fieldset>
                      <legend className="scheduler-border">Start Date:</legend>
                      <input type="date" id="startDate" max={new Date().toISOString().split('T')[0]} value={startDate} onChange={handleStartDateChange} className="inputStyle" placeholder="MM/DD/YYYY" disabled />
                    </fieldset>
                  </div>
                  <div className="flexAuto">
                    <fieldset>
                      <legend className="scheduler-border">End Date:</legend>
                      <input type="date" id="endDate" max={new Date().toISOString().split('T')[0]} value={endDate} onChange={handleEndDateChange} className="inputStyle" placeholder="MM/DD/YYYY" disabled />
                    </fieldset>
                  </div>
                </div>
                <div className="displayFlex marginStyle">
                  <div className="flexAuto">
                    <fieldset>
                      <legend className="scheduler-border">Started by:</legend>
                      <input type="text" id="startedBy" value={startedBy} onChange={handleStartedByChange} className="inputStyle" style={{ border: 'none' }} disabled />
                    </fieldset>
                  </div>
                  <div className="flexAuto">
                    <fieldset>
                      <legend className="scheduler-border">Closed by:</legend>
                      <input type="text" id="closedBy" value={closedBy} onChange={handleClosedByChange} className="inputStyle" style={{ border: 'none' }} disabled />
                    </fieldset>
                  </div>

                </div>

              </div>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              {/* <button type="submit" className="buttonStyle4" >
            {loading ? 'Loading...' : 'Update'}
            </button> */}
            </form>

            <div className="paddingTop">

              <button type="submit" onClick={() => handleRedirect('/rawbiomass')} className="buttonStyle"> RAW BIOMASS </button>

              <button type="submit" onClick={() => handleRedirect('/extraction')} className="buttonStyle1"> EXTRACTION </button>

              <button type="submit" onClick={() => handleRedirect('/logistics')} className="buttonStyle2" > LOGISTICS </button>

              <button type="submit" onClick={() => handleRedirect('/products')} className="buttonStyle3">PRODUCTS</button>

            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserViewBatch;